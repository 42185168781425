import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import {
  deleteDoc,
  where,
  collection,
  doc,
  query,
  onSnapshot,
  orderBy
} from 'firebase/firestore';
import { existsWithLength } from '@aldridge/aldg-helpers';
import { firestore } from '../../firebase';
import {
  _CurrentDate,
  _CurrentForeman,
  _CurrentJobNumber
} from '../../_Recoil/atoms';
import { basicStyles } from '../../theme';
import ConfirmDelete from '../dailyUsage/ConfirmDelete';

const EndDayJobList = (props) => {
  const { history } = props;
  const [newJobs, setNewJobs] = useState([]);
  const theme = useTheme();
  const customClasses = makeStyles(
    () => ({
      tableHead: {
        fontWeight: 'bold',
        padding: '16px',
        cursor: 'default',
        [theme.breakpoints.down('md')]: {
          paddingBottom: '0px'
        }
      }
    }),
    { index: 1 }
  )();
  const classes = basicStyles();
  const CurrentForeman = useRecoilValue(_CurrentForeman);
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);
  const CurrentDate = useRecoilValue(_CurrentDate);
  const handleSelect = (idx) => {
    history.push(`/pod/${idx}`);
  };

  const [deleteState, setDeleteState] = useState({
    proceedLabel: 'Delete',
    cancelLabel: 'Cancel',
    title: 'Delete Record',
    modalBody: 'Are you sure you want to remove this Record?',
    show: false,
    proceed: (approved, r) => {
      // eslint-disable-next-line no-use-before-define
      removeRecord(approved, r);
    },
    enableEscape: true
  });

  useEffect(() => {
    let mounted = true;
    if (CurrentDate.value === '') {
      toast.error('Date is Required');
      setNewJobs([]);
      return;
    }
    if (
      CurrentForeman.value.length === 0 &&
      CurrentJobNumber.value.length === 0
    ) {
      toast.error('Foreman or Job Number is Required');
      setNewJobs([]);
      return;
    }
    const colRef = (() => {
      let col = collection(firestore, 'Tasks');
      if (CurrentForeman.value.length > 0)
        col = query(col, where('data.Foreman', '==', CurrentForeman.value[0]));
      if (CurrentDate.value !== '')
        col = query(col, where('data.Date', '==', CurrentDate.value));
      if (CurrentJobNumber.value.length > 0)
        col = query(
          col,
          where('data.JobNumber', '==', CurrentJobNumber.value[0])
        );
      col = query(col, orderBy('CreatedDate'));
      return col;
    })();
    onSnapshot(colRef, (res) => {
      const allNewJobs = [];
      res.forEach((d) => allNewJobs.push(d.data()));
      if (mounted) setNewJobs(allNewJobs);
    });
    // eslint-disable-next-line consistent-return
    return () => (mounted = false);
  }, [CurrentForeman, CurrentDate, CurrentJobNumber]);

  const removeRecord = async (a, r) => {
    if (a) {
      if (r.idx !== '') {
        await deleteDoc(doc(firestore, 'Tasks', r.idx)).then(() => {
          toast.success('P.O.D. Removed.');
        });
      }
    }
    setDeleteState({ ...deleteState, show: false });
  };

  const handleDelete = (idx) => {
    setDeleteState({ ...deleteState, show: true, record: { idx } });
  };

  const getWBSDescription = (r) => {
    const display = [];
    if (r.data.TaskType === 'Task') {
      display.push(r.data.PICCDisplay);
      display.push(r.data.Task);
      return display.join(' / ');
    }
    if (r.data.TaskType === 'PreFab') {
      display.push(r.data.OrderNumberDisplay);
      if (r.data.Task !== '') display.push(r.data.Task);
    }
    if (r.data.TaskType === 'WBS') {
      if ((r.data.Level1Display?.toString()?.trim() || '') !== '') {
        display.push(r.data.Level1Display);
      }
      if ((r.data.Level2Display?.toString()?.trim() || '') !== '') {
        display.push(r.data.Level2Display);
      }
      if ((r.data.Level3Display?.toString()?.trim() || '') !== '') {
        display.push(r.data.Level3Display);
      }
      if ((r.data.Level4Display?.toString()?.trim() || '') !== '') {
        display.push(r.data.Level4Display);
      }
      if (r.data.Task !== '') {
        display.push(r.data.Task);
      }
    }
    return display.join(' / ');
  };
  console.log(newJobs);
  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '32px'
        }}
      />
      <ConfirmDelete
        proceedLabel={deleteState.proceedLabel}
        cancelLabel={deleteState.cancelLabel}
        title={deleteState.title}
        modalBody={deleteState.modalBody}
        show={deleteState.show}
        proceed={deleteState.proceed}
        enableEscape={deleteState.enableEscape}
        record={deleteState.record}
      />
      <Grid container spacing={1}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: 'white' }}>
                <TableCell
                  className={`${customClasses.tableHead} ${classes.tableCell}`}
                >
                  Date
                </TableCell>
                <TableCell
                  className={`${customClasses.tableHead} ${classes.tableCell}`}
                >
                  Foreman
                </TableCell>

                {CurrentJobNumber.value.length === 0 ? (
                  <TableCell className={classes.tableHeader}>
                    Job Number
                  </TableCell>
                ) : null}
                <TableCell
                  className={`${customClasses.tableHead} ${classes.tableCell}`}
                  colSpan={2}
                >
                  P.O.D./WBS
                </TableCell>
                <TableCell
                  className={`${customClasses.tableHead} ${classes.tableCell}`}
                >
                  Planned vs Actual
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newJobs.map((row) => (
                <TableRow key={row.id} className={classes.row1}>
                  <TableCell
                    className={classes.tableCell}
                    onClick={() => handleSelect(row.id)}
                  >
                    {row.data.Date}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    onClick={() => handleSelect(row.id)}
                  >
                    {row.data.ForemanDisplay}
                  </TableCell>
                  {CurrentJobNumber.value.length === 0 ? (
                    <TableCell
                      className={classes.tableCell}
                      onClick={() => handleSelect(row.id)}
                    >
                      {row.data.JobNumberDisplay}
                    </TableCell>
                  ) : null}
                  <TableCell
                    className={classes.tableCell}
                    onClick={() => handleSelect(row.id)}
                  >
                    {getWBSDescription(row)}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    onClick={() => handleSelect(row.id)}
                  >
                    Planned: {row.data.StartDay.ExpectedProductionQuantity}{' '}
                    {row.data.StartDay.UoM} <br />
                    {existsWithLength(
                      row.data.EndDay.CompletedProductionQuantity
                    )
                      ? `Actual: ${row.data.EndDay.CompletedProductionQuantity} ${row.data.StartDay.UoM}`
                      : ''}
                  </TableCell>
                  <TableCell className={classes.tableCell} align='right'>
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(row.id);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};
EndDayJobList.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired
};
EndDayJobList.defaultProps = {};

export default EndDayJobList;
