import React, { useEffect, useContext } from 'react';
import { Grid, Card } from '@mui/material';
import { useRecoilValue, useSetRecoilState } from "recoil";
import WeatherDashboard from '../components/dashboard/weather/WeatherDashboard';
import DailyPODDashboard from '../components/dashboard/DailyPODDashboard';
import DailyTimeDashboard from '../components/dashboard/timeDashboard';
import { _HeaderTitle, _CurrentDate, _CurrentJobNumber } from '../_Recoil/atoms';
import DailyToolbar from '../components/dailyUsage/DailyToolbar';
import ErrorBoundary from '../utils/ErrorBoundary';
import EquipmentDashboard from '../components/dashboard/EquipmentDashboard';

import { UserContext } from '../providers/UserProvider';

const Dashboard = () => {
  document.title = 'Dashboard';
  const setHeaderTitle = useSetRecoilState(_HeaderTitle);
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);
  const CurrentDate = useRecoilValue(_CurrentDate);
  const showCards = CurrentJobNumber.value.length !== 0 && CurrentDate.value !== '';
  const { user } = useContext(UserContext);

  useEffect(() => {
    setHeaderTitle('Daily Planning Overview');
  }, []);
  return (
    <ErrorBoundary componentName='Dashboard' user={user}>
      <div style={{ padding: '5px' }}>
        <DailyToolbar />
      </div>
      <Grid container justifyContent="center">
        {showCards ?
          <Grid item md={8} xs={12} style={{ padding: '5px' }}>
            <Card >
              <DailyPODDashboard />
            </Card>
            <Card style={{ marginTop: '8.1px' }}>
              <DailyTimeDashboard />
            </Card>
          </Grid>
          : <div style={{ padding: '10px 5px 5px 5px', width: '100%' }}><Card style={{ padding: '10px 5px 10px 5px' }}><i>Please complete the Job Details by clicking the pen to see a daily overview.</i></Card></div>}
        <Grid item md={showCards ? 4 : 12} xs={12} style={{ padding: '5px', marginTop: showCards ? '0px' : '10px' }} >
          <WeatherDashboard />
          {showCards ? <div style={{ marginTop: '5px' }} ><EquipmentDashboard /></div> : null}
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
};
export default Dashboard;
