import React from 'react';
import PropTypes from 'prop-types';
import {
  InputText,
  InputNumber,
  InputSelect
} from '@aldridge/aldg-data-components';
import { Grid } from '@mui/material';

const TaskDailyProduction = (props) => {
  const { record, onChange, customClasses, classes, timeOfDay, checkLockout, user } =
    props;

  return (
    <Grid container style={{ margin: 0 }}>
      <Grid item xs={12} className={customClasses.header}>
        <h2>Daily Production</h2>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.field}>
        <InputNumber
          label='Expected Production Quantity'
          value={record.data.StartDay.ExpectedProductionQuantity || ''}
          onChange={onChange}
          name='StartDay.ExpectedProductionQuantity'
          disabled={timeOfDay === 'EndDay' || checkLockout}
        />
      </Grid>
      <Grid item xs={12} sm={6} className={classes.field}>
        <InputSelect
          label='Expected Quantity UoM'
          name='StartDay.UoM'
          onChange={onChange}
          value={record.data.StartDay.UoM || ''}
          options={user.prefab ? [{ label: 'Each', value: 'ea' },
          ] : [
            { label: 'Feet', value: 'ft' },
            { label: 'Yards', value: 'yds' },
            { label: 'Cubic Yards', value: 'cu yds' },
            { label: 'Pounds', value: 'lbs' },
            { label: 'Each', value: 'ea' },
            { label: 'Square Feet', value: 'sq ft' },
            { label: 'Tons', value: 'tons' }
          ]}
          disabled={timeOfDay === 'EndDay' || checkLockout}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        className={
          timeOfDay === 'newday' ? customClasses.hidden : classes.field
        }
      >
        <InputNumber
          label='Completed Production Quantity'
          value={record.data.EndDay.CompletedProductionQuantity || ''}
          onChange={onChange}
          name='EndDay.CompletedProductionQuantity'
          hidden={timeOfDay === 'newday'}
          disabled={checkLockout}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        className={
          timeOfDay === 'newday' ? customClasses.hidden : classes.field
        }
      >
        <InputText
          name='EndDay.PercentComplete'
          label='Percent Complete'
          onChange={onChange}
          value={record.data.EndDay.PercentComplete || ''}
          hidden={timeOfDay === 'newday'}
          disabled={checkLockout}
        />
      </Grid>
    </Grid>
  );
};

TaskDailyProduction.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  customClasses: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  timeOfDay: PropTypes.string.isRequired,
  checkLockout: PropTypes.bool,
  user: PropTypes.objectOf(PropTypes.any).isRequired

};
TaskDailyProduction.defaultProps = {
  checkLockout: false
};

export default TaskDailyProduction;
