/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Button, Modal, Typography } from '@mui/material';

import { httpsCallable } from 'firebase/functions';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import { auth, functions } from '../../firebase';
import SwitchGroups from './SwitchGroups';
import { basicStyles } from '../../theme/index';

const MockUserModal = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { user, setUser } = props;
  const [loading, setLoading] = useState(false);
  const [userRole, setUserRole] = React.useState({
    ...user,
    mocked: false,
  });

  const openModal = () => {
    setModalOpen(true);
  };
  const classes = basicStyles();

  // useEffect(() => {
  //   localStorage.setItem('token', JSON.stringify(userRole));
  // }, [userRole]);

  const mockUserFunction = () => {
    const uMock = { ...userRole };
    uMock.mocked = true;
    localStorage.setItem('token', JSON.stringify(uMock));
    setUserRole(uMock);
    setUser(uMock);
    // window.location.reload(false)
  };

  const endMock = () => {
    setLoading(true);
    auth.currentUser.getIdToken(true).then((idToken) => {
      const checkIfUserExists = httpsCallable(functions, 'userExists');
      checkIfUserExists({ idToken, host: window.location.host })
        .then(async () => {
          const token = await auth.currentUser.getIdTokenResult(true);
          setUser(token.claims);
          setUserRole(token.claims);
          localStorage.setItem('token', JSON.stringify(token.claims));
          setModalOpen(false);
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err.message, { autoClose: 8000 });
        });
    });
  };

  const roles = () => {
    const impersonating = [];
    if (user.user)
      impersonating.push('User');
    if (user.manager)
      impersonating.push('Manager');
    if (user.prefab)
      impersonating.push('PreFab');
    return impersonating.join(', ');
  };
  return (
    <>
      <Modal open={modalOpen} className={classes.modal}>
        <div className={classes.timePaper}>
          <div
            style={{
              display: 'block',
              justifyContent: 'space-between',
              padding: '8px'
            }}
          >
            <SwitchGroups userRole={userRole} setUserRole={setUserRole} />
          </div>

          <div style={{ width: '100%', justifyContent: 'block' }}>

            <Button
              color='primary'
              variant='contained'
              onClick={(event) => mockUserFunction(event)}
            >
              Save
            </Button>
            &nbsp;
            {!loading ? <Button
              color='secondary'
              variant='contained'
              onClick={endMock}
            >
              End Mock
            </Button> : <Loader width={45} height={45} type="TailSpin" />}
            &nbsp;
            <Button
              color='error'
              variant='contained'
              style={{ whiteSpace: 'break-spaces' }}

              onClick={() => setModalOpen(false)}>
              Cancel
            </Button>
          </div>

        </div>

      </Modal>
      <div style={{ margin: ' 5px' }}>
        <Button color='primary' onClick={openModal} variant='contained'>Mock Role</Button>
      </div>
      <div>
        {user.mocked ? <div>You are currently viewing the app as a: {roles()}</div> : null}
      </div>
    </>
  );
};

MockUserModal.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  setUser: PropTypes.func.isRequired,
};
MockUserModal.defaultProps = {};
export default MockUserModal;
