import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
  useMediaQuery
} from '@mui/material';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import dayjs from 'dayjs';
import { faPlus, faTimes } from '@fortawesome/pro-solid-svg-icons';
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  where
} from 'firebase/firestore';

import DailyToolbar from '../dailyUsage/DailyToolbar';
import { firestore } from '../../firebase';
import {
  _CurrentDate,
  _CurrentJobNumber,
  _OnlyThisJob
} from '../../_Recoil/atoms';
import { basicStyles, icons } from '../../theme';
import TimeEntryRecord from './TimeEntryRecord';
import { weekEndDate } from '../../utils/dateFunctions';

import { UserContext } from '../../providers/UserProvider';

const TimeSummaryDetail = (props) => {
  const [timeEntries, setTimeEntries] = useState([]);
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);
  const CurrentDate = useRecoilValue(_CurrentDate);
  const OnlyThisJob = useRecoilValue(_OnlyThisJob);
  const { history, employeeId, checkLockout } = props;

  const [displayBoxOpen, setDisplayBoxOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(undefined);
  const [containsGL, setContainsGL] = useState(false);

  const mounted = useRef(null);

  const { user } = useContext(UserContext);
  const displayChangeBox = (bool) => {
    setDisplayBoxOpen(bool);
  };
  const classes = basicStyles();
  const theme = useTheme();

  const AddTime = () => {
    if (CurrentDate.value !== '') {
      setSelectedRecord(undefined);
      displayChangeBox(true);
    } else {
      toast.error('You must have a Date Selected to enter time...');
    }
  };

  const getRecords = () => {
    if (CurrentJobNumber.value.length === 0) {
      if (mounted.current) setTimeEntries([]);
      // return;
    }

    const colRef = (() => {
      let col = query(collection(firestore, `TimeEntries`));
      col = query(
        col,
        where('data.WeekEndDate', '==', weekEndDate(CurrentDate.value))
      );
      if (!(user.manager || user.admin)) {
        col = query(col, where('CreatedBy', '==', user.email));
      }
      if (!user.prefab && OnlyThisJob && CurrentJobNumber.value.length > 0) {
        col = query(
          col,
          where('data.JCTDSCID', '==', CurrentJobNumber.value[0])
        );
      }
      // 'rschuman@aldridge-electric.com'
      // col = col.where('CreatedBy', '==', 'rschuman@aldridge-electric.com');
      col = query(col, where('data.Employee', '==', employeeId));
      col = query(col, orderBy('data.EmployeeDisplay'));
      col = query(col, orderBy('data.Date'));
      return col;
    })();

    onSnapshot(colRef, (res) => {
      const allTimeEntries = [];
      res.forEach((d) => allTimeEntries.push(d.data()));
      if (mounted.current) setTimeEntries(allTimeEntries);
    });
  };

  const checkModalRecord = async (r) => {
    if (mounted.current) {
      if (!checkLockout) {
        setSelectedRecord(r);
        displayChangeBox(true);
      } else {
        toast.error('You cannot modify time for this entry anymore.');
      }
    }
  };

  const close = () => {
    history.push('/time-summary');
  };

  const removeRecord = async (r) => {
    await deleteDoc(doc(firestore, 'TimeEntries', r.id));
    getRecords();
    toast.success(`Removed ${r.data.EmployeeDisplay} time Record.`);
  };
  const parseNumber = (num) => {
    const defaultNum = 0;
    if (Number.isNaN(parseFloat(num))) return defaultNum;
    return parseFloat(num);
  };
  const iconClasses = icons();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const closePopup = () => displayChangeBox(false);

  useEffect(() => {
    mounted.current = true;
    getRecords();
    return () => (mounted.current = false);
  }, [CurrentDate]);

  useEffect(() => {
    mounted.current = true;
    timeEntries.some((tE) => {
      if (tE.data.TimeCardType === 'GL' && mounted.current) {
        setContainsGL(true);
        return true;
      }
      return false;
    });
    return () => {
      mounted.current = false;
    };
  }, [timeEntries]);

  return (
    <>
      <DailyToolbar />

      <div style={{ paddingTop: '1rem' }}>
        Time Summary for{' '}
        {timeEntries.length > 0 ? (
          <b>{timeEntries[0].data.EmployeeDisplay}</b>
        ) : null}{' '}
        on Week Ending{' '}
        <b>{dayjs(weekEndDate(CurrentDate.value)).format('MMMM D, YYYY')}</b>
      </div>
      <Grid container>
        <Modal open={displayBoxOpen} className={classes.modal}>
          <div className={classes.timePaper}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '8px'
              }}
            >
              <h5 style={{ textDecoration: 'underline' }}>Add Time Record</h5>
            </div>

            <div
              style={{
                position: 'relative',
                flex: '1 1 auto',
                padding: '1rem'
              }}
            >
              <TimeEntryRecord
                record={selectedRecord}
                closePopup={closePopup}
                employee={employeeId}
              />
            </div>
          </div>
        </Modal>
        <Grid container style={{ display: 'contents' }}>
          <div
            style={{
              position: 'sticky',
              top: '-1px',
              left: 0,
              right: 0,
              width: '100%',
              height: '56px',
              padding: '8px',
              margin: '8px 0px',
              display: 'flex',
              justifyContent: 'flex-end',
              zIndex: 100,
              alignSelf: 'flex-start'
            }}
          >
            {!checkLockout && <div
              className={`${iconClasses.container} ${iconClasses.addContainer}`}
              style={{ margin: '0px 12px' }}
              title='Add Time'
            >
              <Button
                onClick={AddTime}
                disableRipple
                className={iconClasses.buttonWrapper}
              >
                <FontAwesomeIcon icon={faPlus} className={iconClasses.icon} />
              </Button>
            </div>}
            <div
              className={`${iconClasses.container} ${iconClasses.redContainer}`}
              style={{ marginRight: '2px' }}
              title='Close Employee Detail'
            >
              <Button
                onClick={close}
                disableRipple
                className={iconClasses.buttonWrapper}
              >
                <FontAwesomeIcon icon={faTimes} className={iconClasses.icon} />
              </Button>
            </div>
          </div>
        </Grid>
        <Grid container>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: 'white' }}>
                  <TableCell
                    className={classes.tableHeader}
                    style={{ minWidth: matches ? '70px' : '' }}
                  >
                    Date
                  </TableCell>
                  {matches ? (
                    <TableCell
                      className={classes.tableHeader}
                      style={{ minWidth: '150px' }}
                    >
                      {containsGL ? 'Union/Job or GL/Picc' : 'Union/Job/Picc'}
                    </TableCell>
                  ) : (
                    <>
                      <TableCell className={classes.tableHeader}>
                        Union
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {containsGL ? 'Job/GL' : 'Job'}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        Picc
                      </TableCell>
                    </>
                  )}
                  <TableCell
                    style={{ padding: '12px 0px' }}
                    className={classes.tableHeader}
                  >
                    Regular
                  </TableCell>
                  <TableCell className={classes.tableHeader}>
                    Overtime
                  </TableCell>
                  <TableCell className={classes.tableHeader}>
                    DoubleTime
                  </TableCell>
                  <TableCell className={classes.tableHeader}>
                    Vac/Hol/SL
                  </TableCell>
                  <TableCell className={classes.tableHeader} colSpan={2}>
                    Per Diem
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {timeEntries.map((timeEntry) => (
                  <TableRow
                    className={classes.row1}
                    key={timeEntry.id}
                    onClick={() => {
                      checkModalRecord(timeEntry);
                    }}
                  >
                    <TableCell
                      className={classes.tableCell}
                      style={{ minWidth: matches ? '70px' : '' }}
                    >
                      {dayjs(timeEntry.data.Date).format('ddd MMM D, YYYY')}
                    </TableCell>
                    {matches ? (
                      <TableCell
                        className={classes.tableCell}
                        style={{ minWidth: '150px' }}
                      >
                        {timeEntry.data.UnionDisplay} <br />
                        {timeEntry.data.TimeCardType === 'GL'
                          ? timeEntry.data.GLCode
                          : timeEntry.data.JobDisplay}{' '}
                        <br />
                        {timeEntry.data.PiccDisplay} <br />
                      </TableCell>
                    ) : (
                      <>
                        <TableCell className={classes.tableCell}>
                          {timeEntry.data.UnionDisplay}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {timeEntry.data.TimeCardType === 'GL'
                            ? timeEntry.data.GLCode
                            : timeEntry.data.JobDisplay}
                        </TableCell>
                        {timeEntry.data.NoWorkRecorded === 'true' ? null : (
                          <TableCell className={classes.tableCell}>
                            {timeEntry.data.PiccDisplay}
                          </TableCell>
                        )}
                      </>
                    )}
                    {timeEntry.data.NoWorkRecorded === 'true' ? (
                      <TableCell
                        colSpan={5}
                        style={{ textAlign: 'center' }}
                        className={classes.tableCell}
                      >
                        No Work Recorded
                      </TableCell>
                    ) : (
                      <>
                        <TableCell className={classes.tableCell}>
                          {parseNumber(timeEntry.data.Regular).toFixed(2)}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {parseNumber(timeEntry.data.Overtime).toFixed(2)}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {parseNumber(timeEntry.data.Doubletime).toFixed(2)}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {(
                            parseNumber(timeEntry.data.Vacation) +
                            parseNumber(timeEntry.data.SickLeave) +
                            parseNumber(timeEntry.data.Holiday)
                          ).toFixed(2)}
                        </TableCell>
                      </>
                    )}

                    <TableCell className={classes.tableCell}>
                      {timeEntry.data.PerDiem !== ''
                        ? `$${timeEntry.data.PerDiem}`
                        : 'N/A'}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      align='center'
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!checkLockout)
                          removeRecord(timeEntry);
                      }}
                    >
                      {!checkLockout && <FontAwesomeIcon
                        icon={faTrashAlt}
                        style={{ cursor: 'pointer' }}
                      />}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};
TimeSummaryDetail.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  employeeId: PropTypes.string.isRequired,
  checkLockout: PropTypes.bool
};
TimeSummaryDetail.defaultProps = {
  history: {
    push: () => { }
  },
  checkLockout: false
};

export default TimeSummaryDetail;
