import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  InputNumber,
  InputAutocomplete,
  InputTextarea,
  Files
} from '@aldridge/aldg-data-components';
import { Grid, Button } from '@mui/material';
import { firebaseConfig } from '../../firebase';

const TaskObstacles = (props) => {
  const {
    record,
    onChange,
    customClasses,
    classes,
    timeOfDay,
    matches,
    checkLockout
  } = props;
  const [secondOb, setSecondOb] = useState(false);

  const calculateImpactedHours = (val) => {
    let hours = parseFloat(record.data.EndDay.HoursImpacted);
    let people = parseFloat(record.data.EndDay.PeopleImpacted);
    if (typeof val !== 'undefined') {
      hours = parseFloat(record.data.EndDay.HoursImpacted2);
      people = parseFloat(record.data.EndDay.PeopleImpacted2);
    }
    if (Number.isNaN(hours)) hours = 0;
    if (Number.isNaN(people)) people = 0;
    return hours * people;
  };
  return (
    <Grid container style={{ margin: 0 }}>
      <Grid
        item
        xs={12}
        className={customClasses.header}
        hidden={timeOfDay === 'newday'}
      >
        <h2>Obstacles</h2>
      </Grid>
      <Grid container style={{ margin: 0 }}>
        <Grid item xs={12} sm={6} className={classes.field}>
          <InputAutocomplete
            firestoreOptions={{
              collection: 'Obstacles',
              valueKey: 'id',
              orderBy: 'data.ObstacleCode',
              method: 'onSnapshot'
            }}
            label='Obstacle Code'
            value={record.data.EndDay.ObstacleCode || []}
            onChange={onChange}
            name='EndDay.ObstacleCode'
            optionDisplay={[
              'option_ObstacleCode',
              'option_ObstacleDescription'
            ]}
            enterprise={false}
            hidden={timeOfDay === 'newday'}
            disabled={checkLockout}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.field}>
          <InputAutocomplete
            firestoreOptions={{
              collection: `Obstacles/${record.data.EndDay.ObstacleCode[0]}/DetailedObstacles`,
              valueKey: 'id',
              orderBy: 'data.DetailedObstacleCode',
              method: 'onSnapshot'
            }}
            label='Sub Obstacle Code'
            enterprise={false}
            onChange={onChange}
            name='EndDay.SubObstacleCode'
            requirement={{
              rules: record.data.EndDay.ObstacleCode[0] || -1,
              text: 'Please Select an Obstacle Code Before Continuing.'
            }}
            value={record.data.EndDay.SubObstacleCode || []}
            optionDisplay={[
              'option_DetailedObstacleCode',
              'option_DetailedObstacleDescription'
            ]}
            hidden={timeOfDay === 'newday'}
            disabled={checkLockout}
          />
        </Grid>

        <Grid item sm={6} xs={12} className={classes.field}>
          <InputNumber
            name='EndDay.HoursImpacted'
            label='Task Hours Impacted'
            value={record.data.EndDay.HoursImpacted || ''}
            onChange={onChange}
            hidden={timeOfDay === 'newday'}
            disabled={checkLockout}
          />
        </Grid>
        <Grid item sm={6} xs={12} className={classes.field}>
          <InputNumber
            name='EndDay.PeopleImpacted'
            label='# of People Impacted'
            value={record.data.EndDay.PeopleImpacted || ''}
            onChange={onChange}
            hidden={timeOfDay === 'newday'}
            disabled={checkLockout}
          />
        </Grid>
        <Grid item xs={12}>
          Total Hours Impacted: {calculateImpactedHours()}
        </Grid>
        <Grid item xs={12} className={classes.field}>
          <InputTextarea
            name='EndDay.ReasonsForImpact'
            label='Reasons For Impact'
            value={record.data.EndDay.ReasonsForImpact || ''}
            onChange={onChange}
            hidden={timeOfDay === 'newday'}
            disabled={checkLockout}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.field}
        style={{ paddingRight: matches ? '24px' : '8px' }}
      >
        {!secondOb &&
          (typeof record.data.EndDay.ObstacleCode2 === 'undefined' ||
            record.data.EndDay.ObstacleCode2.length === 0) ? (
          <Grid container justifyContent='flex-end'>
            {' '}
            <Button
              variant='outlined'
              color='primary'
              style={{ textTransform: 'none' }}
              onClick={() => setSecondOb(true)}
            >
              <i>
                Another obstacle is <u> absolutely</u> necessary.
              </i>
            </Button>{' '}
          </Grid>
        ) : (
          <Grid container style={{ margin: 0 }}>
            <Grid
              item
              xs={12}
              className={customClasses.header}
              hidden={timeOfDay === 'newday'}
            >
              <h3>Secondary Obstacle</h3>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className={classes.field}
              style={{ paddingRight: matches ? '24px' : '8px' }}
            >
              <InputAutocomplete
                firestoreOptions={{
                  collection: `Obstacles`,
                  valueKey: 'id',
                  orderBy: 'data.ObstacleCode',
                  method: 'onSnapshot'
                }}
                label='Obstacle Code'
                value={record.data.EndDay.ObstacleCode2 || []}
                onChange={onChange}
                name='EndDay.ObstacleCode2'
                collection='Obstacles'
                optionDisplay={[
                  'option_ObstacleCode',
                  'option_ObstacleDescription'
                ]}
                valueKey='id'
                enterprise={false}
                orderBy='data.ObstacleCode'
                hidden={timeOfDay === 'newday'}
                disabled={checkLockout}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className={classes.field}
              style={{ paddingRight: '24px' }}
            >
              <InputAutocomplete
                firestoreOptions={{
                  collection: `Obstacles/${typeof record.data.EndDay.ObstacleCode2 !== 'undefined'
                    ? record.data.EndDay.ObstacleCode2[0]
                    : undefined
                    }/DetailedObstacles`,
                  valueKey: 'id',
                  orderBy: 'data.DetailedObstacleCode',
                  method: 'onSnapshot'
                }}
                label='Sub Obstacle Code'
                enterprise={false}
                onChange={onChange}
                name='EndDay.SubObstacleCode2'
                requirement={{
                  rules:
                    (typeof record.data.EndDay.ObstacleCode2 !== 'undefined'
                      ? record.data.EndDay.ObstacleCode2[0]
                      : undefined) || -1,
                  text: 'Please Select an Obstacle Code Before Continuing.'
                }}
                value={record.data.EndDay.SubObstacleCode2 || []}
                optionDisplay={[
                  'option_DetailedObstacleCode',
                  'option_DetailedObstacleDescription'
                ]}
                hidden={timeOfDay === 'newday'}
                disabled={checkLockout}
              />
            </Grid>

            <Grid
              item
              sm={6}
              xs={12}
              className={classes.field}
              style={{ paddingRight: matches ? '24px' : '8px' }}
            >
              <InputNumber
                name='EndDay.HoursImpacted2'
                label='Task Hours Impacted'
                value={record.data.EndDay.HoursImpacted2 || ''}
                onChange={onChange}
                hidden={timeOfDay === 'newday'}
                disabled={checkLockout}
              />
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
              className={classes.field}
              style={{ paddingRight: '24px' }}
            >
              <InputNumber
                name='EndDay.PeopleImpacted2'
                label='# of People Impacted'
                value={record.data.EndDay.PeopleImpacted2 || ''}
                onChange={onChange}
                hidden={timeOfDay === 'newday'}
                disabled={checkLockout}
              />
            </Grid>
            <Grid item xs={12}>
              Total Hours Impacted: {calculateImpactedHours(true)}
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.field}
              style={{ paddingRight: '24px' }}
            >
              <InputTextarea
                name='EndDay.ReasonsForImpact2'
                label='Reasons For Impact'
                value={record.data.EndDay.ReasonsForImpact2 || ''}
                onChange={onChange}
                hidden={timeOfDay === 'newday'}
                disabled={checkLockout}
              />
            </Grid>
            {typeof record.data.EndDay.ObstacleCode2 === 'undefined' ||
              record.data.EndDay.ObstacleCode2.length === 0 ? (
              <Grid container justifyContent='flex-end'>
                {' '}
                <Button
                  variant='outlined'
                  color='primary'
                  style={{ textTransform: 'none' }}
                  onClick={() => setSecondOb(false)}
                >
                  <i>Nevermind, a second obstacle isn&apos;t necessary.</i>
                </Button>{' '}
              </Grid>
            ) : null}
          </Grid>
        )}
      </Grid>

      <Grid item xs={12}>
        <Files
          label='Obstacle Photos'
          name='ObstaclePhotos'
          folder={`${record.data.JobNumber}/${record.data.Date}/${record.data.Foreman}/Obstacles`}
          bucket={firebaseConfig.storageBucket}
          firebaseConfig={firebaseConfig}
          getFilesFunction='getFiles'
          multiple
        />
      </Grid>
    </Grid>
  );
};

TaskObstacles.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  customClasses: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  timeOfDay: PropTypes.string.isRequired,
  matches: PropTypes.bool.isRequired,
  checkLockout: PropTypes.bool
};
TaskObstacles.defaultProps = {
  checkLockout: false
};

export default TaskObstacles;
