/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Paper, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';

import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import {
  faExclamationTriangle,
  faCheck,
  faArrowUpRightFromSquare
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRecoilValue } from 'recoil';
import { useTheme } from '@mui/material/styles';
import { faSave, faTimes } from '@fortawesome/pro-solid-svg-icons';

import { getAnalytics, logEvent } from 'firebase/analytics';
import {
  deleteDoc,
  doc,
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  setDoc,
  getDoc
} from 'firebase/firestore';
import { firestore } from '../../firebase';
import {
  _CurrentDate,
  _CurrentForeman,
  _CurrentJobNumber
} from '../../_Recoil/atoms';
import { UserContext } from '../../providers/UserProvider';
import { basicStyles, icons } from '../../theme';
import CrewMemberPopup from './CrewMemberPopup';
import TaskWBSInfo from './TaskWBSInfo';
import TaskDailyProduction from './TaskDailyProduction';
import TaskPlannedHoursEmployees from './TaskPlannedHoursEmployees';
import TaskCompletedHours from './TaskCompletedHours';
import TaskObstacles from './TaskObstacles';

const PODForm = (props) => {
  const { history, match, timeOfDay, pod, setPOD } = props;
  const CurrentForeman = useRecoilValue(_CurrentForeman);
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);
  const { user } = useContext(UserContext);
  const [piccs, setPiccs] = useState([]);
  const mounted = useRef(null);
  const theme = useTheme();

  const dataLoaded = useRef(false);
  const today = new Date();
  const record = pod;

  useEffect(() => {
    mounted.current = true;
    if (match.params.id === 'new') {
      return () => null;
    }
    const fRef = query(doc(collection(firestore, 'Tasks'), match.params.id));
    onSnapshot(fRef, (snap) => {
      if (snap.exists() && mounted) {
        setPOD(snap.data());
        dataLoaded.current = true;
      }
    });
    return () => (mounted.current = false);
  }, [match.params.id]);

  const checkLockout = (() => {
    if (user && user.admin) return false;
    const lastDayToEdit = dayjs().subtract('5', 'day').format('YYYY-MM-DD');
    if (lastDayToEdit >= record.data.Date && record.data.Date !== '') {
      toast.info(
        `I'm sorry, you can no longer add/modify Tasks for "${record.data.Date}".`
      );
      return true;
    }
    return false;
  })();

  useEffect(() => {
    if (
      typeof record.data.JobNumber === 'undefined' ||
      record.data.JobNumber.length === 0
    )
      return () => null;

    mounted.current = true;

    const fRef = query(
      collection(firestore, 'PICC'),
      where('data.JobNumber', '==', record.data.JobNumber),
      orderBy('data.Level0', 'asc'),
      orderBy('data.Level1', 'asc'),
      orderBy('data.Level2', 'asc'),
      orderBy('data.Level3', 'asc'),
      orderBy('data.Level4', 'asc')
    );
    onSnapshot(fRef, (snap) => {
      const p = [];
      if (!snap.empty) {
        snap.forEach((d) => p.push(d.data()));
      }
      if (mounted.current) setPiccs(p);
    });
    return () => (mounted.current = false);
  }, [record.data.JobNumber]);

  const onChange = (event, name, displayFromTypeahead, autoRecord) => {
    try {
      const numberFieldsThatShouldntDisplayNegativeNumbers = [
        JSON.stringify(['StartDay', 'ExpectedProductionQuantity']),
        JSON.stringify(['EndDay', 'CompletedProductionQuantity']),
        JSON.stringify(['StartDay', 'PlannedShiftHours']),
        JSON.stringify(['EndDay', 'HoursImpacted']),
        JSON.stringify(['EndDay', 'PeopleImpacted']),
        JSON.stringify(['EndDay', 'HoursImpacted2']),
        JSON.stringify(['EndDay', 'PeopleImpacted2'])
      ];
      const nameCanSplit = (n) => {
        if (n.split('.').length > 1) return [true, n.split('.')];
        return [false, n];
      };
      const [canSplit, id] =
        typeof name !== 'undefined'
          ? nameCanSplit(name)
          : nameCanSplit(event.target.name);
      let value = typeof name !== 'undefined' ? event : event.target.value;
      if (
        value < 0 &&
        numberFieldsThatShouldntDisplayNegativeNumbers.indexOf(
          JSON.stringify(id)
        ) !== -1
      )
        value = '';
      const changedRecord = { ...record };
      if (canSplit) {
        changedRecord.data[id[0]][id[1]] = value;
      } else {
        changedRecord.data[id] = value;
      }
      if (typeof displayFromTypeahead !== 'undefined') {
        if (canSplit) {
          changedRecord.data[id[0]][`${id[1]}Display`] = displayFromTypeahead;
        } else {
          changedRecord.data[`${id}Display`] = displayFromTypeahead;
        }
      }
      if (id === 'JobNumber') {
        changedRecord.data.PICC = autoRecord?.[0]?.data?.Picc || [];
        changedRecord.data.PICCDisplay = autoRecord?.[0]?.data?.PiccDisplay;
        changedRecord.data.ScopeOfWork =
          autoRecord?.[0]?.data?.ScopeOfWork || '';
        changedRecord.data.TypeOfWork = autoRecord?.[0]?.data?.TypeOfWork || '';
        changedRecord.data.BriefDescription =
          autoRecord?.[0]?.data?.BriefDescription || '';

        // changedRecord.data.JobNumberDisplay =
        // autoRecord?.[0]?.data?.JobNumberDisplay || '';
        changedRecord.data.NeedByDate =
          autoRecord?.[0]?.data.DateNeededBy || '';
        changedRecord.data.OrderNumber =
          autoRecord?.[0]?.data.OrderNumber || '';
      } // HELPFUL
      if (id === 'OrderNumber') {
        changedRecord.data.PICC = autoRecord?.[0]?.data?.Picc || [];
        changedRecord.data.PICCDisplay = autoRecord?.[0]?.data?.PiccDisplay;
        changedRecord.data.ScopeOfWork =
          autoRecord?.[0]?.data?.ScopeOfWork || '';
        changedRecord.data.TypeOfWork = autoRecord?.[0]?.data?.TypeOfWork || '';
        changedRecord.data.BriefDescription =
          autoRecord?.[0]?.data?.BriefDescription || '';
        changedRecord.data.JobNumber =
          autoRecord?.[0]?.data?.JobNumber[0] || '';
        changedRecord.data.JobNumberDisplay =
          autoRecord?.[0]?.data?.JobNumberDisplay || '';
        changedRecord.data.NeedByDate =
          autoRecord?.[0]?.data.DateNeededBy || '';
        changedRecord.data.IsGLTicket = autoRecord?.[0]?.data.IsGLTicket || '';
        changedRecord.data.GLCode = autoRecord?.[0]?.data.GLCode || '';
      }

      if (id === 'Level1') {
        changedRecord.data.Level2 = '';
        changedRecord.data.Level2Display = '';
        changedRecord.data.Level3 = '';
        changedRecord.data.Level3Display = '';
        changedRecord.data.Level4 = '';
        changedRecord.data.Level4Display = '';
      }

      if (['Level2', 'Level3', 'Level4'].includes(id)) {
        if (
          changedRecord.data.Level2 === '' &&
          (changedRecord.data.Level3 !== '' || changedRecord.data.Level4 !== '')
        ) {
          changedRecord.data.Level3 = '';
          changedRecord.data.Level3Display = '';
          changedRecord.data.Level4 = '';
          changedRecord.data.Level4Display = '';
        }
        if (
          changedRecord.data.Level3 === '' &&
          changedRecord.data.Level4 !== ''
        ) {
          changedRecord.data.Level4 = '';
          changedRecord.data.Level4Display = '';
        }
      }
      if (id === 'AllWBS' && record?.data?.AllWBS?.length === 0) {
        changedRecord.data.Level1 = '';
        changedRecord.data.Level1Display = '';
        changedRecord.data.Level2 = '';
        changedRecord.data.Level2Display = '';
        changedRecord.data.Level3 = '';
        changedRecord.data.Level3Display = '';
        changedRecord.data.Level4 = '';
        changedRecord.data.Level4Display = '';
      }
      if (changedRecord.CreatedDate === '') {
        changedRecord.CreatedBy = user.email;
        changedRecord.CreatedDate = new Date().toJSON();
      }
      changedRecord.ModifiedBy = user.email;
      changedRecord.ModifiedDate = new Date().toJSON();
      setPOD(changedRecord);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const addNew = () => {
    try {
      const savedRecord = JSON.parse(JSON.stringify(record));
      if (match.params.id === 'new') {
        const RECORDID = doc(collection(firestore, 'Tasks'));
        savedRecord.id = RECORDID.id;
      }

      const dRef = doc(collection(firestore, 'Tasks'), savedRecord.id);
      setDoc(dRef, savedRecord, { merge: true });
      history.push('/pod');
      toast.success('Form saved successfully!', { autoClose: 5000 });
      const analytics = getAnalytics();
      logEvent(analytics, 'pod_modified', {
        user: record.ModifiedBy,
        date: record.ModifiedDate,
        recordID: record.id
      });
    } catch (err) {
      toast.error('Error occured while saving form.', { autoCloseq: 5000 });
    }
    history.push('/pod');
  };

  const goBack = () => {
    history.push('/pod');
  };

  const attemptPercentComplete = () => {
    const expected = parseFloat(
      record.data.StartDay.ExpectedProductionQuantity
    );
    const completed = parseFloat(
      record.data.EndDay.CompletedProductionQuantity
    );
    if (Number.isNaN(expected) || Number.isNaN(completed)) return '0%';
    const cRecord = { ...record };
    cRecord.data.EndDay.PercentComplete = `${(
      (completed / expected) *
      100
    ).toFixed(2)}%`;
    const percent = `${((completed / expected) * 100).toFixed(2)}%`;
    if (mounted.current) setPOD(cRecord);
    return percent;
  };

  const useStyles = makeStyles(
    () => ({
      button: {
        marginLeft: theme.spacing(1)
      },
      header: {
        padding: '16px'
      },
      hidden: {
        padding: '0px',
        display: 'none'
      },
      suggested: {
        fontFamily: 'revert',
        fontWeight: '500',
        textAlign: 'left'
      }
    }),
    { index: 1 }
  );
  const customClasses = useStyles();
  const classes = basicStyles();

  useEffect(() => {
    if (!dataLoaded.current) return;
    attemptPercentComplete();
  }, [
    record.data.EndDay.CompletedProductionQuantity,
    record.data.StartDay.ExpectedProductionQuantity
  ]);

  const iconClasses = icons();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = useState(false);
  const closePopup = () => {
    setOpen(false);
  };

  return (
    <>
      <CrewMemberPopup
        open={open}
        closePopup={closePopup}
        foreman={record.data.Foreman}
        foremanDisplay={record.data.ForemanDisplay}
        jobnumber={CurrentJobNumber.value[0]}
      />
      <Grid container>
        <Grid item xs={12} style={{ padding: '5px' }} align='center'>
          <u>{`Job Number: ${record.data.JobNumberDisplay} `}</u>
        </Grid>
        <div
          style={{
            position: 'sticky',
            top: '-1px',
            left: 0,
            right: 0,
            width: '100%',
            padding: '8px',
            paddingRight: '0px',
            display: 'flex',
            justifyContent: 'flex-end',
            zIndex: 100
          }}
        >
          <div
            className={`${iconClasses.container} ${iconClasses.greenContainer}`}
            style={{ margin: matches ? '0px -8px' : '0px 12px' }}
            title='Save Record'
          >
            <Button
              onClick={() => addNew()}
              disableRipple
              className={iconClasses.buttonWrapper}
            >
              <FontAwesomeIcon icon={faSave} className={iconClasses.icon} />
            </Button>
          </div>
          <div
            className={`${iconClasses.container} ${iconClasses.redContainer}`}
            style={{ margin: '0px 0px 0px 12px' }}
            title='Close Record'
          >
            <Button
              onClick={goBack}
              disableRipple
              className={iconClasses.buttonWrapper}
            >
              <FontAwesomeIcon icon={faTimes} className={iconClasses.icon} />
            </Button>
          </div>
        </div>
        <Grid item xs={12}>
          <Paper className={classes.simplePrimaryPaper} variant='outlined'>
            <TaskWBSInfo
              record={record}
              setRecord={setPOD}
              checkLockout={checkLockout}
              onChange={onChange}
              classes={classes}
              customClasses={customClasses}
              CurrentForeman={CurrentForeman}
              CurrentJobNumber={CurrentJobNumber}
              piccs={piccs}
              user={user}
              timeOfDay={timeOfDay}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.simplePrimaryPaper} variant='outlined'>
            <TaskDailyProduction
              record={record}
              checkLockout={checkLockout}
              onChange={onChange}
              classes={classes}
              customClasses={customClasses}
              timeOfDay={timeOfDay}
              user={user}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.simplePrimaryPaper} variant='outlined'>
            <TaskPlannedHoursEmployees
              record={record}
              checkLockout={checkLockout}
              onChange={onChange}
              classes={classes}
              customClasses={customClasses}
              piccs={piccs}
              timeOfDay={timeOfDay}
              setOpen={setOpen}
              CurrentJobNumber={CurrentJobNumber}
              user={user}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} hidden={timeOfDay === 'newday'}>
          <Paper className={classes.simplePrimaryPaper} variant='outlined'>
            <TaskCompletedHours
              record={record}
              checkLockout={checkLockout}
              setPOD={setPOD}
              classes={classes}
              customClasses={customClasses}
              CurrentForeman={CurrentForeman}
              CurrentJobNumber={CurrentJobNumber}
              piccs={piccs}
              timeOfDay={timeOfDay}
              user={user}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} hidden={timeOfDay === 'newday'}>
          <Paper className={classes.simplePrimaryPaper} variant='outlined'>
            <TaskObstacles
              record={record}
              checkLockout={checkLockout}
              onChange={onChange}
              classes={classes}
              customClasses={customClasses}
              matches={matches}
              timeOfDay={timeOfDay}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

PODForm.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  timeOfDay: PropTypes.oneOf(['newday', 'EndDay']).isRequired,
  pod: PropTypes.objectOf(PropTypes.any),
  setPOD: PropTypes.func
};
PODForm.defaultProps = {
  pod: {},
  setPOD: () => { }
};

export default PODForm;
