import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Modal, Button, Backdrop, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { basicStyles } from '../../theme';

const ConfirmDelete = (props) => {
  const { proceedLabel, cancelLabel, title, modalBody, show, proceed, record } =
    props;
  const theme = useTheme();
  const customClasses = useCallback(() => makeStyles(
    () => ({
      paper: {
        zIndex: 1,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
      }
    }),
    { index: 1 }
  )(), []);
  const classes = useCallback(() => basicStyles(), []);
  return (
    <div className='static-modal'>
      <Modal open={show} BackdropComponent={Backdrop} className={classes.modal}>
        <div className={customClasses.paper}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '1rem'
            }}
          >
            <h5 style={{ textDecoration: 'underline' }}>{title}</h5>
          </div>

          <div
            style={{ position: 'relative', flex: '1 1 auto', padding: '1rem' }}
          >
            {modalBody}
          </div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'flex-end',
              padding: '0.75rem'
            }}
          >
            <Button
              variant='contained'
              onClick={() => proceed(false, record)}
              color='info'
              style={{ marginRight: '0.3rem' }}
            >
              {cancelLabel}
            </Button>
            <Button
              color='error'
              variant='contained'
              onClick={() => proceed(true, record)}
            >
              {proceedLabel}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

ConfirmDelete.propTypes = {
  proceedLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  modalBody: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func, // called when ok button is clicked.
  record: PropTypes.objectOf(PropTypes.any)
};
ConfirmDelete.defaultProps = {
  proceedLabel: '',
  cancelLabel: '',
  title: '',
  modalBody: '',
  show: false,
  proceed: () => { }, // called when ok button is clicked.
  record: {}
};

export default ConfirmDelete;
