/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Grid,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  Modal
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { faMinus, faPlus, faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { InputAutocomplete, InputDate } from '@aldridge/aldg-data-components';
import dayjs from 'dayjs';
import { useRecoilState } from 'recoil';
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc
} from 'firebase/firestore';
import { firestore, firebaseConfig } from '../../firebase';
import {
  basicStyles,
  icons,
  accordionClass,
  accordionSummaryClass,
  accordionDetailsClass
} from '../../theme';
import AccountingModal from './AccountingModal';
import { weekEndDate } from '../../utils/dateFunctions';
import { _payrollEmployeeTransaction } from '../../_Recoil/atoms';

const AccountingSummary = (props) => {
  const [timeEntries, setTimeEntries] = useState([]);
  const { history } = props;
  const [employeePayrollTransaction, setEmployeePayrollTransaction] =
    useRecoilState(_payrollEmployeeTransaction);
  const [employeeIds] = useState([]);
  const [employeeRecords, setEmployeeRecords] = useState([]);
  const [filterSelected, setFilterSelected] = useState(0);
  const [, setAggTime] = useState([]);
  const [aggTimeDisplay, setAggTimeDisplay] = useState([]);
  const [displayBoxOpen, setDisplayBoxOpen] = useState(false);
  const [selectedRecord] = useState(undefined);
  const theme = useTheme();
  const displayChangeBox = (bool) => {
    setDisplayBoxOpen(bool);
  };

  const useStyles = makeStyles(
    () => ({
      button: {
        padding: '8px',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
          justifyContent: 'flex-end'
        },
        [theme.breakpoints.up('sm')]: {
          paddingLeft: '0px'
        }
      },
      filterBtn: {
        textTransform: 'none',
        color: '#3f51b5',
        borderRadius: '0px',
        whiteSpace: 'nowrap',
        maxHeight: '40px',
        [theme.breakpoints.down('sm')]: {
          minWidth: '30px',
          maxWidth: '30px'
        }
      },
      filterBtnSelected: {
        textTransform: 'none',
        borderRadius: '0px',
        backgroundColor: '#3f51b5',
        color: 'white',
        maxHeight: '40px',
        whiteSpace: 'nowrap',
        '&:hover': {
          backgroundColor: '#132cb6'
        },
        [theme.breakpoints.down('sm')]: {
          minWidth: '30px',
          maxWidth: '30px'
        }
      },
      legendBox: {
        width: '20px',
        height: '20px',
        border: 'solid'
      }
    }),
    { index: 1 }
  );
  const classes = basicStyles();
  const customClasses = useStyles();
  const ac = accordionClass();
  const acdc = accordionDetailsClass();
  const acsc = accordionSummaryClass();

  const getRecords = () => {
    if (
      employeePayrollTransaction.weekEndDate !== '' &&
      employeePayrollTransaction.employeeList.length > 0
    ) {
      const allTimeEntries = [];
      for (let i = 0; i < employeePayrollTransaction.employeeList.length; i++) {
        allTimeEntries.push(
          getDocs(
            query(
              collection(firestore, 'TimeEntries'),
              where(
                'data.Employee',
                '==',
                employeePayrollTransaction.employeeList[i]
              ),
              where(
                'data.WeekEndDate',
                '==',
                weekEndDate(
                  employeePayrollTransaction.weekEndDate.split('T')[0]
                )
              )
            )
          )
        );
      }
      Promise.all(allTimeEntries).then((tes) => {
        const allData = [];
        tes.forEach((docs) => {
          if (!docs.empty) {
            docs.forEach((d) => {
              allData.push(d.data());
            });
          }
        });
        setTimeEntries(allData);
      });
    } else {
      toast.info(
        'Please make sure to select at least one Employee and a Week End Date.'
      );
    }
  };

  useEffect(() => {
    let mounted = true;
    const allEmp = [];
    employeeIds.forEach((ei) => {
      allEmp.push(getDoc(doc(firestore, 'ENT - Employees', ei.id)));
    });
    const allEmpRecords = [];
    Promise.all(allEmp).then((res) => {
      res.forEach((d) => {
        allEmpRecords.push(d.data());
      });
      if (mounted) setEmployeeRecords(allEmpRecords);
    });
    return () => {
      mounted = false;
    };
  }, [employeeIds]);

  useEffect(() => {
    let mounted = true;
    if (mounted) getRecords(mounted);
    return () => (mounted = false);
  }, [employeePayrollTransaction]);

  // useEffect(() => {
  //   if (typeof error !== 'undefined' && error.length > 0) {
  //     try {
  //       toast.error(<div>
  //         {
  //           error.map(em => (
  //             <div key={em.record.id} style={{ display: 'flex', width: '100%', flexDirection: 'column', textAlign: 'left' }}>
  //               <div>Failed to Submit Time for: <b>{em.record.data.EmployeeDisplay}; {em.record.data.PiccDisplay}</b></div>
  //               <div>Error: <b>{em.error}</b></div>
  //               <br />
  //             </div>
  //           ))
  //         }
  //         <br />Click to close this message.
  //       </div>, { autoClose: false, toastId: "ExportTimeError", enableHtml: true })
  //     } catch (err) {
  //       toast.error(error)
  //     }
  //   } if (typeof ProcessTimeData !== 'undefined') {
  //     toast.success('Time Exported Successfully.', { toastId: 'ExportTimeSuccess' });
  //   }
  // }, [ProcessTimeData, error]);

  const parseNumber = (num) => {
    const defaultNum = 0;
    if (Number.isNaN(parseFloat(num))) return defaultNum;
    return parseFloat(num);
  };

  const getTimeEntries = (tE, filter, mounted) => {
    const aggTimeRecords = [];
    const r = {
      Employee: '',
      EmployeeDisplay: '',
      Date: '',
      Regular: 0,
      Overtime: 0,
      Doubletime: 0,
      Vacation: 0,
      SickLeave: 0,
      Holiday: 0,
      NoWorkRecorded: '',
      PerDiem: ''
    };
    tE.forEach((te) => {
      const idx = aggTimeRecords.findIndex(
        (atr) => atr.Employee === te.data.Employee
      );
      if (idx === -1) {
        const cR = JSON.parse(JSON.stringify(r));
        cR.Employee = te.data.Employee;
        cR.EmployeeDisplay = te.data.EmployeeDisplay;
        cR.Date = te.data.Date;
        cR[dayjs(te.data.Date).format('ddd')] = {};
        if (filter === 0 || filter === 7 || filter === 1)
          cR[dayjs(te.data.Date).format('ddd')].Regular = parseNumber(
            te.data.Regular
          );
        if (filter === 0 || filter === 7 || filter === 2)
          cR[dayjs(te.data.Date).format('ddd')].Overtime = parseNumber(
            te.data.Overtime
          );
        if (filter === 0 || filter === 7 || filter === 3)
          cR[dayjs(te.data.Date).format('ddd')].Doubletime = parseNumber(
            te.data.Doubletime
          );
        if (filter === 0 || filter === 7 || filter === 4)
          cR[dayjs(te.data.Date).format('ddd')].Holiday = parseNumber(
            te.data.Holiday
          );
        if (filter === 0 || filter === 7 || filter === 5)
          cR[dayjs(te.data.Date).format('ddd')].Vacation = parseNumber(
            te.data.Vacation
          );
        if (filter === 0 || filter === 7 || filter === 6)
          cR[dayjs(te.data.Date).format('ddd')].SickLeave = parseNumber(
            te.data.SickLeave
          );
        if (filter === 0 || filter === 7)
          cR[dayjs(te.data.Date).format('ddd')].PerDiem =
            te.data.PerDiem !== ''
              ? te.data.PerDiem
              : cR[dayjs(te.data.Date).format('ddd')].PerDiem;
        if (filter === 0 || filter === 8)
          cR[dayjs(te.data.Date).format('ddd')].NoWorkRecorded =
            te.data.NoWorkRecorded === 'true';
        aggTimeRecords.push(cR);
      } else {
        const cR = aggTimeRecords[idx];
        if (typeof cR[dayjs(te.data.Date).format('ddd')] === 'undefined')
          cR[dayjs(te.data.Date).format('ddd')] = {
            Regular: 0,
            Overtime: 0,
            Doubletime: 0,
            Vacation: 0,
            SickLeave: 0,
            Holiday: 0
          };
        if (filter === 0 || filter === 7 || filter === 1)
          cR[dayjs(te.data.Date).format('ddd')].Regular += parseNumber(
            te.data.Regular
          );
        if (filter === 0 || filter === 7 || filter === 2)
          cR[dayjs(te.data.Date).format('ddd')].Overtime += parseNumber(
            te.data.Overtime
          );
        if (filter === 0 || filter === 7 || filter === 3)
          cR[dayjs(te.data.Date).format('ddd')].Doubletime += parseNumber(
            te.data.Doubletime
          );
        if (filter === 0 || filter === 7 || filter === 4)
          cR[dayjs(te.data.Date).format('ddd')].Holiday += parseNumber(
            te.data.Holiday
          );
        if (filter === 0 || filter === 7 || filter === 5)
          cR[dayjs(te.data.Date).format('ddd')].Vacation += parseNumber(
            te.data.Vacation
          );
        if (filter === 0 || filter === 7 || filter === 6)
          cR[dayjs(te.data.Date).format('ddd')].SickLeave += parseNumber(
            te.data.SickLeave
          );
        if (filter === 0 || filter === 7)
          cR[dayjs(te.data.Date).format('ddd')].PerDiem =
            te.data.PerDiem !== ''
              ? te.data.PerDiem
              : cR[dayjs(te.data.Date).format('ddd')].PerDiem;
        if (filter === 0 || filter === 8)
          cR[dayjs(te.data.Date).format('ddd')].NoWorkRecorded = !cR[
            dayjs(te.data.Date).format('ddd')
          ].NoWorkRecorded
            ? te.data.NoWorkRecorded === 'true'
            : cR[dayjs(te.data.Date).format('ddd')].NoWorkRecorded;
      }
    });
    if (mounted) {
      setAggTime(aggTimeRecords);
      setAggTimeDisplay(aggTimeRecords);
    }
  };

  useEffect(() => {
    let mounted = true;
    getTimeEntries(timeEntries, 0, mounted);
    return () => (mounted = false);
  }, [timeEntries, employeeRecords]);

  const displayDailyTotalHrs = (day) => {
    if (typeof day === 'undefined') return '';
    if (day.NoWorkRecorded) {
      return 'X';
    }
    return `${parseNumber(day.Regular) +
      parseNumber(day.Overtime) +
      parseNumber(day.Doubletime) +
      parseNumber(day.Holiday) +
      parseNumber(day.Vacation) +
      parseNumber(day.SickLeave)
      }<br>${day.PerDiem !== '' && typeof day.PerDiem !== 'undefined'
        ? `$${day.PerDiem.toString()}`
        : ''
      }`;
  };

  const onChange = (event, name) => {
    const changedRecord = { ...employeePayrollTransaction };
    const value = typeof name !== 'undefined' ? event : event.target.value;
    const id = typeof name !== 'undefined' ? name : event.target.name;
    changedRecord[id] = value;
    setEmployeePayrollTransaction(changedRecord);
  };

  const displayTotalHrs = (r) => {
    let rgHrs = 0;
    let otHrs = 0;
    let dtHrs = 0;
    let hlHrs = 0;
    let vaHrs = 0;
    let slHrs = 0;
    let pdDrs = 0;
    if (typeof r === 'undefined') {
      return null;
    }
    const keys = Object.keys(r);
    keys.forEach((k) => {
      if (['Employee', 'EmployeeDisplay', 'Date'].indexOf(k) === -1) {
        rgHrs += parseNumber(r[k].Regular);
        otHrs += parseNumber(r[k].Overtime);
        dtHrs += parseNumber(r[k].Doubletime);
        hlHrs += parseNumber(r[k].Holiday);
        vaHrs += parseNumber(r[k].Vacation);
        slHrs += parseNumber(r[k].SickLeave);
        pdDrs += parseNumber(r[k].PerDiem);
      }
    });
    return `<span>${rgHrs + otHrs + dtHrs + hlHrs + vaHrs + slHrs
      }</span><br /><span>${pdDrs > 0 ? `$${pdDrs}` : ''}</span>`;
    // return `<span>${rgHrs}</span>/<span>${otHrs}</span>/<span>${dtHrs}</span><br><span>${hlHrs}</span>/<span>${vaHrs}</span>/<span>${slHrs}</span>`;
  };
  const iconClasses = icons();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const selectFilter = (s) => {
    setFilterSelected(s);
    let toDisplay;
    switch (s) {
      case 0:
        toDisplay = timeEntries;
        getTimeEntries(toDisplay, s, s);
        break;
      case 1:
        toDisplay = timeEntries.filter((te) => te.data.Regular !== '');
        getTimeEntries(toDisplay, s);
        break;
      case 2:
        toDisplay = timeEntries.filter((te) => te.data.Overtime !== '');
        getTimeEntries(toDisplay, s);
        break;
      case 3:
        toDisplay = timeEntries.filter((te) => te.data.Doubletime !== '');
        getTimeEntries(toDisplay, s);
        break;
      case 4:
        toDisplay = timeEntries.filter((te) => te.data.Holiday !== '');
        getTimeEntries(toDisplay, s);
        break;
      case 5:
        toDisplay = timeEntries.filter((te) => te.data.Vacation !== '');
        getTimeEntries(toDisplay, s);
        break;
      case 6:
        toDisplay = timeEntries.filter((te) => te.data.SickLeave !== '');
        getTimeEntries(toDisplay, s);
        break;
      case 7:
        toDisplay = timeEntries.filter((te) => te.data.PerDiem !== '');
        getTimeEntries(toDisplay, s);
        break;
      case 8:
        toDisplay = timeEntries.filter((te) => te.data.NoWorkRecorded !== '');
        getTimeEntries(toDisplay, s);
        break;
      default:
        break;
    }
  };

  const getColor = (day) => {
    if (typeof day !== 'undefined') {
      if (
        day.NoWorkRecorded &&
        (day.Doubletime > 0 ||
          day.Overtime > 0 ||
          day.Vacation > 0 ||
          day.Holiday > 0 ||
          day.SickLeave > 0 ||
          day.Regular > 0)
      )
        return '#dbdd2e';
      if (day.Doubletime > 0 || day.Overtime > 0) return 'red';
      if (day.Regular > 8) return 'darkorange';
      if (day.Vacation > 0 || day.Holiday > 0) return 'blue';
      if (day.SickLeave > 0) return 'green';
      if (day.NoWorkRecorded) return 'darkturquoise';
    }
    return 'black';
  };
  const addWeek = (increment) => {
    const uRecord = { ...employeePayrollTransaction };
    uRecord.weekEndDate = dayjs(uRecord.weekEndDate)
      .add(increment, 'week')
      .format('YYYY-MM-DD');
    setEmployeePayrollTransaction(uRecord);
  };

  const disableTime = () => {
    // eslint-disable-next-line
    const confirmation = confirm(
      'Are you sure you want to lay off the selected employees? This will be hard to undo.'
    );
    if (confirmation) {
      const empRecords = [...timeEntries];
      empRecords.forEach((r) => {
        r.data.DoNotProcess = true;
      });
      setTimeEntries(empRecords);
    }
  };

  return (
    <>
      <Grid container>
        <Modal open={displayBoxOpen} className={classes.modal}>
          <div className={classes.timePaper}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '8px'
              }}
            >
              <h5 style={{ textDecoration: 'underline' }}>Add Time Record</h5>
            </div>

            <div
              style={{
                position: 'relative',
                flex: '1 1 auto',
                padding: '1rem'
              }}
            >
              <AccountingModal
                record={selectedRecord}
                closePopup={() => displayChangeBox(false)}
                getRecords={getRecords}
              />
            </div>
          </div>
        </Modal>
        <Accordion defaultExpanded classes={ac} style={{ width: '100%' }}>
          <AccordionSummary
            classes={{ root: acsc.root, expanded: acsc.expanded }}
            expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
          >
            Employees and Week End Date
          </AccordionSummary>
          <AccordionDetails classes={acdc}>
            <Grid container style={{ backgroundColor: 'white' }}>
              <Grid item sm={6} xs={12} className={classes.field}>
                <InputAutocomplete
                  firebaseConfig={firebaseConfig}
                  firestoreOptions={{
                    method: 'get',
                    collection: 'ENT-Employees',
                    valueKey: 'prtmstid',
                    orderBy: 'LegalLastName'
                  }}
                  value={employeePayrollTransaction.employeeList || []}
                  label='Employees'
                  onChange={onChange}
                  name='employeeList'
                  optionDisplay={(opts) =>
                    `${opts.LegalFirstName} ${opts.MiddleName || ''} ${opts.LegalLastName
                    } [${opts.EmployeeNumber}]`
                  }
                  multiple
                />
              </Grid>
              <Grid item sm={6} xs={12} className={classes.field}>
                <Grid container>
                  <Grid item xs={8}>
                    <InputDate
                      type='date'
                      label='Week End Date'
                      onChange={onChange}
                      name='weekEndDate'
                      value={
                        weekEndDate(employeePayrollTransaction.weekEndDate) ||
                        ''
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Button
                      onClick={() => addWeek(1)}
                      disableRipple
                      className={iconClasses.buttonWrapper}
                    >
                      <FontAwesomeIcon
                        style={{ color: 'black', fontSize: '1.25rem' }}
                        icon={faPlus}
                      />
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Button
                      onClick={() => addWeek(-1)}
                      disableRipple
                      className={iconClasses.buttonWrapper}
                    >
                      <FontAwesomeIcon
                        style={{ color: 'black', fontSize: '1.25rem' }}
                        icon={faMinus}
                      />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {employeePayrollTransaction.employeeList.length !== 0 ? (
                <Grid item xs={12} className={classes.field}>
                  <Button
                    onClick={disableTime}
                    variant='outlined'
                    color='primary'
                    style={{ textTransform: 'none' }}
                  >
                    Click to layoff the selected employees.
                  </Button>
                </Grid>
              ) : null}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Grid container style={{ display: 'contents' }}>
          <div
            style={{
              position: 'sticky',
              top: '-1px',
              left: 0,
              right: 0,
              width: '100%',
              height: '56px',
              backgroundColor: 'white',
              padding: '8px',
              margin: '8px 0px',
              display: 'flex',
              justifyContent: 'flex-end',
              zIndex: 100,
              alignSelf: 'flex-start'
            }}
          >
            {/* <Grid item md={6} xs={12} className={customClasses.button} hidden={CurrentJobNumber !== 0 && currentDate !== ''} /> */}
            <Grid
              item
              xs={12}
              className={customClasses.button}
              style={{
                justifyContent: 'center',
                whiteSpace: 'nowrap',
                paddingRight: '0px'
              }}
            >
              {/* {
                loading ? <Loader type='TailSpin' height={56} /> : (
                  <> */}

              {/*  per john request turned off ability to submit to payroll manually */
              /* <div className={`${iconClasses.container} ${iconClasses.exportContainer}`} title="Submit Time To Payroll">
                    <Button onClick={ProcessTime} disableRipple className={iconClasses.buttonWrapper}> */}
              {/* <FontAwesomeIcon icon={faCloudUploadAlt} className={iconClasses.icon} />
                </Button>
                  </div> */}
              <div
                style={{
                  textAlign: 'left',
                  marginLeft: matches ? '-8px' : '0px'
                }}
              >
                <div
                  style={{
                    width: '100%',
                    border: 'solid',
                    borderColor: '#3f51b5',
                    borderRadius: '5px',
                    marginTop: '2px'
                  }}
                >
                  <Button
                    disableRipple
                    className={
                      filterSelected === 0
                        ? customClasses.filterBtnSelected
                        : customClasses.filterBtn
                    }
                    onClick={() => selectFilter(0)}
                  >
                    All
                  </Button>
                  <Button
                    disableRipple
                    className={
                      filterSelected === 1
                        ? customClasses.filterBtnSelected
                        : customClasses.filterBtn
                    }
                    onClick={() => selectFilter(1)}
                  >
                    Reg
                  </Button>
                  <Button
                    disableRipple
                    style={{
                      minWidth: matches ? '40px' : '',
                      maxWidth: matches ? '40px' : ''
                    }}
                    className={
                      filterSelected === 2
                        ? customClasses.filterBtnSelected
                        : customClasses.filterBtn
                    }
                    onClick={() => selectFilter(2)}
                  >
                    Over
                  </Button>
                  <Button
                    disableRipple
                    className={
                      filterSelected === 3
                        ? customClasses.filterBtnSelected
                        : customClasses.filterBtn
                    }
                    onClick={() => selectFilter(3)}
                  >
                    Dbl
                  </Button>
                  <Button
                    disableRipple
                    className={
                      filterSelected === 4
                        ? customClasses.filterBtnSelected
                        : customClasses.filterBtn
                    }
                    onClick={() => selectFilter(4)}
                  >
                    Hol
                  </Button>
                  <Button
                    disableRipple
                    className={
                      filterSelected === 5
                        ? customClasses.filterBtnSelected
                        : customClasses.filterBtn
                    }
                    onClick={() => selectFilter(5)}
                  >
                    Vac
                  </Button>
                  <Button
                    disableRipple
                    className={
                      filterSelected === 6
                        ? customClasses.filterBtnSelected
                        : customClasses.filterBtn
                    }
                    onClick={() => selectFilter(6)}
                    style={{
                      minWidth: matches ? '40px' : '',
                      maxWidth: matches ? '40px' : ''
                    }}
                  >
                    Sick
                  </Button>
                  <Button
                    disableRipple
                    className={
                      filterSelected === 7
                        ? customClasses.filterBtnSelected
                        : customClasses.filterBtn
                    }
                    onClick={() => selectFilter(7)}
                  >
                    {matches ? 'PD' : 'Per Diem'}
                  </Button>
                  <Button
                    disableRipple
                    style={{
                      minWidth: matches ? '40px' : '',
                      maxWidth: matches ? '40px' : ''
                    }}
                    className={
                      filterSelected === 8
                        ? customClasses.filterBtnSelected
                        : customClasses.filterBtn
                    }
                    onClick={() => selectFilter(8)}
                  >
                    NWR
                  </Button>
                </div>
              </div>
              {/* <div style={{ width: '100%', textAlign: '-webkit-right' }}>
                      <div className={`${iconClasses.container} ${iconClasses.addContainer}`} title="Add Time" style={{ margin: '0px 0px 0px 4px' }}>
                        <Button onClick={AddTime} disableRipple className={iconClasses.buttonWrapper}>
                          <FontAwesomeIcon icon={faPlus} className={iconClasses.icon} />
                        </Button>
                      </div>
                    </div> */}
              {/* </> */}
              {/* )
              } */}
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <TableContainer style={{ marginBottom: '16px' }}>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: 'white' }}>
                <TableCell className={classes.tableHeader}>Employee</TableCell>
                <TableCell align='center' className={classes.tableHeader}>
                  Mon
                </TableCell>
                <TableCell align='center' className={classes.tableHeader}>
                  Tue
                </TableCell>
                <TableCell align='center' className={classes.tableHeader}>
                  Wed
                </TableCell>
                <TableCell align='center' className={classes.tableHeader}>
                  Thu
                </TableCell>
                <TableCell align='center' className={classes.tableHeader}>
                  Fri
                </TableCell>
                <TableCell align='center' className={classes.tableHeader}>
                  Sat
                </TableCell>
                <TableCell align='center' className={classes.tableHeader}>
                  Sun
                </TableCell>
                <TableCell align='center' className={classes.tableHeader}>
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {aggTimeDisplay.map((allData) => (
                <TableRow
                  className={classes.row1}
                  style={{ cursor: 'pointer' }}
                  key={allData.Employee}
                  onClick={() =>
                    history.push(
                      `accounting/${weekEndDate(
                        employeePayrollTransaction.weekEndDate
                      )}/${allData.Employee}`
                    )
                  }
                >
                  <TableCell className={classes.tableCell}>
                    {allData.EmployeeDisplay}
                  </TableCell>
                  <TableCell
                    align='center'
                    style={{ color: getColor(allData.Mon) }}
                    className={classes.tableCell}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: displayDailyTotalHrs(allData.Mon)
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align='center'
                    style={{ color: getColor(allData.Tue) }}
                    className={classes.tableCell}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: displayDailyTotalHrs(allData.Tue)
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align='center'
                    style={{ color: getColor(allData.Wed) }}
                    className={classes.tableCell}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: displayDailyTotalHrs(allData.Wed)
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align='center'
                    style={{ color: getColor(allData.Thu) }}
                    className={classes.tableCell}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: displayDailyTotalHrs(allData.Thu)
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align='center'
                    style={{ color: getColor(allData.Fri) }}
                    className={classes.tableCell}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: displayDailyTotalHrs(allData.Fri)
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align='center'
                    style={{ color: getColor(allData.Sat) }}
                    className={classes.tableCell}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: displayDailyTotalHrs(allData.Sat)
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align='center'
                    style={{ color: getColor(allData.Sun) }}
                    className={classes.tableCell}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: displayDailyTotalHrs(allData.Sun)
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align='center'
                    style={{ color: getColor(allData.Total) }}
                    className={classes.tableCell}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: displayTotalHrs(allData)
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Card
          style={{
            backgroundColor: 'white',
            width: '100%',
            paddingBottom: '4px'
          }}
        >
          <div
            style={{
              textAlign: 'left',
              width: '100%',
              padding: '8px 8px 0px 8px'
            }}
          >
            <b style={{ marginLeft: '4px' }}>Legend:</b>
            <div
              style={{
                padding: '4px',
                width: 'fit-content',
                whiteSpace: 'nowrap',
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <div
                style={{ backgroundColor: 'red' }}
                className={customClasses.legendBox}
              />
              <div style={{ margin: '2px 0px 0px 6px' }}>
                Records with Overtime/Doubletime hours
              </div>
            </div>
            <div
              style={{
                padding: '4px',
                width: 'fit-content',
                whiteSpace: 'nowrap',
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <div
                style={{ backgroundColor: 'darkorange' }}
                className={customClasses.legendBox}
              />
              <div style={{ margin: '2px 0px 0px 6px' }}>
                Records with more than 8 Regular hours
              </div>
            </div>
            <div
              style={{
                padding: '4px',
                width: 'fit-content',
                whiteSpace: 'nowrap',
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <div
                style={{ backgroundColor: 'blue' }}
                className={customClasses.legendBox}
              />
              <div style={{ margin: '2px 0px 0px 6px' }}>
                Records with Holiday/Vacation hours
              </div>
            </div>
            <div
              style={{
                padding: '4px',
                width: 'fit-content',
                whiteSpace: 'nowrap',
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <div
                style={{ backgroundColor: 'green' }}
                className={customClasses.legendBox}
              />
              <div style={{ margin: '2px 0px 0px 6px' }}>
                Records with Sick Leave hours
              </div>
            </div>
            <div
              style={{
                padding: '4px',
                width: 'fit-content',
                whiteSpace: 'nowrap',
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <div
                style={{ backgroundColor: 'darkturquoise' }}
                className={customClasses.legendBox}
              />
              <div style={{ margin: '2px 0px 0px 6px' }}>
                Records with No Work Recorded
              </div>
            </div>
            <div
              style={{
                padding: '4px',
                width: 'fit-content',
                whiteSpace: 'nowrap',
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <div
                style={{ backgroundColor: '#dbdd2e' }}
                className={customClasses.legendBox}
              />
              <div style={{ margin: '2px 0px 0px 6px' }}>
                Records with No Work Recorded &amp; Hours
              </div>
            </div>
            <div
              style={{
                padding: '4px 4px 4px 24px',
                width: 'fit-content',
                color: 'red',
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <div style={{ margin: '2px 0px 0px 6px' }}>
                Records with Invalid PICCs
              </div>
            </div>
          </div>
        </Card>
      </Grid>
    </>
  );
};
AccountingSummary.propTypes = {
  history: PropTypes.objectOf(PropTypes.any)
};
AccountingSummary.defaultProps = {
  history: {
    push: () => { }
  }
};

export default AccountingSummary;
