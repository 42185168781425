import React, { useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@mui/material';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import {
  InputAutocomplete,
  InputText,
  InputSelect,
  InputNumber,
  InputDate,
  InputCheckbox,
  InputCheckboxGroup
} from '@aldridge/aldg-data-components';
import { getAnalytics, logEvent } from 'firebase/analytics';
import {
  setDoc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  collection,
  doc,
  where
} from 'firebase/firestore';
import dayjs from 'dayjs';
import { tryParse, isUndefined, NaNtoNum } from '@aldridge/aldg-helpers';
import {
  _CurrentDate,
  _CurrentForeman,
  _CurrentJobNumber,
  _Job,
  _Division
} from '../../_Recoil/atoms';
import { firestore, firebaseConfig } from '../../firebase';
import { UserContext } from '../../providers/UserProvider';
import { SizeContext } from '../../providers/SizeProvider';
import { weekEndDate } from '../../utils/dateFunctions';
import existsWithLength from '../../utils/existsWithLength';

const TimeEntryRecord = (props) => {
  const { closePopup, checkLockout, record, employee, getRecords } = props;
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);
  const Job = useRecoilValue(_Job);
  const CurrentDate = useRecoilValue(_CurrentDate);
  const CurrentForeman = useRecoilValue(_CurrentForeman);
  const CurrentDivision = useRecoilValue(_Division);
  const [PerDiems, setPerDiems] = useState([]);
  const { user } = useContext(UserContext);
  const size = useContext(SizeContext);
  const [loadedEmp, setLoadedEmp] = useState({ flag: false, value: undefined });
  const mounted = useRef(null);
  const emptyRecord = {
    id: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    ModifiedDate: '',
    ScreenSize: size,
    data: {
      Crew: '',
      TimeEntryPoint: 'TimeEntryRecord',
      Date: CurrentDate.value,
      WeekEndDate: weekEndDate(CurrentDate.value),
      Doubletime: '',
      ExemptFromCertifiedPayroll: '',
      Holiday: '',
      JCTDSCID:
        CurrentJobNumber.value.length > 0 ? CurrentJobNumber.value[0] : '',
      JobNumber: Job || '',
      JobDisplay: CurrentJobNumber.display || '',
      Division: CurrentDivision,
      LessThan8HoursVerified: '',
      NoWorkRecorded: '',
      Overtime: '',
      PRTUNMID: '',
      PerDiem: '',
      Foreman: CurrentForeman.value.length > 0 ? CurrentForeman.value[0] : '',
      Employee: employee,
      EmployeeDisplay: '',
      Person: '',
      PersonDisplay: '',
      PersonJobPiccDisplay: '',
      Picc: '',
      PiccDisplay: '',
      PreFab: user.prefab,
      Regular: '',
      Shift: '1',
      SickLeave: '',
      SickLeaveType: '',
      Union: '',
      UnionDisplay: '',
      Vacation: '',
      ArrowOpen: false
    }
  };
  const [entry, setEntry] = useState(emptyRecord);

  useEffect(() => {
    const uRecord = { ...entry };
    uRecord.ScreenSize = size;
    setEntry(uRecord);
  }, [size]);

  useEffect(() => {
    mounted.current = true;
    if (typeof record !== 'undefined') {
      if (mounted.current) {
        setEntry(record);
      }
    }
    const pds = [];
    const fRef = query(
      collection(firestore, 'PerDiems'),
      where('status', '==', 'A'),
      orderBy('order')
    );
    onSnapshot(fRef, (res) => {
      res.forEach((d) => {
        pds.push(d.data());
      });
      if (mounted.current) setPerDiems(pds);
    });
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    mounted.current = true;
    if (loadedEmp.flag && typeof loadedEmp.value !== 'undefined') {
      const uEntry = { ...entry };
      uEntry.data.Crew = loadedEmp.value;
      if (mounted.current) setEntry(uEntry);
    }
    return () => {
      mounted.current = false;
    };
  }, [loadedEmp]);

  useEffect(() => {
    mounted.current = true;
    if (
      typeof entry.data.Crew !== 'undefined' &&
      entry.data.Crew.length !== 0
    ) {
      const uEntry = { ...entry };
      getDoc(doc(firestore, 'Crew', uEntry.data.Crew[0])).then((er) => {
        if (er.exists()) {
          const EmployeeRecord = er.data();
          [uEntry.data.Employee] = EmployeeRecord.data.Employee;
          uEntry.data.EmployeeDisplay = EmployeeRecord.data.EmployeeDisplay;
          [uEntry.data.Union] = EmployeeRecord.data.Union;
          uEntry.data.UnionDisplay = EmployeeRecord.data.UnionDisplay;

          uEntry.data.PersonJobPiccDisplay = `${EmployeeRecord.data.EmployeeDisplay}\n${CurrentJobNumber.display}\n${uEntry.data.PiccDisplay}`;

          if (
            typeof EmployeeRecord.data.DefaultPerDiem !== 'undefined' &&
            EmployeeRecord.data.DefaultPerDiem !== ''
          )
            uEntry.data.PerDiem = EmployeeRecord.data.DefaultPerDiem;

          if (mounted.current) setEntry(uEntry);
        }
      });
    }
    return () => {
      mounted.current = false;
    };
  }, [entry.data.Crew, entry.data.Picc]);

  // eslint-disable-next-line no-unused-vars
  const RoundTo25 = (value, nam) => {
    const cleanedValue = (isUndefined(value) ? '' : value.toString()).replace(
      /[^0-9.]/g,
      ''
    );
    const numberValue =
      Number.isNaN(cleanedValue) || cleanedValue.toString().trim() === ''
        ? 0
        : `${cleanedValue}`;
    const vMod = parseFloat(numberValue) % 1;
    let finalValue = numberValue;
    try {
      switch (true) {
        case vMod <= 0.125:
          finalValue = parseInt(NaNtoNum(finalValue), 10) + 0;
          break;
        case vMod <= 0.375:
          finalValue = parseInt(NaNtoNum(finalValue), 10) + 0.25;
          break;
        case vMod <= 0.625:
          finalValue = parseInt(NaNtoNum(finalValue), 10) + 0.5;
          break;
        case vMod <= 0.875:
          finalValue = parseInt(NaNtoNum(finalValue), 10) + 0.75;
          break;
        default:
          finalValue = parseInt(NaNtoNum(finalValue), 10) + 1;
      }
    } catch (ex) {
      finalValue = 0;
    }
    if (finalValue > 24) finalValue = 24;

    const uRecord = structuredClone(entry);
    uRecord.data[nam] = finalValue;
    setEntry((prev) => {
      const uData = { data: { ...prev.data, [nam]: finalValue } };
      return { ...prev, ...uData };
    });
    return finalValue;
  };
  const onChange = (event, name, displayFromTypeahead) => {
    try {
      const id = name || event.target.name;
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changedRecord = { ...entry };
      changedRecord.data[id] = value;
      if (typeof displayFromTypeahead !== 'undefined') {
        changedRecord.data[`${id}Display`] = displayFromTypeahead;
      }
      if (id === 'Date') {
        changedRecord.data.WeekEndDate = weekEndDate(value);
      }
      if (id === 'JCTDSCID' && typeof displayFromTypeahead !== 'undefined') {
        changedRecord.data.JobNumber = displayFromTypeahead.substring(0, 6);
        changedRecord.data.JobDisplay = displayFromTypeahead;
        [changedRecord.data.JCTDSCID] = value;
      }
      // const TimeTypeFields = [
      //   'Doubletime',
      //   'Holiday',
      //   'Overtime',
      //   'Regular',
      //   'SickLeave',
      //   'Vacation'
      // ];
      // if (TimeTypeFields.indexOf(id) > -1) {
      //   changedRecord.data[id] = RoundTo25(value).toString();
      // }
      if (changedRecord.CreatedDate === '') {
        changedRecord.CreatedBy = user.email;
        changedRecord.CreatedDate = new Date().toJSON();
      }
      changedRecord.ModifiedBy = user.email;
      changedRecord.ModifiedDate = new Date().toJSON();
      delete changedRecord.data.ArrowOpenDisplay;
      delete changedRecord.data.JCTDSCIDDisplay;
      setEntry(changedRecord);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const close = () => {
    setEntry(emptyRecord);
    closePopup();
  };

  const sumHours = (timeRecord) =>
    tryParse(timeRecord.data.Regular) +
    tryParse(timeRecord.data.Overtime) +
    tryParse(timeRecord.data.Doubletime) +
    tryParse(timeRecord.data.Holiday) +
    tryParse(timeRecord.data.Vacation) +
    tryParse(timeRecord.data.SickLeave);

  const isValidRecord = () => {
    let valid = true;
    const err = [];
    let perDiemRecord = false;
    let noWorkRecord = false;
    if (entry.data.PerDiem !== '') {
      perDiemRecord = true;
    }
    if (entry.data.NoWorkRecorded === 'true') {
      noWorkRecord = true;
    }
    if (entry.data.Crew.length === 0) {
      valid = false;
      err.push('You must select an Employee to Enter Time for.');
    }
    if (existsWithLength(entry.data.Picc) && noWorkRecord) {
      valid = false;
      err.push('You cannot select a PICC for No Work Recorded.');
    }
    // if (!existsWithLength(entry.data.Picc) && (perDiemRecord || noWorkRecord)) {
    //   valid = false;
    //   err.push('You must select a PICC to Enter Time for.');
    // }
    if (!existsWithLength(entry.data.Picc) && sumHours(entry) > 0) {
      valid = false;
      err.push('You must select a PICC to Enter Time for.');
    }
    if (entry.data.Shift === '' && !perDiemRecord && !noWorkRecord) {
      valid = false;
      err.push('You must select a Shift to Enter Time for.');
    }
    if (entry.data.Shift !== '' && noWorkRecord) {
      valid = false;
      err.push('You cannot select a Shift for No Work Recorded.');
    }
    if (
      entry.data.Regular === '' &&
      entry.data.Overtime === '' &&
      entry.data.Doubletime === '' &&
      entry.data.Holiday === '' &&
      entry.data.Vacation === '' &&
      entry.data.SickLeave === '' &&
      entry.data.perDiemRecord === '' &&
      !noWorkRecord
    ) {
      valid = false;
      err.push('You must enter Hours to Enter Time for.');
    }
    if (
      (entry.data.Regular !== '' ||
        entry.data.Overtime !== '' ||
        entry.data.Doubletime !== '' ||
        entry.data.Holiday !== '' ||
        entry.data.Vacation !== '' ||
        entry.data.SickLeave !== '') &&
      noWorkRecord
    ) {
      valid = false;
      err.push('You cannot enter Hours for No Work Recorded.');
    }
    return { valid, err: err.join(' ') };
  };
  const createTimeRecord = async () => {
    const cEntry = { ...entry };
    const validRecord = isValidRecord();
    if (validRecord.valid) {
      if (cEntry.id === '') {
        const docRef = doc(collection(firestore, 'TimeEntries'));
        cEntry.id = docRef.id;
        setDoc(docRef, cEntry, { merge: true });
        const analytics = getAnalytics();
        logEvent(analytics, 'time_entered', {
          user: user.email,
          date: cEntry.ModifiedDate,
          recordID: cEntry.id
        });
        getRecords(mounted.current);
        close();
      } else {
        const docRef = doc(firestore, 'TimeEntries', cEntry.id);
        setDoc(docRef, cEntry, { merge: true });
        getRecords(mounted.current);
        close();
      }
    } else {
      toast.error(validRecord.err);
    }
  };

  const whereClause = () => {
    if (typeof entry.data.Crew !== 'undefined' && entry.data.Crew !== '')
      return [
        [
          'id',
          '==',
          Array.isArray(entry.data.Crew) && existsWithLength(entry.data.Crew)
            ? entry.data.Crew[0]
            : entry.data.Crew
        ]
      ];
    if (
      user &&
      (user.prefab || user.admin) &&
      CurrentForeman.value.length > 0
    ) {
      return [['data.Foreman', '==', CurrentForeman.value[0]]];
    }
    // TODO: GL
    if (CurrentForeman.value.length > 0)
      return [
        ['data.JobNumber', '==', entry.data.JCTDSCID],
        ['data.Foreman', '==', CurrentForeman.value[0]]
      ];

    return [['data.JobNumber', '==', entry.data.JCTDSCID]];
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <div>
          {entry?.data?.id === '' || typeof entry?.data?.id === 'undefined'
            ? 'Entering'
            : 'Modifying'}{' '}
          Time for{' '}
          <span style={{ fontWeight: 'bold' }}>{entry?.data?.JobDisplay}</span>{' '}
          on{' '}
          <span style={{ fontWeight: 'bold' }}>
            {dayjs(entry?.data?.Date).format('ddd MMM DD, YY')}
          </span>
          ...
        </div>
      </Grid>
      <Grid item xs={12}>
        <InputDate
          name='Date'
          label='Work Date'
          collection='TimeEntries'
          firestore={firestore}
          value={entry.data.Date}
          onChange={onChange}
          where={[['data.JobNumber', '==', CurrentJobNumber.value[0]]]}
          valueKey='id'
          enterprise={false}
          disablePortal
        />
      </Grid>
      <Grid item xs={12}>
        <InputAutocomplete
          firebaseConfig={firebaseConfig}
          name='Crew'
          label='Employee'
          firestoreOptions={{
            method: 'onSnapshot',
            collection: 'Crew',
            where: whereClause(),
            orderBy: 'data.EmployeeDisplay',
            valueKey: 'id'
          }}
          value={entry.data.Crew || []}
          onChange={onChange}
          optionDisplay={(opts) => {
            if (Object.keys(opts).length === 0) return '';

            return `${opts.data.EmployeeDisplay} [ Union: ${opts.data.UnionDisplay} ]`;
          }}
          filterDataset={(opts) => {
            const uniqueOpts = [];

            opts.forEach((opt) => {
              const exists = uniqueOpts.findIndex(
                (u) =>
                  u.data.Union[0] === opt.data.Union[0] &&
                  u.data.Employee[0] === opt.data.Employee[0]
              );
              if (exists === -1) {
                uniqueOpts.push(opt);
              }
            });
            if (typeof employee !== 'undefined') {
              const filteredEmps = uniqueOpts.filter(
                (e) => e.data.Employee[0] === employee
              );

              if (!loadedEmp.flag && filteredEmps.length === 1) {
                setLoadedEmp({ flag: true, value: [filteredEmps[0].id] });
              }
              if (!loadedEmp) {
                setLoadedEmp({ flag: true, value: undefined });
              }
              return filteredEmps;
            }
            return uniqueOpts;
          }}
          enterprise={false}
          disablePortal
          disabled={checkLockout}
        />
      </Grid>
      {CurrentJobNumber.value.length === 0 &&
      entry.data.TimeCardType !== 'GL' ? (
        <Grid item xs={12}>
          <InputAutocomplete
            firebaseConfig={firebaseConfig}
            name='JCTDSCID'
            label='Job Number'
            firestoreOptions={{
              method: 'onSnapshot',
              collection: 'ENT-Jobs',
              orderBy: 'JobNumber',
              valueKey: 'jctdscid',
              where: [['JobStatus', '==', 'Open']]
            }}
            value={
              typeof entry.data.JCTDSCID === 'string' &&
              entry.data.JCTDSCID !== ''
                ? [entry.data.JCTDSCID]
                : entry.data.JCTDSCID
            }
            onChange={onChange}
            optionDisplay={(opts) => {
              if (Object.keys(opts).length === 0) return '';

              return `${opts.JobNumber}.${opts.SubJobNumber} - ${opts.JobDescription}`;
            }}
            disablePortal
          />
        </Grid>
      ) : null}
      {entry.data.TimeCardType === 'GL' && (
        <Grid item xs={12}>
          <InputText
            name='GLCode'
            label='GL Code'
            onChange={onChange}
            value={record.data.GLCode || ''}
            disabled
          />
        </Grid>
      )}

      {entry.data.TimeCardType !== 'GL' && (
        <Grid item xs={12}>
          <InputAutocomplete
            firebaseConfig={firebaseConfig}
            name='Picc'
            label='Picc'
            firestoreOptions={{
              method: 'onSnapshot',
              collection: 'ENT-Piccs',
              where: [['jctdscid', '==', entry.data.JCTDSCID]],
              valueKey: 'jctmstid'
            }}
            value={entry.data.Picc || ''}
            requirement={{
              rules:
                entry.data.JCTDSCID !== '' &&
                typeof entry.data.JCTDSCID !== 'undefined',
              text: 'Please Select a Job Number First...'
            }}
            onChange={onChange}
            optionDisplay={(opts) => {
              if (typeof opts.jctdscid === 'undefined') {
                return '';
              }
              return `${opts.PICC} - ${opts.Description} ${
                opts.Status === 'Inactive' ? '[Inactive in ECMS]' : ''
              }`;
            }}
            enterprise
            disablePortal
            disabled={checkLockout}
          />
        </Grid>
      )}
      <Grid item xs={6}>
        <InputSelect
          name='Shift'
          label='Shift'
          value={entry.data.Shift || ''}
          onChange={onChange}
          options={[
            { label: '1', value: '1', default: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' }
          ]}
          disabled={checkLockout}
        />
      </Grid>
      <Grid item xs={6}>
        <InputNumber
          name='Regular'
          label='Regular'
          value={entry.data.Regular || ''}
          onBlur={(val, nam) => RoundTo25(val, nam)}
          disabled={checkLockout}
          min={0}
          max={10}
        />
      </Grid>
      <Grid item xs={6}>
        <InputNumber
          name='Overtime'
          label='Overtime'
          value={entry.data.Overtime || ''}
          onBlur={(val, nam) => RoundTo25(val, nam)}
          disabled={checkLockout}
          min={0}
          max={16}
        />
      </Grid>
      <Grid item xs={6}>
        <InputNumber
          name='Doubletime'
          label='Doubletime'
          value={entry.data.Doubletime || ''}
          onBlur={(val, nam) => RoundTo25(val, nam)}
          disabled={checkLockout}
          min={0}
          max={16}
        />
      </Grid>
      <Grid item xs={6}>
        <InputNumber
          name='Holiday'
          label='Holiday'
          value={entry.data.Holiday || ''}
          onBlur={(val, nam) => RoundTo25(val, nam)}
          disabled={checkLockout}
          min={0}
          max={16}
        />
      </Grid>
      <Grid item xs={6}>
        <InputNumber
          name='Vacation'
          label='Vacation'
          value={entry.data.Vacation || ''}
          onBlur={(val, nam) => RoundTo25(val, nam)}
          disabled={checkLockout}
          min={0}
          max={16}
        />
      </Grid>
      <Grid item xs={6}>
        <InputSelect
          name='SickLeaveType'
          label='Sick Leave Type'
          value={entry.data.SickLeaveType || ''}
          onChange={onChange}
          options={[
            { value: 'SL', label: 'California' },
            { value: 'S1', label: 'MD/DC' },
            { value: 'S2', label: 'IBEW 26 Sick Leave' }
          ]}
          disabled={checkLockout}
        />
      </Grid>
      <Grid item xs={6}>
        <InputNumber
          name='SickLeave'
          label='Sick Leave'
          value={entry.data.SickLeave || ''}
          onBlur={(val, nam) => RoundTo25(val, nam)}
          disabled={checkLockout}
          min={0}
          max={16}
        />
      </Grid>
      <Grid item xs={6} md={3} lg={6} style={{ whiteSpace: 'nowrap' }}>
        <InputSelect
          name='PerDiem'
          label='Per Diem'
          onChange={onChange}
          options={PerDiems}
          disabled={PerDiems.length <= 0 || checkLockout}
          value={entry.data.PerDiem || ''}
        />
      </Grid>
      <Grid item xs={6} md={3} lg={6}>
        <InputCheckboxGroup
          name='ExemptFromCertifiedPayroll'
          value={entry.data.ExemptFromCertifiedPayroll || ''}
          onChange={onChange}
          disabled={checkLockout}
        >
          <InputCheckbox value='true' label='Exempt' />
        </InputCheckboxGroup>
      </Grid>
      <Grid item xs={12} md={6} lg={6} style={{ textAlign: 'left' }}>
        <InputCheckboxGroup
          name='NoWorkRecorded'
          value={entry.data.NoWorkRecorded || ''}
          onChange={onChange}
          disabled={checkLockout}
        >
          <InputCheckbox label='No work recorded.' value='true' />
        </InputCheckboxGroup>
      </Grid>
      <Grid item xs={12} lg={6} style={{ textAlign: 'left' }}>
        <InputCheckboxGroup
          name='LessThan8HoursVerified'
          value={entry.data.LessThan8HoursVerified || ''}
          onChange={onChange}
          disabled={checkLockout}
        >
          <InputCheckbox label='Less Than 8 Hours Verified.' value='true' />
        </InputCheckboxGroup>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1} justifyContent='flex-end'>
          {!checkLockout && (
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                onClick={createTimeRecord}
              >
                Save
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button variant='contained' color='error' onClick={close}>
              Close
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

TimeEntryRecord.propTypes = {
  closePopup: PropTypes.func,
  checkLockout: PropTypes.bool,
  record: PropTypes.objectOf(PropTypes.any),
  employee: PropTypes.string,
  getRecords: PropTypes.func
};
TimeEntryRecord.defaultProps = {
  closePopup: () => {},
  checkLockout: false,
  record: undefined,
  employee: undefined,
  getRecords: () => {}
};

export default TimeEntryRecord;
