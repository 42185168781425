import React, { useContext } from 'react';
import { Avatar, Box, Button, Divider, List, Typography } from '@mui/material';
import { OfflineContext } from '@aldridge/aldg-helpers';
import {
  faBusinessTime,
  faCalculatorAlt
} from '@fortawesome/pro-regular-svg-icons';
import { useRecoilValue } from 'recoil';
import MockUserModal from '_GlobalComponents/Menu/MockUserModal';
import NavItem from './NavItem';
import SidebarData from '../../_GlobalComponents/Menu/SideBarData';
import { UserContext } from '../../providers/UserProvider';
import * as serviceWorker from '../../serviceWorkerRegistration';
import OnlineStatus from '../../_GlobalComponents/OnlineStatus';
import CloudSync from '../../_GlobalComponents/CloudSync';
import IndexedDbWrapper from '../../utils/indexedDbWrapper';
import SubmitHelpDeskTicket from '../../utils/SubmitHelpDeskTicket';
import { config, signOut } from '../../firebase';
import { _AppVersion } from '../../_Recoil/atoms';
import JoinMeetButton from '../../utils/JoinMeetButton';
// import { basicStyles } from '../../theme/index'

const DashboardSidebar = (props) => {
  const { user, setUser } = useContext(UserContext);

  const online = useContext(OfflineContext);
  // eslint-disable-next-line react/prop-types
  const { setApplicationDrawer } = props;
  const AppVersion = useRecoilValue(_AppVersion);
  const fRefreshApplication = () => {
    signOut().then(() => {
      serviceWorker.unregister().then(async () => {
        localStorage.removeItem('DJR-KEY');
        localStorage.removeItem('OfflineInited');
        window.location.reload();
      });
    });
  };
  const data = JSON.parse(JSON.stringify(SidebarData));

  const whichManagerTime = () => {
    const env =
      config.projectId.split('-')[config.projectId.split('-').length - 1];
    switch (env?.toLowerCase().trim()) {
      case 'training':
        return 'https://aldg-djr-training-time-manager.web.app';
      case 'dev':
        return 'https://aldg-djr-dev-time-manager.web.app';
      default:
        return 'https://djr-manager-time.aldridge.app/';
    }
  };
  // const classes = basicStyles();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Avatar src={user.picture} />
        <Typography color='textPrimary' variant='h5'>
          {user.name}
        </Typography>
        <Typography color='textSecondary' variant='body2'>
          {user.jobTitle}
        </Typography>
      </Box>
      {user.admin || user.mocked ? <MockUserModal user={user} setUser={setUser} /> : null}
      <Divider />
      <Box sx={{ p: 2, pb: 1, pt: 0 }}>
        <List>
          {data.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              onClick={setApplicationDrawer}
            />
          ))}
          {user && (user.manager || user.admin) ? (
            <NavItem
              href='/'
              key='Manager Time'
              title='Manager Time'
              icon={faBusinessTime}
              onClick={() => {
                window.location.href = whichManagerTime();
              }}
            />
          ) : null}
          {user && (user.payroll || user.superAdmin) ? (
            <NavItem
              href='/accounting'
              key='Accounting'
              title='Accounting'
              icon={faCalculatorAlt}
              onClick={setApplicationDrawer}
            />
          ) : null}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box
        sx={{
          backgroundColor: 'background.default',
          m: 1,
          p: 1
        }}
      >
        <div
          style={{ marginBottom: '24px', marginTop: '-12px', border: 'double' }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: '8px',
              fontWeight: 'bold'
            }}
          >
            Current App Version: {AppVersion}
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center'
            }}
          >
            <OnlineStatus />
            Current Online Status: {online ? 'Online' : 'Offline'}
          </div>
          <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
            <IndexedDbWrapper projectId={config.projectId}>
              View Pending Records
            </IndexedDbWrapper>
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              marginBottom: '8px'
            }}
          >
            <CloudSync>Download Data</CloudSync>
          </div>
        </div>
        <Typography align='center' gutterBottom variant='h4'>
          Need Help?
        </Typography>
        <div style={{ marginTop: '-8px', paddingBottom: '4px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              pt: 1
            }}
          >
            {online ? (
              <Button
                color='primary'
                variant='contained'
                style={{ whiteSpace: 'break-spaces' }}
                onClick={fRefreshApplication}
              >
                Force Refresh Application
              </Button>
            ) : (
              <div style={{ color: 'red', fontWeight: 'bold' }}>
                You must be online to force refresh your application.
              </div>
            )}
          </Box>
        </div>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 1
          }}
        >
          <Typography align='center' variant='body2'>
            Please contact Customer Support
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            pt: 1
          }}
        >
          <SubmitHelpDeskTicket /> <JoinMeetButton />
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardSidebar;
