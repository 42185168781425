import React, { useState } from 'react';
import { Grid, Button, useTheme, useMediaQuery } from '@mui/material';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputText } from '@aldridge/aldg-data-components';
import { useRecoilState } from 'recoil';
import { _CurrentLocation } from '../../../_Recoil/atoms';

const WeatherSearch = () => {
  const [CurrentLocation, setCurrentLocation] = useRecoilState(_CurrentLocation);
  const [searchedValue, setSearchedValue] = useState({
    value: '',
    type: ''
  });

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));
  const matches2 = useMediaQuery(theme.breakpoints.down('md'));

  const onChange = (e) => {
    const updatedValue = { ...searchedValue };
    if (Number.isNaN(parseInt(e, 10))) {
      updatedValue.type = 'city';
      updatedValue.value = e;
    } else {
      updatedValue.type = 'zip';
      updatedValue.value = e;
    }
    setSearchedValue(updatedValue);
  };

  const onClick = () => {
    const u = JSON.parse(JSON.stringify(CurrentLocation));
    if (searchedValue.type === 'zip') {
      u.query.zip = searchedValue.value;
      u.type = searchedValue.type;
    } else {
      const [city, state] = (searchedValue.value).split(",");
      u.query.city = city;
      if (state)
        u.query.state = state;
      else
        u.query.state = '';
      u.type = searchedValue.type;
    }
    setCurrentLocation(u);
  };

  const enterSearch = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      onClick();
    }
  };

  return (
    <Grid container alignItems="center" style={{ padding: '26px 8px 26px 10px' }}>
      <Grid item xs={8} md={9} style={{ paddingRight: '4px' }}>
        <InputText
          onChange={onChange}
          value={searchedValue.value}
          onKeyUp={enterSearch}
          label='Search Location'
          InputLabelProps={{ shrink: true }}
          name='SearchLocation'
        />
      </Grid>
      <Grid item xs={4} md={3}>
        <Button
          onClick={onClick}
          className='location-button'
          variant='contained'
          color='primary'
        >
          {matches && !matches2 ? <FontAwesomeIcon icon={faSearch} /> : 'Search'}
        </Button>
      </Grid>
    </Grid>
  );
};

export default WeatherSearch;
