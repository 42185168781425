import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
  useMediaQuery
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import {
  orderBy,
  collection,
  query,
  where,
  onSnapshot
} from 'firebase/firestore';
import { firestore } from '../../firebase';
import { basicStyles, icons } from '../../theme';
import AccountingModal from './AccountingModal';

const AccountingDetail = (props) => {
  const [timeEntries, setTimeEntries] = useState([]);
  const { history, employeeId, currentJobNumber, currentDate, weekEndDate } =
    props;

  const [displayBoxOpen, setDisplayBoxOpen] = useState(false);
  const [selectedRecord] = useState(undefined);

  const displayChangeBox = (bool) => {
    setDisplayBoxOpen(bool);
  };
  const classes = basicStyles();
  const theme = useTheme();

  // const AddTime = () => {
  //   if (currentJobNumber !== 0 && currentDate !== '') {
  //     setSelectedRecord(undefined);
  //     displayChangeBox(true);
  //   } else {
  //     toast.error('You must have a Job and Date Selected to enter time...');
  //   }
  // }

  const getRecords = (isMounted) => {
    if (currentJobNumber === 0) {
      setTimeEntries([]);
      return;
    }

    const colRef = (() => {
      let col = collection(firestore, `TimeEntries`);
      col = query(col, where('data.WeekEndDate', '==', weekEndDate));
      col = query(col, where('data.Employee', '==', employeeId));
      col = query(col, orderBy('data.EmployeeDisplay'));
      col = query(col, orderBy('data.Date'));
      return col;
    })();

    onSnapshot(colRef, (res) => {
      const allTimeEntries = [];
      res.forEach((d) => allTimeEntries.push(d.data()));
      if (isMounted) setTimeEntries(allTimeEntries);
    });
  };
  useEffect(() => {
    let mounted = true;
    getRecords(mounted);
    return () => (mounted = false);
  }, [currentJobNumber, currentDate]);

  const checkModalRecord = async () => {
    // if (false) {
    //   setSelectedRecord(r)
    //   displayChangeBox(true);
    // }
  };

  const close = () => {
    history.push('/accounting');
  };

  const parseNumber = (num) => {
    const defaultNum = 0;
    if (Number.isNaN(parseFloat(num))) return defaultNum;
    return parseFloat(num);
  };
  const iconClasses = icons();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const closePopup = () => displayChangeBox(false);
  return (
    <>
      <div style={{ paddingTop: '1rem' }}>
        Time Summary for{' '}
        {timeEntries.length > 0 ? (
          <b>{timeEntries[0].data.EmployeeDisplay}</b>
        ) : null}{' '}
        on Week Ending <b>{weekEndDate}</b>
      </div>
      <Grid container>
        <Modal open={displayBoxOpen} className={classes.modal}>
          <div className={classes.timePaper}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '8px'
              }}
            >
              <h5 style={{ textDecoration: 'underline' }}>Add Time Record</h5>
            </div>

            <div
              style={{
                position: 'relative',
                flex: '1 1 auto',
                padding: '1rem'
              }}
            >
              <AccountingModal
                record={selectedRecord}
                closePopup={closePopup}
                employee={employeeId}
              />
            </div>
          </div>
        </Modal>
        <Grid container style={{ display: 'contents' }}>
          <div
            style={{
              position: 'sticky',
              top: '-1px',
              left: 0,
              right: 0,
              width: '100%',
              height: '56px',
              backgroundColor: 'white',
              padding: '8px',
              margin: '8px 0px',
              display: 'flex',
              justifyContent: 'flex-end',
              zIndex: 100,
              alignSelf: 'flex-start'
            }}
          >
            <div
              className={`${iconClasses.container} ${iconClasses.redContainer}`}
              style={{ marginRight: '2px' }}
              title='Close Employee Detail'
            >
              <Button
                onClick={close}
                disableRipple
                className={iconClasses.buttonWrapper}
              >
                <FontAwesomeIcon icon={faTimes} className={iconClasses.icon} />
              </Button>
            </div>
          </div>
        </Grid>
        <Grid container>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: 'white' }}>
                  <TableCell
                    className={classes.tableHeader}
                    style={{ minWidth: matches ? '70px' : '' }}
                  >
                    Date
                  </TableCell>
                  {matches ? (
                    <TableCell
                      className={classes.tableHeader}
                      style={{ minWidth: '150px' }}
                    >
                      Job/Union/Picc
                    </TableCell>
                  ) : (
                    <>
                      <TableCell className={classes.tableHeader}>Job</TableCell>
                      <TableCell className={classes.tableHeader}>
                        Union
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        Picc
                      </TableCell>
                    </>
                  )}
                  <TableCell className={classes.tableHeader}>Regular</TableCell>
                  <TableCell className={classes.tableHeader}>
                    Overtime
                  </TableCell>
                  <TableCell className={classes.tableHeader}>
                    DoubleTime
                  </TableCell>
                  <TableCell className={classes.tableHeader}>
                    Vac/Hol/SL
                  </TableCell>
                  <TableCell className={classes.tableHeader} colSpan={2}>
                    Per Diem
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {timeEntries.map((timeEntry) => (
                  <TableRow
                    className={classes.row1}
                    key={timeEntry.id}
                    onClick={() => {
                      checkModalRecord(timeEntry);
                    }}
                  >
                    <TableCell
                      className={classes.tableCell}
                      style={{ minWidth: matches ? '70px' : '' }}
                    >
                      {dayjs(timeEntry.data.Date).format('ddd MMM D, YYYY')}
                    </TableCell>
                    {matches ? (
                      <TableCell
                        className={classes.tableCell}
                        style={{ minWidth: '150px' }}
                      >
                        {timeEntry.data.JobDisplay} <br />
                        {timeEntry.data.UnionDisplay} <br />
                        {timeEntry.data.PiccDisplay} <br />
                      </TableCell>
                    ) : (
                      <>
                        <TableCell className={classes.tableCell}>
                          {timeEntry.data.JobDisplay}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {timeEntry.data.UnionDisplay}
                        </TableCell>
                        {timeEntry.data.NoWorkRecorded === 'true' ? null : (
                          <TableCell className={classes.tableCell}>
                            {timeEntry.data.PiccDisplay}
                          </TableCell>
                        )}
                      </>
                    )}
                    {timeEntry.data.NoWorkRecorded === 'true' ? (
                      <TableCell
                        colSpan={5}
                        style={{ textAlign: 'center' }}
                        className={classes.tableCell}
                      >
                        No Work Recorded
                      </TableCell>
                    ) : (
                      <>
                        <TableCell className={classes.tableCell}>
                          {parseNumber(timeEntry.data.Regular).toFixed(2)}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {parseNumber(timeEntry.data.Overtime).toFixed(2)}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {parseNumber(timeEntry.data.Doubletime).toFixed(2)}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {(
                            parseNumber(timeEntry.data.Vacation) +
                            parseNumber(timeEntry.data.SickLeave) +
                            parseNumber(timeEntry.data.Holiday)
                          ).toFixed(2)}
                        </TableCell>
                      </>
                    )}

                    <TableCell className={classes.tableCell}>
                      {timeEntry.data.PerDiem !== ''
                        ? `$${timeEntry.data.PerDiem}`
                        : 'N/A'}
                    </TableCell>
                    {/* <TableCell className={classes.tableCell} align='center'>
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          removeRecord(timeEntry);
                        }}
                      />
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};
AccountingDetail.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  employeeId: PropTypes.string.isRequired,
  currentDate: PropTypes.string,
  currentJobNumber: PropTypes.string,
  weekEndDate: PropTypes.string.isRequired
};
AccountingDetail.defaultProps = {
  history: {
    push: () => {}
  },
  currentDate: undefined,
  currentJobNumber: undefined
};

export default AccountingDetail;
