import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useSetRecoilState } from 'recoil';
import DailyToolbar from '../components/dailyUsage/DailyToolbar';
import { _HeaderTitle } from '../_Recoil/atoms';
import DistributionList from '../components/distribution/DistributionList';
import Distribution from '../components/distribution/DistributionForm';
import { basicStyles } from '../theme';
import ErrorBoundary from '../utils/ErrorBoundary';
import { UserContext } from '../providers/UserProvider';

const DistributionPage = (props) => {
  document.title = 'Distribution';
  const { match, history } = props;
  const classes = basicStyles();
  const { user } = useContext(UserContext);

  const setHeaderTitle = useSetRecoilState(_HeaderTitle);
  useEffect(() => setHeaderTitle('Distribution'), []);
  return (
    <ErrorBoundary componentName='Distribution' user={user}>
      <Grid className={classes.gridItem}>
        <Grid item xs={12} className={classes.gridItem}>
          <div style={{ paddingBottom: '8px' }}>
            <h1>{document.title}</h1>
          </div>
        </Grid>
        <Grid container>
          <DailyToolbar />
        </Grid>
        <Grid item xs={12}>
          {typeof match.params.id === 'undefined' ? (
            <>
              <DistributionList history={history} match={match} />
            </>
          ) : (
            <Distribution history={history} match={match} />
          )}
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
};

DistributionPage.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
};
DistributionPage.defaultProps = {
  match: {
    params: {}
  },
  history: {
    push: () => { }
  }
};
export default DistributionPage;
