import React, { useEffect } from 'react';
import { signOut } from '../firebase';

const SignOut = () => {
  useEffect(() => {
    signOut().then(() => {
      window.location.href = '/';
    });
  }, []);
  return <h1>Signing you out...</h1>;
};

export default SignOut;
