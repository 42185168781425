// eslint-disable-next-line import/no-extraneous-dependencies
import * as ExcelJS from 'exceljs';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';

const downloadWBS = (selection, piccs, CurrentJobNumber) => {
  const workbook = new ExcelJS.Workbook();
  const headers = [
    'Complete',
    'Level0',
    'Level1',
    'Level2',
    'Level3',
    'Level4',
    'PICC',
    'CompletedHours',
    'CompletedQuantity',
    'EstimatedHours',
    'EstimatedQuantity',
    'TotalHours',
    'id'
  ];
  const piccList =
    (selection.length > 0
      ? piccs.filter((r) => selection.indexOf(r.id) > -1)
      : piccs).map((item) => [
        item.data.Complete,
        item.data.Level0,
        item.data.Level1,
        item.data.Level2,
        item.data.Level3,
        item.data.Level4,
        item.data.PayItem,
        item.data.CompletedHours,
        item.data.CompletedQuantity,
        item.data.EstimatedHours,
        item.data.EstimatedQuantity,
        item.data.TotalHours,
        item.id
      ]);

  console.log(piccList);
  const WBSWorksheet = workbook.addWorksheet('WBS');
  WBSWorksheet.getRow(1).values = headers;
  WBSWorksheet.addRows(piccList);
  console.log(workbook);
  workbook.xlsx.writeBuffer().then((r) => {
    saveAs(new Blob([r.buffer]), `${CurrentJobNumber.display}, ${CurrentJobNumber.display}-WBS.xlsx`);
    toast.success(`Your WBS data for${CurrentJobNumber.display} successfully downloaded.`);
  });
};

export default downloadWBS;