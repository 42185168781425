const weekEndDate = (dt) => {
  try {
    const [year, month, date] = dt.split('-');
    const nDt = new Date(`${month}/${date}/${year}`);
    const day = nDt.getDay();
    const diff = nDt.getDate() - day + (day === 0 ? 0 : 7);
    return new Date(nDt.setDate(diff)).toJSON().split('T')[0];
  } catch (err) {
    return 'Invalid Date';
  }
};

const dayDifference = (dt) => {
  const wkdt = new Date(dt);
  const fixedDate = new Date(wkdt.getTime() + wkdt.getTimezoneOffset() * 60000);
  const today = new Date();

  return Math.round(
    (today.getTime() - fixedDate.getTime()) / (1000 * 3600 * 24)
  );
};

export { weekEndDate, dayDifference };
