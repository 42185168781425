/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideoArrowUpRight } from '@fortawesome/pro-solid-svg-icons';
import { checkDate, openMeet } from '../utils/meetUtils';
import { pulseBackgroundStyle } from '../theme';

const MeetStatus = () => {
  const pulseBackground = pulseBackgroundStyle();
  return (checkDate() ?
    <div
      style={{
        position: 'relative',
        margin: '0 1px',
        display: 'flex',
        padding: '5px',
        backgroundColor: 'white',
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        alignItems: 'center'
      }}
      title='Join Support Meet'
    >
      <div
        className={
          checkDate() ? pulseBackground.animatedItem : ''
        }
      />
      {checkDate() && (
        <span
          className='fa-layers fa-fw'
          style={{ cursor: 'pointer' }}
          onClick={openMeet}
          role='button'
        >
          <FontAwesomeIcon style={{ color: '#00ca00' }} icon={faVideoArrowUpRight} />
        </span>
      )}
    </div> : null);
};

export default MeetStatus;
