import React, { useState, useContext } from 'react';
import { Modal, Button, createTheme } from '@mui/material';
import { InputText, InputTextarea } from '@aldridge/aldg-data-components';
import { toast } from 'react-toastify';
import { OfflineContext } from '@aldridge/aldg-helpers';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';

import { UserContext } from '../providers/UserProvider';

const SubmitHelpDeskTicket = () => {
  const [record, setRecord] = useState({
    subject: '',
    message: ''
  });
  const { user } = useContext(UserContext);
  const online = useContext(OfflineContext);
  const [modalOpen, setModalOpen] = useState(false);
  const submitHelpdeskTicket = httpsCallable(functions, 'SubmitIncident');
  const openModal = () => {
    setModalOpen(true);
  };
  const submitTicket = () => {
    if (record.subject !== '' && record.message !== '') {
      submitHelpdeskTicket({ subject: record.subject, message: record.message, sender: user.email })
        .then((res) => {
          if (res.data?.status?.toLowerCase() === 'success') {
            toast.success('Helpdesk Submitted!');
            setModalOpen(false);
          } else {
            toast.error('There was an error submitting the ticket. Please try again later.');
          }
        })
        .catch((err) => {
          toast.error(`${err.message}`);
        }).finally(() => {
          setModalOpen(false);
        });
    } else {
      toast.error('You must fill out the Subject and the Details to submit a ticket.');
    }
  };

  const t = createTheme();

  return (
    <>
      <Modal open={modalOpen} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div
          style={{
            backgroundColor: t.palette.background.paper,
            border: '2px solid #000',
            boxShadow: t.shadows[5],
            outline: 'none',
            padding: t.spacing(1, 2),
            width: '50%',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '12px',
              flexDirection: 'column'
            }}
          >
            <InputText
              name='subject'
              label='Ticket Subject'
              value={record.subject}
              onChange={(e) => {
                setRecord((prev) => ({ ...prev, subject: e }));
              }}
            />
            <InputTextarea
              name='message'
              label='Ticket Details'
              value={record.message}
              onChange={(e) => setRecord((prev) => ({ ...prev, message: e }))}
            />
          </div>

          <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
            {online ? (
              <Button variant='contained' color='primary' onClick={submitTicket}>
                Submit Helpdesk Ticket
              </Button>
            ) : (
              <div style={{ color: 'red', fontWeight: 'bold' }}>You have no internet connection. Please try submitting a ticket later.</div>
            )}
            &nbsp;
            <Button variant='contained' color='error' onClick={() => setModalOpen(false)}>
              Close
            </Button>
          </div>
        </div>
      </Modal>
      {online ? (
        <Button color='primary' onClick={openModal} variant='contained'>
          Add Ticket
        </Button>
      ) : (
        <div style={{ color: 'red', fontWeight: 'bold' }}>You have no internet connection. Please try submitting a ticket later.</div>
      )}
    </>
  );
};

export default SubmitHelpDeskTicket;
