/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import PropTypes from 'prop-types';
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StaticDatePicker from "@mui/lab/StaticDatePicker";
import PickersDay from "@mui/lab/PickersDay";

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "selected"
})(({ theme, selected }) => ({
  ...(selected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark
    },
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
}));

const startOfDay = (day) => day.setHours(0, 0, 0, 0);

const findDate = (dates, date) => {
  const dateTime = new Date(date).getTime();
  return dates.find((item) => new Date(item).getTime() === dateTime);
};

const findIndexDate = (dates, date) => {
  const dateTime = new Date(date).getTime();
  return dates.findIndex((item) => new Date(item).getTime() === dateTime);
};

export default function MultiDatePicker(props) {
  const { values, setValues } = props;

  const renderPickerDay = (date, selectedDates, pickersDayProps) => {
    if (!values) {
      return <PickersDay {...pickersDayProps} />;
    }

    const selected = findDate(values, date);

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        selected={selected}
      />
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        label="Week picker"
        value={values}
        showDaysOutsideCurrentMonth
        sx={{
          '& .css-169iwlq-MuiCalendarPicker-root': {
            backgroundColor: 'purple'
          }
        }}
        onChange={(newValue) => {
          // copying the values array 
          const array = [...values];
          const date = startOfDay(newValue);
          const index = findIndexDate(array, date);
          if (index >= 0) {
            array.splice(index, 1);
          } else {
            array.push(date);
          }
          setValues(array);
        }}
        renderDay={renderPickerDay}
        renderInput={(params) => <TextField {...params} />}
        inputFormat="'Week of' MMM d"
        shouldDisableYear={(year) => new Date(year).getFullYear() < 2020 || new Date(year).getFullYear() > 2024}
      />
    </LocalizationProvider>
  );
}

MultiDatePicker.propTypes = {
  values: PropTypes.array,
  setValues: PropTypes.func
};

MultiDatePicker.defaultProps = {
  values: [new Date()],
  setValues: () => { }
};