import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Grid, Button } from '@mui/material';
import { InputAutocomplete } from '@aldridge/aldg-data-components';
import { collection, doc, setDoc } from 'firebase/firestore';
import { basicStyles } from '../../theme';
import { firebaseConfig, firestore } from '../../firebase';
import existsWithLength from '../../utils/existsWithLength';
import getLastIndex from '../../utils/getLastIndex';

const GenerateOTDTasksModal = (props) => {
  const { open, closePopup, job, foreman, user, date } = props;
  const mounted = useRef(null);
  const [taskCrewRecord, setTaskCrewRecord] = useState({
    OrderNumbers: [],
    taskDetails: [],
    crew: []
  });
  const classes = basicStyles();

  useEffect(() => {
    mounted.current = true;
    return () => { mounted.current = false; };
  }, []);

  const clearOnClose = () => {
    if (mounted)
      setTaskCrewRecord({
        OrderNumbers: [],
        taskDetails: [],
        crew: []
      });
    closePopup();
  };

  const orderChanged = (event, name, displayFromTypeahead, autoRecord) => {
    const changedRecord = JSON.parse(JSON.stringify(taskCrewRecord));
    const value = typeof name !== 'undefined' ? event : event.target.value;
    const n = typeof name !== 'undefined' ? name : event.target.name;
    changedRecord[n] = value;

    if (changedRecord.taskDetails.length > event.length) {
      const removedTask = changedRecord.taskDetails.map(t => t.OrderNumber).filter(oNum => !event.includes(oNum))[0];
      const idxToRemove = changedRecord.taskDetails.map(t => t.OrderNumber).indexOf(removedTask);
      changedRecord.taskDetails.splice(idxToRemove, 1);
    } else {
      const newTask = {};
      newTask.OrderNumber = getLastIndex(value);
      newTask.OrderNumberDisplay = `${getLastIndex(autoRecord)?.data.OrderNumber} - ${getLastIndex(autoRecord)?.data.BriefDescription}`;
      newTask.PICC = getLastIndex(autoRecord)?.data.Picc || [];
      newTask.PICCDisplay = getLastIndex(autoRecord)?.data.PiccDisplay;
      newTask.ScopeOfWork = getLastIndex(autoRecord)?.data.ScopeOfWork || '';
      newTask.JobNumber =
        getLastIndex(autoRecord)?.data?.JobNumber[0] || '';
      newTask.JobNumberDisplay =
        getLastIndex(autoRecord)?.data?.JobNumberDisplay || '';
      newTask.NeedByDate =
        getLastIndex(autoRecord)?.data?.DateNeededBy || '';
      newTask.IsGLTicket = getLastIndex(autoRecord)?.data?.IsGLTicket || '';
      newTask.TypeOfWork = getLastIndex(autoRecord)?.data?.TypeOfWork || '';
      newTask.GLCode = getLastIndex(autoRecord)?.data?.GLCode || '';
      changedRecord.taskDetails.push(newTask);
    }
    if (mounted.current)
      setTaskCrewRecord(changedRecord);
  };

  const crewChanged = (event, name, displayFromTypeahead) => {
    const changedRecord = JSON.parse(JSON.stringify(taskCrewRecord));
    const value = typeof name !== 'undefined' ? event : event.target.value;
    const n = typeof name !== 'undefined' ? name : event.target.name;
    changedRecord[`${n}Display`] = displayFromTypeahead;
    changedRecord[n] = value;
    if (mounted.current)
      setTaskCrewRecord(changedRecord);
  };

  const createTasks = () => {
    taskCrewRecord.taskDetails.forEach((task) => {
      const newDocId = doc(collection(firestore, 'Tasks')).id;
      const taskRecord = { data: {} };

      taskRecord.id = newDocId;
      taskRecord.CreatedBy = user.email;
      taskRecord.ModifiedBy = user.email;
      taskRecord.CreatedDate = new Date().toJSON();
      taskRecord.ModifiedDate = new Date().toJSON();

      taskRecord.data.Date = date;
      taskRecord.data.EmployeesWorkingOnTask = taskCrewRecord.crew;
      taskRecord.data.EmployeesWorkingOnTaskDisplay = taskCrewRecord.crewDisplay || '';
      taskRecord.data.Foreman = foreman.value[0] || '';
      taskRecord.data.ForemanDisplay = foreman.display || '';
      taskRecord.data.JobNumber = task.JobNumber;
      taskRecord.data.JobNumberDisplay = task.JobNumberDisplay || '';
      taskRecord.data.PICC = task.PICC || [];
      taskRecord.data.PICCDisplay = task.PICCDisplay || '';
      taskRecord.data.TaskType = "PreFab";
      taskRecord.data.GLCode = task.GLCode || '';
      taskRecord.data.IsGLTicket = task.IsGLTicket || '';
      taskRecord.data.NeedByDate = task.NeedByDate || '';
      taskRecord.data.ScopeOfWork = task.ScopeOfWork || '';
      taskRecord.data.TypeOfWork = task.TypeOfWork || '';
      taskRecord.data.OrderNumber = [task.OrderNumber];
      taskRecord.data.OrderNumberDisplay = task.OrderNumberDisplay || '';
      taskRecord.data.HideCompleted = 'true';

      taskRecord.data.EndDay = { ObstacleCode: [] };
      taskRecord.data.StartDay = {
        UoM: 'ea'
      };

      setDoc(doc(firestore, 'Tasks', newDocId), taskRecord, { merge: true });
      clearOnClose();
    });
  };

  return <Grid container>
    <Modal open={open} onClose={clearOnClose} className={classes.modal}>
      <div className={classes.modalPaper} style={{ width: '75%' }}>
        <Grid container>
          <Grid item xs={12}>
            <h4
              style={{
                textDecoration: 'underline',
                position: 'relative',
                flex: '1 1 auto',
                padding: '0px',
                paddingTop: '8px'
              }}
            >
              Generate OTD Tasks
            </h4>
          </Grid>
          <Grid item xs={12} style={{ padding: '8px 0px' }}>
            <InputAutocomplete
              callType='function'
              cloudFunction={{
                name: 'otdOrders',
                config: firebaseConfig,
                variables: { job: job.value[0] || '', hideCompleted: 'true' },
                valueKey: 'id'
              }}
              label='Select Orders'
              multiple
              value={taskCrewRecord.OrderNumbers || []}
              onChange={orderChanged}
              name='OrderNumbers'
              disableCloseOnSelect
              optionDisplay={(opts) => {
                if (Object.keys(opts).length === 0) return '';
                return `${opts.data.OrderNumber} - ${opts.data.BriefDescription} [${opts.data.Status}]`;
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ padding: '8px 0px' }}>
            <InputAutocomplete
              label='Select Crew Members'
              value={taskCrewRecord.crew || []}
              onChange={crewChanged}
              name='crew'
              firestoreOptions={{
                method: 'onSnapshot',
                collection: 'Crew',
                valueKey: 'id',
                where: [
                  ['data.Foreman', '==', foreman.value[0]],
                  ['data.JobNumber', '==', job.value[0] || '']
                ],
                orderBy: 'data.EmployeeDisplay'
              }}
              enterprise={false}
              optionDisplay={(opts) =>
                `${opts.data.EmployeeDisplay} ${opts.data.UnionDisplay}`
              }
              multiple
              disableCloseOnSelect
              emptyPlaceholder='There are no crew members.'
            />
          </Grid>
          {existsWithLength(taskCrewRecord.OrderNumbers) &&
            <Grid item xs={12} style={{ padding: '12px 0px 4px 0px', height: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <Button variant='contained' color='error' onClick={clearOnClose}>Cancel</Button>
              <Button variant='contained' onClick={createTasks}>Create Tasks</Button>
            </Grid>}
        </Grid>
      </div>
    </Modal>
  </Grid>;

};


GenerateOTDTasksModal.propTypes = {
  open: PropTypes.bool,
  closePopup: PropTypes.func,
  job: PropTypes.object,
  foreman: PropTypes.object,
  user: PropTypes.object,
  date: PropTypes.string
};
GenerateOTDTasksModal.defaultProps = {
  open: false,
  closePopup: () => { },
  job: { value: [], display: '' },
  foreman: { value: [], display: '' },
  user: { email: '' },
  date: ''
};


export default GenerateOTDTasksModal;