import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { toast } from 'react-toastify';
import {
  Table,
  TableRow,
  TableCell,
  useMediaQuery,
  useTheme,
  Grid,
  TableBody,
  Button
} from '@mui/material';
import { faTrashAlt, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  deleteDoc,
  onSnapshot,
  query,
  doc,
  collection,
  where
} from 'firebase/firestore';
import { firestore } from '../../firebase';
import ConfirmDelete from '../dailyUsage/ConfirmDelete';
import {
  _CurrentDate,
  _CurrentForeman,
  _CurrentJobNumber
} from '../../_Recoil/atoms';
import { icons, basicStyles } from '../../theme';

const DailyReportList = (props) => {
  const { history } = props;
  const [reports, setReports] = useState([]);
  const CurrentForeman = useRecoilValue(_CurrentForeman);
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);
  const CurrentDate = useRecoilValue(_CurrentDate);

  useEffect(() => {
    let mounted = true;
    if (CurrentDate.value === '') {
      toast.info('Date is required to view Daily Reports.');
      setReports([]);
      return;
    }
    if (
      CurrentForeman.value.length === 0 &&
      CurrentJobNumber.value.length === 0
    ) {
      toast.info('Job Number or Foreman is required to view Daily Reports.');
      setReports([]);
      return;
    }

    let q = query(collection(firestore, 'DailyReport'));
    if (CurrentForeman.value.length !== 0) {
      q = query(q, where('data.Foreman', '==', CurrentForeman.value[0]));
    }
    if (CurrentJobNumber.value.length !== 0) {
      q = query(q, where('data.JobNumber', '==', CurrentJobNumber.value[0]));
    }
    q = query(q, where('data.Date', '==', CurrentDate.value));
    onSnapshot(q, (snap) => {
      const docs = [];
      if (!snap.empty) {
        snap.forEach((d) => {
          docs.push(d.data());
        });
      }
      if (mounted) setReports(docs);
    });
    // eslint-disable-next-line consistent-return
    return () => (mounted = false);
  }, [CurrentForeman.value, CurrentDate.value]);

  const [deleteState, setDeleteState] = useState({
    proceedLabel: 'Delete',
    cancelLabel: 'Cancel',
    title: 'Delete Record',
    modalBody: 'Are you sure you want to remove this Record?',
    show: false,
    proceed: (approved, r) => {
      // eslint-disable-next-line no-use-before-define
      removeRecord(approved, r);
    },
    enableEscape: true
  });

  const addNew = () => {
    if (CurrentForeman.value.length === 0 || CurrentDate.value === '') {
      toast.error(
        'Please select a Date and a Foreman to enter a Daily Report.'
      );
      return;
    }
    history.push(`/daily-report/new`);
  };

  const removeRecord = async (a, r) => {
    if (a) {
      if (r.idx !== '') {
        await deleteDoc(doc(firestore, 'DailyReport', r.idx)).then(() => {
          toast.success('Daily Report Removed.');
        });
      }
    }
    setDeleteState({ ...deleteState, show: false });
  };
  const handleDelete = (idx) => {
    setDeleteState({ ...deleteState, show: true, record: { idx } });
  };
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = basicStyles();
  const iconClasses = icons();
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '8px',
          marginTop: '8px'
        }}
      >
        {matches && reports.length === 0 ? (
          <div
            className={`${iconClasses.container} ${iconClasses.addContainer}`}
            style={{ marginRight: '0' }}
          >
            <Button
              onClick={addNew}
              disableRipple
              className={iconClasses.buttonWrapper}
            >
              <FontAwesomeIcon
                icon={faPlus}
                className={iconClasses.icon}
                onClick={addNew}
                title='Add Daily Report'
              />
            </Button>
          </div>
        ) : null}
      </div>
      <ConfirmDelete
        proceedLabel={deleteState.proceedLabel}
        cancelLabel={deleteState.cancelLabel}
        title={deleteState.title}
        modalBody={deleteState.modalBody}
        show={deleteState.show}
        proceed={deleteState.proceed}
        enableEscape={deleteState.enableEscape}
        record={deleteState.record}
      />
      <Table>
        <TableBody>
          <TableRow style={{ backgroundColor: 'white' }}>
            <TableCell className={classes.tableHeader}>Foreman</TableCell>
            <TableCell className={classes.tableHeader}>Job Number</TableCell>
            {matches && reports.length === 0 ? (
              <TableCell className={classes.tableHeader} colSpan={3}>
                Date
              </TableCell>
            ) : (
              <TableCell
                className={classes.tableHeader}
                style={{ paddingRight: '0px' }}
                colSpan={3}
              >
                <Grid container style={{ alignItems: 'center' }}>
                  <Grid item xs={2} align='left'>
                    Date
                  </Grid>
                  <Grid item xs={10} align='right'>
                    <div
                      className={`${iconClasses.container} ${iconClasses.addContainer}`}
                      style={{ margin: '4px 0px' }}
                    >
                      <Button
                        onClick={addNew}
                        disableRipple
                        className={iconClasses.buttonWrapper}
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          className={iconClasses.icon}
                          onClick={addNew}
                          title='Add Daily Report'
                        />
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </TableCell>
            )}
          </TableRow>
          {reports.map((r) => (
            <TableRow
              className={classes.row}
              key={`${r.data.ForemanDisplay}, ${r.data.Date}, ${r.data.JobNumber}`}
            >
              <TableCell
                className={classes.tableCell}
                onClick={() => history.push(`daily-report/${r.id}`)}
              >
                {r.data.ForemanDisplay}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                onClick={() => history.push(`daily-report/${r.id}`)}
              >
                {r.data.JobNumberDisplay}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                onClick={() => history.push(`daily-report/${r.id}`)}
              >
                {r.data.Date}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                style={{ textAlign: 'right' }}
                onClick={() => handleDelete(r.id)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

DailyReportList.propTypes = {
  // match: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any)
};
DailyReportList.defaultProps = {
  // match: {
  //   params: {}
  // },
  history: {
    push: () => {}
  }
};
export default DailyReportList;
