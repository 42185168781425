import React, { useEffect, useState } from 'react';
import { faPen, faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './WeatherDashboardStyle.css';
import axios from 'axios';
import { Button, Grid, Slide, Card } from '@mui/material';
import { useRecoilState } from 'recoil';
import dayjs from 'dayjs';
import MainWeatherCard from './MainWeatherCard';
import HourlyForecast from './HourlyForecast';
import WeatherSearch from './WeatherSearch';
import { _CurrentLocation } from '../../../_Recoil/atoms';
import WeatherForecast from './WeatherForecast';
import { env } from '../../../.env';

const weatherKey = env.REACT_APP_WEATHER_API_KEY;

const WeatherDashboard = () => {
  const [currentLocation, setCurrentLocation] = useRecoilState(_CurrentLocation);
  const [allWeather, setAllWeather] = useState([]);
  const temperatureUnits = 'imperial';
  const [loading, setLoading] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showDesc, setShowDesc] = useState(true);
  const [week, setWeek] = useState(true);
  const [hour, setHour] = useState(false);
  const [additional, setAdditional] = useState({});

  // OpenWeather API request
  const apiRequest = async (query, type) => {
    if (type === '') return;
    setLoading(true);
    const locationUrl = ((apiCall, count) => {
      if (type === 'gps') {
        return `https://api.openweathermap.org/data/2.5/${apiCall}?lat=${query.latitude}&lon=${query.longitude}&units=${temperatureUnits}&cnt=${count}&appid=${weatherKey}`;
      }
      if (type === 'zip') {
        return `https://api.openweathermap.org/data/2.5/${apiCall}?zip=${query.zip}&units=${temperatureUnits}&cnt=${count}&appid=${weatherKey}`;
      }
      if (query.state !== "") {
        return `https://api.openweathermap.org/data/2.5/${apiCall}?q=${query.city},${query.state},USA&units=${temperatureUnits}&cnt=${count}&appid=${weatherKey}`;
      }
      return `https://api.openweathermap.org/data/2.5/${apiCall}?q=${query.city}&units=${temperatureUnits}&cnt=${count}&appid=${weatherKey}`;
    });
    await axios
      .get(locationUrl('weather', 1))
      .then((result) => {
        const currentConditions = result.data;
        setAdditional({
          cityName: currentConditions.name,
          temp: currentConditions.main.temp,
          icon: currentConditions.weather[0].icon,
          description: currentConditions.weather[0].main,
          humidity: currentConditions.main.humidity,
          time: currentConditions.dt,
          high: currentConditions.main.temp_max,
          low: currentConditions.main.temp_min,
          sunrise: currentConditions.sys.sunrise,
          sunset: currentConditions.sys.sunset,
          windSpeed: `${currentConditions.wind.speed} mph`
        });
        const allWeatherUrl = `https://api.openweathermap.org/data/2.5/onecall?lat=${currentConditions.coord.lat}&lon=${currentConditions.coord.lon}&units=${temperatureUnits}&cnt=$1&appid=${weatherKey}`;
        axios.get(allWeatherUrl).then((res) => {
          setAllWeather({
            dailyPrecip: `${Math.round((res.data.hourly[0].pop) * 100)}%`,
            humidity: `${res.data.hourly[0].humidity}%`,
            weekForecast: res.data.daily,
            hourForecast: res.data.hourly,
          });
          setLoading(false);
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log(err);
          setLoading(false);
        });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        setLoading(false);
      });
  };

  const toggleSwitching = (bool1, setBool1, bool2, setBool2) => {
    if (bool1 !== bool2) {
      if (bool1) {
        setBool1(!bool1);
        setTimeout(() => { setBool2(!bool2); }, 300);
        return;
      }
      setBool2(!bool2);
      setTimeout(() => { setBool1(!bool1); }, 300);
    }
  };

  useEffect(() => {
    let mounted = true;
    function success(position) {
      const { latitude } = position.coords;
      const { longitude } = position.coords;
      const changedLocation = JSON.parse(JSON.stringify(currentLocation));
      changedLocation.type = 'gps';
      changedLocation.query.latitude = latitude;
      changedLocation.query.longitude = longitude;
      if (mounted)
        setCurrentLocation(changedLocation);
    }

    function error() {
    }
    navigator.geolocation.getCurrentPosition(success, error);
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    let mounted = true;
    try {
      apiRequest(currentLocation.query, currentLocation.type);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    };
    if (mounted) {
      setShowSearch(false);
      setShowDesc(true);
    }
    return () => (mounted = false);
  }, [currentLocation]);

  return (
    <>
      <Card>
        <Grid
          container
          justifyContent='center'
          alignItems='center'
        >
          {(!loading && additional.cityName === undefined) ? <div style={{ padding: '8px' }}><i style={{ padding: '8px' }}>Access to location denied. Please enter your location below to access weather data.</i> <div style={{ marginTop: '-8px' }}><WeatherSearch /></div></div>
            :
            <Grid item xs={12}>
              <Grid item xs={12} style={{ height: '150px' }}>
                <div style={{ width: "100%", display: 'flex', flexDirection: 'row', overflow: 'hidden', justifyContent: 'flex-start', alignItems: 'center', padding: '8px 8px 0px 8px' }}>
                  <div style={{ paddingLeft: '4px', whiteSpace: 'nowrap', fontSize: '1.5rem' }}>{additional.cityName}</div>
                  <Button style={{
                    maxHeight: '25px', minWidth: '25px', maxWidth: '25px', padding: '6px', marginTop: '-5px', marginLeft: '5px', backgroundColor: '#EEE', color: 'black'
                  }} onClick={() => { toggleSwitching(showSearch, setShowSearch, showDesc, setShowDesc); }}>{showSearch ? <FontAwesomeIcon style={{ fontSize: '16px' }} icon={faArrowLeft} /> : <FontAwesomeIcon style={{ fontSize: '16px' }} icon={faPen} />}
                  </Button>
                  <div style={{ paddingRight: '8px', width: '100%', textAlign: 'right', fontSize: '30px' }}>{dayjs(new Date()).format('MM/DD')}</div>
                </div>
                <Slide direction="left" in={showSearch} timeout={600} mountOnEnter unmountOnExit>
                  <div style={{ paddingLeft: '8px' }}><WeatherSearch /></div>
                </Slide>
                <Slide direction="right" in={showDesc} timeout={600} mountOnEnter unmountOnExit>
                  <div><MainWeatherCard
                    temp={additional.temp}
                    icon={additional.icon}
                    dailyPrecip={(allWeather.dailyPrecip) === 'undefined%' ? '0%' : allWeather.dailyPrecip}
                    humidity={allWeather.humidity}
                    description={additional.description}
                    loading={loading}
                  />
                  </div>
                </Slide>
              </Grid >
            </Grid >
          }
        </Grid >
      </Card>
      {(!loading && additional.cityName === undefined) ? null :
        <Card style={{ marginTop: '8px', height: '210px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ paddingLeft: '11px', paddingTop: '8px', whiteSpace: 'nowrap', textAlign: 'left', fontSize: '1.5rem' }}> {week ? 'Weekly' : 'Hourly'} Forecast</div>
            <div style={{ textAlign: 'right', width: '100%', paddingTop: '8px', paddingRight: '8px' }}><Button style={{ padding: '4px', textTransform: 'none', textDecoration: 'underline', color: '#000000de' }} onClick={() => { toggleSwitching(week, setWeek, hour, setHour); }}>Show {week ? 'Hourly' : 'Weekly'}</Button></div></div>
          <div style={{ marginLeft: '-4px' }}>
            <Slide direction="up" in={week} timeout={600} mountOnEnter unmountOnExit>
              <div style={{ display: 'flex', justifyContent: 'space-between', overflowX: 'scroll', overflowY: 'hidden' }}><WeatherForecast forecast={allWeather.weekForecast} /></div>
            </Slide>
            <Slide direction="up" in={hour} timeout={600} mountOnEnter unmountOnExit>
              <div style={{ display: 'flex', justifyContent: 'space-between', overflowX: 'scroll', overflowY: 'hidden' }}><HourlyForecast forecast={allWeather.hourForecast} /></div>
            </Slide></div>
        </Card>}
    </>
  );
};
export default WeatherDashboard;
