import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRaindrops, faHumidity } from '@fortawesome/pro-regular-svg-icons';
import DailyOverview from './DailyOverview';

const DetailItem = (props) => {
  const { item, icon, label } = props;
  return (
    <>
      <div style={{ textAlign: 'right', whiteSpace: 'nowrap', padding: '8px' }}>
        <FontAwesomeIcon icon={icon} style={{ textAlign: 'right', fontSize: '25px', marginRight: '5px' }} />
        {item} {'  '}{label}</div></>
  )
}

DetailItem.propTypes = {
  item: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.objectOf(PropTypes.any),
  label: PropTypes.string,
};

DetailItem.defaultProps = {
  item: '',
  icon: '',
  label: '',
};

const MainWeatherCard = (props) => {
  const { temp, description, icon, loading, dailyPrecip, humidity } =
    props;
  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        {loading ? (
          <h2>Loading...</h2>
        ) : (
          <Grid item xs={12}>
            <div style={{ width: "100%", display: 'flex', flexDirection: 'row', overflow: 'hidden', alignItems: 'center' }}>
              <div style={{ paddingBottom: '4px', marginLeft: '-8px' }}><DailyOverview temp={temp} description={description} icon={icon} /></div>
              <div style={{ width: '100%' }}>
                <DetailItem item={dailyPrecip} icon={faRaindrops} label="Precipitation Chance" />
                <DetailItem item={humidity} icon={faHumidity} label="Humididty" />
              </div>
            </div>
          </Grid>

        )}
      </Grid>
    </>
  );
};
MainWeatherCard.propTypes = {
  temp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  description: PropTypes.string,
  icon: PropTypes.string,
  loading: PropTypes.bool,
  dailyPrecip: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  humidity: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

MainWeatherCard.defaultProps = {
  temp: '',
  description: '',
  icon: '',
  dailyPrecip: '',
  loading: false,
  humidity: ''
};
export default MainWeatherCard;
