import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import utc from 'dayjs/plugin/utc';
import timezonePlugin from 'dayjs/plugin/timezone';
import objectSupport from 'dayjs/plugin/objectSupport';

dayjs.extend(utc);
dayjs.extend(timezonePlugin);
dayjs.extend(objectSupport);

const meetLink = '';
const meetDayOfWeek = 1; // Monday
const meetStartTime = 13;
const meetEndTime = 14;

const meetTimezone = 'America/Chicago';

export const defaultCheckDateOptions = {
  link: meetLink,
  dayOfWeek: meetDayOfWeek,
  startTime: meetStartTime,
  endTime: meetEndTime,
  timeNow: undefined, // Date() object, defaults to new Date()
  currentTimezone: undefined,
  meetingTimezone: meetTimezone
};

export const checkDate = (checkDateOptions = defaultCheckDateOptions) => {
  const { link, startTime, endTime, dayOfWeek, timeNow, meetingTimezone } = {
    ...defaultCheckDateOptions,
    ...checkDateOptions
  };

  if (link === '') return false;

  const now = dayjs(timeNow).tz(meetingTimezone);
  const day = now.day();
  const hour = now.hour();

  return day === dayOfWeek && hour >= startTime && hour < endTime;
};

const defaultFormatOptions = {
  meetingTimezone: meetTimezone,
  currentTimezone: undefined,
  format: ''
};
const hourFormat = 'h A';
const dayFormat = 'dddd';

const formatTime = (dayjsDeclare, options) => {
  const { meetingTimezone, currentTimezone, format } = {
    ...defaultFormatOptions,
    ...options
  };

  return dayjs() // new Date()
    .tz(meetingTimezone) // new Date() in meetingTZ
    .set(dayjsDeclare) // { hour or day }
    .tz(currentTimezone) // set to local tz
    .format(format); // finally we format
};

export const formatAMPM = (hour, options = {}) =>
  formatTime({ hour }, { format: hourFormat, ...options });

export const formatDayOfWeek = (day, options = {}) =>
  formatTime({ day }, { format: dayFormat, ...options });

// defaultCheckDateOptions = {
//   link: meetLink,
//   dayOfWeek: meetDayOfWeek,
//   startTime: meetStartTime,
//   endTime: meetEndTime,
//   timeNow: undefined,
//   timezone: meetTimezone
// };

export const supportMessage = (options = defaultCheckDateOptions) => {
  const { startTime, endTime, dayOfWeek, currentTimezone, meetingTimezone } = {
    ...defaultCheckDateOptions,
    ...options
  };
  const formatOptions = { meetingTimezone, currentTimezone };
  return `The support team is available to talk between ${formatAMPM(
    startTime,
    formatOptions
  )} and ${formatAMPM(
    endTime,
    formatOptions
  )} your local time on ${formatDayOfWeek(
    dayOfWeek,
    formatOptions
  )}s. Please click this button again during this window.`;
};

export const openMeet = (options = defaultCheckDateOptions) => {
  const { link } = {
    ...defaultCheckDateOptions,
    ...options
  };
  if (!checkDate(options)) {
    toast.warn(supportMessage(options));
  }
  window.open(link, '_blank');
};

export {
  meetDayOfWeek,
  meetDayOfWeek as meetDay, // Backwards compatibility
  meetStartTime,
  meetEndTime,
  meetLink
};
