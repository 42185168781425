/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-await-in-loop */
import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback
} from 'react';
import {
  Button,
  Paper,
  Grid,
  Tabs,
  Tab,
  useTheme,
  TextField,
  Box,
  LinearProgress
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { TabContext } from '@mui/lab';
import { toast } from 'react-toastify';
import { PDFExport } from '@progress/kendo-react-pdf';
import { drawDOM, exportPDF } from '@progress/kendo-drawing';
import { useFunctionCall } from '@aldridge/aldg-helpers';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { useRecoilValue } from 'recoil';
import dayjs from 'dayjs';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import {
  MobileDateRangePicker,
  LocalizationProvider,
  LicenseInfo
} from '@mui/x-date-pickers-pro';
import { httpsCallable } from 'firebase/functions';
import { saveAs } from 'file-saver';
import getAttachments from 'utils/getAttachments';
import DailyJobPDF from '../components/reporting/DailyJobPDF';
import { firestore, auth, functions, config } from '../firebase';
import DailyToolbar from '../components/dailyUsage/DailyToolbar';
import {
  _CurrentDate,
  _CurrentForeman,
  _CurrentJobNumber
} from '../_Recoil/atoms';
import { basicStyles } from '../theme';
import TabPanel from '../theme/TabPanel';
import { UserContext } from '../providers/UserProvider';

const JSZip = require('jszip');

const zip = new JSZip();

LicenseInfo.setLicenseKey(
  '7a8c39361479db86399386f40ee06ec8Tz01ODUzMyxFPTE3MDYzNjg5OTUzMjgsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
);

const firestoreData = async (
  job,
  foreman,
  date,
  record,
  setRecord,
  returnFlag
) =>
  new Promise((resolve) => {
    if (
      job.value.length === 0 ||
      date.value === '' ||
      foreman.value.length === 0
    ) {
      toast.info(
        'A Job Number, Date, and Foreman are required to correctly view this report.'
      );
      return;
    }
    const p = [];
    p.push(
      getDocs(
        query(
          collection(firestore, 'DailyReport'),
          where('data.JobNumber', '==', job.value[0]),
          where('data.Foreman', '==', foreman.value[0]),
          where('data.Date', '==', date)
        )
      )
    );

    p.push(
      getDocs(
        query(
          collection(firestore, 'Tasks'),
          where('data.JobNumber', '==', job.value[0]),
          where('data.Foreman', '==', foreman.value[0]),
          where('data.Date', '==', date)
        )
      )
    );
    p.push(
      getDocs(
        query(
          collection(firestore, 'TimeEntries'),
          where('data.Foreman', '==', foreman.value[0]),
          where('data.JCTDSCID', '==', job.value[0]),
          where('data.Date', '==', date),
          orderBy('data.EmployeeDisplay')
        )
      )
    );
    p.push(
      getDocs(
        query(
          collection(firestore, 'Distribution'),
          where('data.JobNumber', '==', job.value[0]),
          where('data.Foreman', '==', foreman.value[0]),
          where('data.Date', '==', date)
        )
      )
    );
    Promise.all(p).then((res) => {
      const [dailyReport, tasks, timeEntries] = res;
      const dR = [];
      const T = [];
      const tE = [];
      if (!dailyReport.empty) {
        dailyReport.forEach((d) => {
          dR.push(d.data());
        });
      }
      if (!tasks.empty) {
        tasks.forEach((d) => {
          T.push(d.data());
        });
      }
      if (!timeEntries.empty) {
        timeEntries.forEach((d) => {
          tE.push(d.data());
        });
      }

      const changedRecord = { ...record };
      changedRecord.Foreman = foreman.display;
      changedRecord.ForemanPRTMSTID = foreman.value;
      changedRecord.Job = job;
      changedRecord.Date = date;
      changedRecord.DailyReport = dR;
      changedRecord.Tasks = T;
      changedRecord.TimeEntries = tE;
      if (returnFlag) resolve(changedRecord);
      else setRecord(changedRecord);
      resolve(1);
    });
  });
const ReportingToolPDF = () => {
  const [saving] = useState(false);
  const [value, setValue] = useState([null, null]);
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);
  const CurrentForeman = useRecoilValue(_CurrentForeman);
  const CurrentDate = useRecoilValue(_CurrentDate);
  const theme = useTheme();
  const { user } = useContext(UserContext);
  document.title = 'Daily Job Report PDF';

  const allValsDefined = () =>
    CurrentJobNumber.value.length > 0 &&
    CurrentDate.value.length > 0 &&
    CurrentForeman.value.length > 0;

  const pdfTitle = (job, foreman, date) => {
    let result = '';
    if (typeof job !== 'undefined') result += job;
    if (typeof foreman !== 'undefined') result += `- ${foreman}`;
    if (typeof date !== 'undefined') result += `- ${date}`;
    return `${result}.pdf`;
  };

  const { data: files, functionCall: filesFunctionCall } = useFunctionCall(
    'getFiles',
    {}
  );
  const sendEmail = httpsCallable(functions, 'SendEmail');
  // const getFiles = httpsCallable(functions, 'getFiles');

  const pdfRef = useRef(null);
  const pdfRefEmail = useRef(null);
  const pdfExportRefInternal = useRef(null);
  const pdfExportRefExternal = useRef(null);

  const [loading, setLoading] = useState(false);
  const [download, setDownload] = useState(false);
  const [multiPDF, setMultiPDF] = useState([]);
  const [pdfProgress, setpdfProgress] = useState(0);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [record, setRecord] = useState({
    Tasks: [],
    DailyReport: [],
    TimeEntries: []
  });

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      firestoreData(
        CurrentJobNumber,
        CurrentForeman,
        CurrentDate.value,
        record,
        setRecord,
        false
      );
      if (allValsDefined())
        filesFunctionCall({
          path: `${CurrentJobNumber.value[0]}/${CurrentDate.value}/${CurrentForeman.value[0]}`
        });
    }
    return () => {
      mounted = false;
    };
  }, [CurrentJobNumber.value, CurrentForeman.value, CurrentDate.value]);

  useEffect(() => {
    if (auth.currentUser && allValsDefined()) {
      filesFunctionCall({
        path: `${CurrentJobNumber.value[0]}/${CurrentDate.value}/${CurrentForeman.value[0]}`
      });
    }
  }, [auth.currentUser]);

  const multiGetFiles = async (date, foreman) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve) => {
      const multiFiles = await getAttachments(
        true,
        () => {},
        () => {},
        `${CurrentJobNumber.value[0]}/${date}/${foreman}`
      );
      resolve(multiFiles);
    });

  useEffect(() => {
    let mounted = true;
    if (multiPDF.length > 0 && mounted) {
      multiGetFiles(multiPDF[0].Date, multiPDF[0].ForemanPRTMSTID).then((F) => {
        multiPDF[0].files = F;
        setRecord(multiPDF[0]);
      });
    } else if (mounted) setDownload(false);
    return () => {
      mounted = false;
    };
  }, [download, multiPDF]);

  const Base64PDF = async (pRef) =>
    drawDOM(pRef, {
      keepTogether: 'div',
      paperSize: 'Letter',
      scale: 0.795,
      margin: 20
    })
      .then((group) => exportPDF(group))
      .then((dataUri) => dataUri);

  const getDistributionEmails = () =>
    new Promise((resolve, reject) => {
      getDocs(
        query(
          collection(firestore, 'Distribution'),
          where('data.JobNumber', '==', CurrentJobNumber.value[0])
        )
      )
        .then((ds) => {
          const emails = [];
          ds.forEach((d) => {
            emails.push(d.data().data.Employee);
          });
          if (!emails.includes(user.email)) {
            emails.push(user.email);
          }
          resolve(emails.filter((e) => (e || '').trim() !== ''));
        })
        .catch((err) => reject(err));
    });

  const emailPDF = useCallback(
    (type) => {
      setLoading(true);
      getDistributionEmails().then(async (emails) => {
        const emailList =
          config.projectId.indexOf('-dev') > -1 ||
          config.projectId.indexOf('-training') > -1 ||
          window.location.hostname === 'localhost'
            ? ['tgibbs@aldridgegroup.com']
            : emails;
        toast.info(`Sending email to ${emailList.join(', ')}`);
        const PDF = await Base64PDF(pdfRef.current);
        if (new Blob([PDF]).size > 10000000) {
          toast.error(
            'PDF is too large to email automatically. Please download the PDF instead and email it manually.'
          );
          setLoading(false);
        } else {
          sendEmail({
            body: {
              to: emailList,
              subject: `${CurrentJobNumber.display}, ${record.Foreman}, ${record.Date} - ${type}`,
              attachment: [
                {
                  filename: `${CurrentJobNumber.display}, ${record.Foreman}, ${record.Date}.pdf`,
                  content: PDF.substring(28),
                  encoding: 'base64'
                }
              ]
            }
          })
            .then(() =>
              toast.success(
                'Email sent! Please allow a few minutes for the email to reach your inbox.'
              )
            )
            .catch((err) =>
              toast.error(
                `Email failed to send! Please wait and try again. If the issue persists, please put in a helpdesk ticket. ${err.toString()}`
              )
            )
            .finally(() => setLoading(false));
        }
      });
    },
    [record]
  );

  const downloadPdfInternal = () => {
    if (pdfExportRefInternal.current) {
      pdfExportRefInternal.current.save();
    }
  };

  const addPDFToZip = async () => {
    const PDF = await Base64PDF(pdfRef.current);
    zip.file(
      `${CurrentJobNumber.display}, ${record.Foreman}, ${record.Date}.pdf`,
      PDF.substring(28),
      { base64: true }
    );
  };

  useEffect(async () => {
    let mounted = true;
    if (download && mounted) {
      await addPDFToZip().then(() => {
        if (multiPDF.length === 1 && mounted) {
          zip.generateAsync({ type: 'blob' }).then((blob) => {
            saveAs(
              blob,
              `${CurrentJobNumber.display} - ${
                value[0].toJSON().split('T')[0]
              } - ${value[1].toJSON().split('T')[0]}.zip`
            );
          });
          setpdfProgress(100);
          setDownload(false);
          setpdfProgress(0);
        } else if (mounted) {
          setMultiPDF([...multiPDF.slice(1)]);
          setpdfProgress(
            Math.round(
              ((totalTransactions - multiPDF.length) / totalTransactions) * 100,
              2
            )
          );
        }
      });
    }
    return () => {
      mounted = false;
    };
  }, [record.files]);

  const downloadPdfExternal = () => {
    if (pdfExportRefExternal.current) {
      pdfExportRefExternal.current.save();
    }
  };

  const getForemans = async (startDay, endDay) => {
    const foreman = await getDocs(
      query(
        collection(firestore, 'Tasks'),
        where('data.JobNumber', '==', CurrentJobNumber.value[0]),
        where('data.Date', '>=', startDay),
        where('data.Date', '<=', endDay)
      )
    );
    const all = [];
    foreman.forEach((d) => {
      all.push(
        {
          foreman: d.data().data?.Foreman,
          display: d.data().data?.ForemanDisplay
        } || ''
      );
    });
    return all.filter(
      (v, i, a) => a.findIndex((v2) => v2.foreman === v.foreman) === i
    );
  };

  const generatePDF = async (start, end, foreman, t) => {
    if (start > end) return;

    const data = await firestoreData(
      CurrentJobNumber,
      { value: [foreman.foreman], display: foreman.display },
      start,
      record,
      setRecord,
      true
    );
    if (
      data.Tasks.length > 0 ||
      data.TimeEntries.length > 0 ||
      data.DailyReport.length > 0
    )
      t.push(data);
    // Make sure to increment startDay
    await generatePDF(
      dayjs(start).add(1, 'day').format('YYYY-MM-DD'),
      end,
      foreman,
      t
    );
  };
  const GenerateAllPdfs = async () => {
    const [StartDay, EndDay] = value;
    const date1 = dayjs(StartDay).format('YYYY-MM-DD');
    const date2 = dayjs(EndDay).format('YYYY-MM-DD');
    console.log(date1, date2);
    if (date1 === 'Invalid Date' || date2 === 'Invalid Date') {
      toast.error('Please select valid dates before generating PDFs.');
      return;
    }
    setLoading(true);
    const foremans = await getForemans(date1, date2);
    if (foremans.length <= 0) {
      toast.warn(
        'No tasks for this job in the selected date range. No pdfs will be generated.'
      );
      return;
    }
    const dateDifference = dayjs(date2).diff(dayjs(date1), 'days') + 1;
    setpdfProgress(0);
    const transactions = [];
    for (let i = 0; i < foremans.length; i++) {
      await generatePDF(date1, date2, foremans[i], transactions);
      setpdfProgress(
        Math.round(
          ((i * dateDifference) / (foremans.length * dateDifference)) * 100,
          2
        )
      );
    }
    setpdfProgress(100);
    setTotalTransactions(transactions.length);
    setMultiPDF(transactions);
    setDownload(true);
    setLoading(false);
  };
  const classes = basicStyles();

  const useStyles = makeStyles(
    () => ({
      outerPaper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        margin: '14px 4px',
        color: theme.palette.text.error,
        whiteSpace: 'nowrap',
        width: '100%'
      },
      field: {
        padding: '8px'
      },
      tab: {
        textTransform: 'none',
        fontWeight: 'bold',
        color: 'black'
      }
    }),
    { index: 1 }
  );
  const customClasses = useStyles();

  const [tval, setTval] = React.useState(0);

  const handleTChange = (event, newtval) => {
    setTval(newtval);
  };
  return (
    <>
      {saving ? (
        <div
          style={{
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#efefef',
            opacity: 0.5,
            zIndex: 100
          }}
        />
      ) : null}
      {(pdfProgress > 0 && pdfProgress < 100) || download ? (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,.25)',
            zIndex: 2500
          }}
        />
      ) : null}
      <div
        style={{
          position: 'sticky',
          top: 0,
          left: 0,
          right: 0,
          width: '100%',
          backgroundColor: 'white',
          padding: '0px',
          display: 'flex',
          justifyContent: 'flex-end',
          zIndex: 100
        }}
      />
      <Grid className={classes.gridItem}>
        <Grid item xs={12} className={classes.gridItem}>
          <div style={{ paddingBottom: '8px' }}>
            <h1>{document.title}</h1>
          </div>
        </Grid>
        <Grid item xs={12}>
          <DailyToolbar />
        </Grid>
        <Grid item xs={12}>
          {(pdfProgress > 0 && pdfProgress < 100) ||
          download ||
          loading ? null : (
            <div>
              <Button
                variant='contained'
                style={{ margin: '20px' }}
                color='primary'
                onClick={tval === 0 ? downloadPdfInternal : downloadPdfExternal}
              >
                Download PDF - {tval === 0 ? 'Internal' : 'External'}
              </Button>
              <Button
                variant='contained'
                style={{ margin: '20px' }}
                color='primary'
                onClick={
                  tval === 0
                    ? () => {
                        emailPDF('Internal');
                      }
                    : () => {
                        emailPDF('External');
                      }
                }
              >
                Email - {tval === 0 ? 'Internal' : 'External'}
              </Button>
            </div>
          )}

          <Grid
            item
            xs={12}
            style={{
              display: loading ? 'none' : 'flex',
              justifyContent: 'center',
              marginTop:
                (pdfProgress > 0 && pdfProgress < 100) || download
                  ? '12px'
                  : '0px'
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              localeText={{ start: 'Start Date', end: 'End Date' }}
            >
              <MobileDateRangePicker
                displayStaticWrapperAs='desktop'
                value={value}
                onChange={(newValue) => {
                  if (!(pdfProgress > 0 && pdfProgress < 100) || download)
                    setValue(newValue);
                }}
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField {...startProps} />
                    <Box sx={{ mx: 2 }}> to </Box>
                    <TextField {...endProps} />
                  </>
                )}
              />
            </LocalizationProvider>
          </Grid>
          {loading ? (
            <div style={{ paddingTop: '12px' }}>Processing, please wait...</div>
          ) : (
            <Grid item xs={12}>
              {(pdfProgress > 0 && pdfProgress < 100) || download ? (
                <Grid style={{ display: 'flex' }}>
                  <LinearProgress
                    variant='determinate'
                    value={pdfProgress}
                    style={{ margin: '12px', width: '100%' }}
                  />{' '}
                  <div style={{ whiteSpace: 'nowrap', alignSelf: 'center' }}>
                    {download ? 'Creating ZIP File...' : 'Generating PDFs'}
                  </div>
                </Grid>
              ) : (
                <Button
                  variant='contained'
                  style={{ margin: '20px' }}
                  color='primary'
                  onClick={GenerateAllPdfs}
                >
                  Generate PDFs
                </Button>
              )}
            </Grid>
          )}
          <Paper
            firestore={firestore}
            className={customClasses.outerPaper}
            variant='outlined'
          >
            <Grid item xs={12} style={{ display: 'inline-block' }}>
              <Tabs
                value={tval}
                textColor='primary'
                onChange={handleTChange}
                variant='scrollable'
                scrollButtons='auto'
              >
                {(pdfProgress > 0 && pdfProgress < 100) || download
                  ? null
                  : [
                      <Tab
                        label='Internal'
                        key='Internal'
                        className={customClasses.tab}
                      />,
                      <Tab
                        label='External'
                        key='External'
                        className={customClasses.tab}
                      />
                    ]}
              </Tabs>
            </Grid>

            <TabContext value={tval.toString()}>
              <TabPanel value={tval} index={0}>
                <Paper variant='outlined' className={customClasses.field}>
                  <Grid container>
                    <Grid item className={customClasses.field} xs={12}>
                      <PDFExport
                        ref={pdfExportRefInternal}
                        paperSize='Letter'
                        keepTogether='div'
                        fileName={pdfTitle(
                          CurrentJobNumber.display,
                          record.Foreman,
                          record.Date
                        )}
                        scale={0.8}
                        key='a'
                      >
                        <div
                          ref={pdfRefEmail}
                          style={{ margin: '20px' }}
                          key='b'
                        >
                          {record.Tasks ? (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center'
                              }}
                            >
                              <DailyJobPDF
                                transaction={record}
                                files={
                                  typeof record.files === 'undefined'
                                    ? files
                                    : record.files
                                }
                                ref={pdfRef}
                                key='c'
                              />
                            </div>
                          ) : null}
                        </div>
                      </PDFExport>
                    </Grid>
                  </Grid>
                </Paper>
              </TabPanel>
              <TabPanel value={tval} index={1}>
                <Paper variant='outlined' className={customClasses.field}>
                  <Grid item className={customClasses.field} xs={12}>
                    <PDFExport
                      ref={pdfExportRefExternal}
                      paperSize='Letter'
                      keepTogether='div'
                      scale={0.8}
                      fileName={pdfTitle(
                        CurrentJobNumber.display,
                        CurrentForeman.display,
                        CurrentDate.display
                      )}
                      key='a'
                    >
                      <div ref={pdfRefEmail} style={{ margin: '20px' }} key='b'>
                        {record.Tasks ? (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center'
                            }}
                          >
                            <DailyJobPDF
                              transaction={record}
                              files={
                                typeof record.files === 'undefined'
                                  ? files
                                  : record.files
                              }
                              external
                              ref={pdfRef}
                              key='c'
                            />
                          </div>
                        ) : null}
                      </div>
                    </PDFExport>
                  </Grid>
                </Paper>
              </TabPanel>
            </TabContext>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ReportingToolPDF;
