/* eslint-disable import/no-extraneous-dependencies */
import { toast } from 'react-toastify';
import * as ExcelJS from 'exceljs';
import {
  query,
  getDocs,
  doc,
  collection,
  where,
  writeBatch
} from 'firebase/firestore';
import { isUndefined } from '@aldridge/aldg-helpers';
import { firestore } from '../../firebase';

const PiccManagement = (piccs, wbsRec) => {
  let currentPicc = "";
  if (
    typeof wbsRec.data.PICC !== 'undefined' &&
    wbsRec.data.PICC !== ''
  ) {
    currentPicc = wbsRec.data.PICC.toString().replace(/[^0-9]/gi, '');
  }
  // eslint-disable-next-line no-loop-func
  const piccRecord = piccs.filter(
    (picc) => picc.PICC === currentPicc
  );
  wbsRec.data.PayItem = currentPicc;
  if (piccRecord.length > 0) {
    wbsRec.data.Picc = [piccRecord[0].jctmstid];
    wbsRec.data.PiccDisplay = `${piccRecord[0].PICC} - ${piccRecord[0].Description}`;
  } else {
    wbsRec.data.Picc = [];
    wbsRec.data.PiccDisplay = ``;
  }
};

// eslint-disable-next-line no-unused-vars
const postRecords = async (records) => {
  let newCount = 0;
  let updateCount = 0;
  let batch = writeBatch(firestore);
  for (let u = 0; u < records.length; u++) {
    if (u !== 0 && u % 500 === 0) {
      // eslint-disable-next-line no-await-in-loop
      await batch.commit();
      batch = writeBatch(firestore);
    }
    const rec = records[u];
    let docRef;
    if (rec.id === '' || typeof rec.id === 'undefined') {
      newCount += 1;
      docRef = doc(collection(firestore, 'PICC'));
      rec.id = docRef.id;
    } else {
      updateCount += 1;
      docRef = doc(collection(firestore, 'PICC'), rec.id);
    }

    batch.set(docRef, rec, { merge: true });
  }
  await batch.commit();

  return [newCount, updateCount];
};
const processExcel = (user, data, CurrentJobNumber, setLoading, getData) => {
  const workbook = new ExcelJS.Workbook();
  workbook.xlsx.load(data).then(() => {
    const WBSWorksheet = workbook._worksheets.find((sheet) => typeof sheet !== 'undefined' && sheet.name === 'WBS');
    console.log(WBSWorksheet);
    if (isUndefined(WBSWorksheet)) {
      throw new Error(`The workbook doesn't have a worksheet named "WBS"`);
    }
    const wsHeader = WBSWorksheet.getRow(1).values.map(r => r.replace(/[^a-z0-9]/gi, ''));
    const wsRows = WBSWorksheet.getRows(2, WBSWorksheet.lastRow.number);
    console.log(wsHeader);
    console.log(wsRows);
    const fRef = query(
      collection(firestore, 'ENT-Piccs'),
      where('jctdscid', '==', CurrentJobNumber.value[0])
    );
    getDocs(fRef).then(async (jobPiccs) => {
      const piccs = [];
      jobPiccs.forEach(p => piccs.push(p.data()));

      const records = [];

      for (let r = 0; r < wsRows.length; r++) {
        const cols = wsRows[r].values;
        if (cols.length > 0) {
          const wbsRec = {
            id: '',
            CreatedBy: user.email,
            CreatedDate: new Date().toJSON(),
            ModifiedBy: '',
            ModifiedDate: '',
            data: {
              JobNumber: CurrentJobNumber.value[0],
              Picc: [],
              Complete: 'No',
              PayItem: '',
              EstimatedHours: '',
              EstimatedQuantity: '',
              TotalHours: '',
              CompletedHours: '',
              CompletedQuantity: '',
              Level0: '',
              Level1: '',
              Level2: '',
              Level3: '',
              Level4: ''
            }
          };
          for (let c = 0; c < cols.length; c++) {
            if (wsHeader[c]) {
              if (wsHeader[c] === 'id') {
                wbsRec[wsHeader[c]] = cols[c];
              } else {
                wbsRec.data[wsHeader[c]] = (cols[c] || '').toString();
              }
            }
          }
          PiccManagement(piccs, wbsRec);
          records.push(JSON.parse(JSON.stringify(wbsRec)));

        }
      }
      const [newCount, updateCount] = await postRecords(records);
      console.log(records);
      // const newCount = 0;
      // const updateCount = 0;
      toast.success(
        `Uploaded ${records.length
        } WBS Codes. ${newCount > 0
          ? `${newCount} New Record(s) have been added. `
          : ''
        } ${updateCount > 0
          ? `${updateCount} Record(s) have been updated.`
          : ''
        }`,
        { autoClose: 15000 }
      );
      getData();
      setLoading(false);
    });
  }).catch((err) => {
    toast.error("Failed Reading the file. Is the file saved as an Excel file?");
    toast.error(err.message);
  });
};
const uploadWBS = (user, CurrentJobNumber, getData, setLoading) => {
  setLoading(true);
  const fileUpBtn = document.createElement('input');
  fileUpBtn.type = 'file';
  fileUpBtn.name = 'uploadWBS';
  fileUpBtn.id = 'uploadWBS';
  fileUpBtn.style = 'display:none;';
  fileUpBtn.addEventListener('change', (e) => {
    const [file] = e.target.files;
    file.arrayBuffer().then((filedata) => {
      processExcel(user, filedata, CurrentJobNumber, setLoading, getData);
    });
  });
  document.body.appendChild(fileUpBtn);
  fileUpBtn.click();
};

export default uploadWBS;
