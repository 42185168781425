import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRecoilValue } from 'recoil';
import { toast } from 'react-toastify';
import { Button, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { faSave, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { collection, setDoc, doc, onSnapshot } from 'firebase/firestore';
import { firestore } from '../../firebase';
import {
  _CurrentDate,
  _CurrentForeman,
  _CurrentJobNumber
} from '../../_Recoil/atoms';
import { UserContext } from '../../providers/UserProvider';
import DailyReportForm from './DailyReportForm';
import { icons } from '../../theme';

const DailyReport = (props) => {
  const { history, match } = props;
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);
  const CurrentForeman = useRecoilValue(_CurrentForeman);
  const CurrentDate = useRecoilValue(_CurrentDate);
  const { user } = useContext(UserContext);
  const ForemanSigRef = useRef({});
  const CustomerSigRef = useRef({});
  const [record, setRecord] = useState({
    id: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    ModifiedDate: '',
    data: {
      JobNumber: CurrentJobNumber.value[0],
      JobNumberDisplay: CurrentJobNumber.display,
      Foreman: CurrentForeman.value[0],
      ForemanDisplay: CurrentForeman.shortDisplay || '',
      Date: CurrentDate.value,
      DateDisplay: CurrentDate.display,
      Weather: '',
      NumberOfAEPersonnelOnsite: '',
      Subcontractors: [],
      Equipments: [],
      InternalNotes: '',
      ExternalNotes: '',
      ForemanSignature: '',
      ForemanSignaturePrint: '',
      ExtraWorkChangesDetail: '',
      CustomerSignature: '',
      CustomerSignaturePrint: ''
    }
  });

  useEffect(() => {
    let mounted = true;
    if (match.params.id === 'new') {
      return () => null;
    }

    onSnapshot(doc(firestore, 'DailyReport', match.params.id), (snap) => {
      if (snap.exists) {
        const d = snap.data();
        if (mounted) setRecord(d);
        if (ForemanSigRef.current && d.data.ForemanSignature !== '')
          ForemanSigRef.current.fromDataURL(d.data.ForemanSignature, {
            ratio: 1
          });
        if (CustomerSigRef.current && d.data.CustomerSignature !== '')
          CustomerSigRef.current.fromDataURL(d.data.CustomerSignature, {
            ratio: 1
          });
      }
    });
    return () => (mounted = false);
  }, []);

  const saveReport = () => {
    try {
      if (match.params.id === 'new') {
        const updatedRec = { ...record };
        const docRef = doc(collection(firestore, 'DailyReport'));
        updatedRec.id = docRef.id;
        setDoc(docRef, updatedRec);
        setRecord(updatedRec);
        history.push(`/daily-report`);
        return;
      }
      setDoc(doc(firestore, 'DailyReport', record.id), record, { merge: true });
      history.push(`/daily-report`);
      toast.success('Form saved successfully!', { autoClose: 5000 });
    } catch (err) {
      toast.error('Error occured while saving form.', { autoClose: 5000 });
    }
  };

  const close = () => {
    history.push(`/daily-report`);
  };

  const handleChange = (event, name, display) => {
    try {
      let id;
      let value;
      const changedReport = { ...record };
      if (typeof name === 'object' && name !== null) {
        id = name.target.name;
        value = typeof name !== 'undefined' ? event : event.target.value;
      } else {
        id = name || event.target.name;
        value = typeof name !== 'undefined' ? event : event.target.value;
      }
      if (typeof display !== 'undefined') {
        changedReport.data[`${name}Display`] = display;
      }
      if (id === 'IncidentDate') {
        if (event.target.value === '') {
          changedReport.data[id] = event.target.value;
          changedReport.data[`${id}_Date`] = event.target.value;
          changedReport.data[`${id}_Time`] = event.target.value;
        } else {
          changedReport.data[id] = event.target.value;
          [changedReport.data[`${id}_Date`], changedReport.data[`${id}_Time`]] =
            event.target.value.split('T');
        }
      } else if (id === 'NumberOfAEPersonnelOnsite' && value < 0) value = 0;
      changedReport.ModifiedBy = user.email;
      changedReport.ModifiedDate = new Date().toJSON();

      changedReport.data[id] = value;
      setRecord(changedReport);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      toast.error(err.message, { autoClose: 8000 });
    }
  };
  const iconClasses = icons();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <div
        style={{
          position: 'sticky',
          top: '-1px',
          left: 0,
          right: 0,
          width: '100%',
          padding: '8px',
          paddingRight: '0px',
          display: 'flex',
          justifyContent: 'flex-end',
          zIndex: 100
        }}
      >
        <div
          className={`${iconClasses.container} ${iconClasses.greenContainer}`}
          style={{ margin: matches ? '0px -8px' : '0px 12px' }}
          title='Save Record'
        >
          <Button
            onClick={saveReport}
            disableRipple
            className={iconClasses.buttonWrapper}
          >
            <FontAwesomeIcon icon={faSave} className={iconClasses.icon} />
          </Button>
        </div>
        <div
          className={`${iconClasses.container} ${iconClasses.redContainer}`}
          style={{ margin: matches ? '0px 0px 0px 12px' : '0px 0px 0px 12px' }}
          title='Close Record'
        >
          <Button
            onClick={close}
            disableRipple
            className={iconClasses.buttonWrapper}
          >
            <FontAwesomeIcon icon={faTimes} className={iconClasses.icon} />
          </Button>
        </div>
      </div>
      <DailyReportForm
        record={record}
        setRecord={setRecord}
        handleChange={handleChange}
        ForemanSigRef={ForemanSigRef}
        CustomerSigRef={CustomerSigRef}
      />
    </>
  );
};

DailyReport.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any)
};
DailyReport.defaultProps = {
  match: {
    params: {}
  },
  history: {
    push: () => { }
  }
};
export default DailyReport;
