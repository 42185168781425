export const env = {
  REACT_APP_APIKEY: 'AIzaSyCxuQvgqDeRapZoerRs7WXm-RM3GrJyWeY',
  REACT_APP_AUTHDOMAIN: 'aldg-djr.firebaseapp.com',
  REACT_APP_PROJECTID: 'aldg-djr',
  REACT_APP_STORAGEBUCKET: 'aldg-djr.appspot.com',
  REACT_APP_MESSAGINGSENDERID: '854882008831',
  REACT_APP_APPID: '1:854882008831:web:78ce23fc68bea5e63656ca',
  REACT_APP_MEASUREMENTID: 'G-9FVQ38G1LP',
  REACT_APP_URL: 'https://api.openweathermap.org/data/2.5/',
  REACT_APP_WEATHER_API_KEY: 'a70ba947c0c521e840c61c5b2355c683',
  REACT_APP_ICON_URL: 'https://openweathermap.org/img/w/',
  REACT_APP_URL: 'https://api.openweathermap.org/data/2.5'
};
