import React, { useEffect } from 'react';
import { Snackbar, Button } from '@mui/material';
import { signOut } from './firebase';
import * as serviceWorker from './serviceWorkerRegistration';

const ServiceWorkerWrapper = () => {
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] = React.useState(null);

  const onSWUpdate = (registration) => {
    localStorage.removeItem('ChangelogShown');
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = async () => {
    signOut().then(() => {
      localStorage.removeItem('ChangelogShown');
      waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
      setShowReload(false);
      window.location.reload(true);
    });
  };

  return (
    <Snackbar
      open={showReload}
      message='A new version is available!'
      onClick={reloadPage}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      action={
        <Button color='inherit' size='small' onClick={reloadPage}>
          Reload
        </Button>
      }
    />
  );
};

export default ServiceWorkerWrapper;
