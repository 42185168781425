import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, useMediaQuery, Paper, Alert } from '@mui/material';
import { faSparkles } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@mui/material/styles';
import { InputAutocomplete, InputSelect } from '@aldridge/aldg-data-components';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { faSave, faTimes } from '@fortawesome/pro-solid-svg-icons';
import {
  onSnapshot,
  setDoc,
  doc,
  collection,
  query,
  where,
  orderBy,
  getDocs
} from 'firebase/firestore';
import { firestore, firebaseConfig } from '../../firebase';
import { UserContext } from '../../providers/UserProvider';
import { _CurrentForeman, _CurrentJobNumber, _Job } from '../../_Recoil/atoms';
import QuickSearchToolbar from '../../_GlobalComponents/QuickFilterToolbar';
import { icons, basicStyles } from '../../theme';

const CrewListSelection = (props) => {
  const classes = basicStyles();
  const { history, match } = props;
  const [crews, setCrews] = useState([]);
  const { user } = useContext(UserContext);
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);
  const CurrentJob = useRecoilValue(_Job);
  const CurrentForeman = useRecoilValue(_CurrentForeman);
  const mounted = useRef(null);
  const [perDiemOptions, setPerDiemOptions] = useState([]);
  const [record, setRecord] = useState({
    id: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    ModifiedDate: '',
    data: {
      JobNumber: CurrentJobNumber.value[0] || '',
      Job: CurrentJob || '',
      Foreman: CurrentForeman.value[0] || '',
      ForemanDisplay: CurrentForeman.shortDisplay || '',
      DefaultPerDiem: '',
      Employee: [],
      Union: []
    }
  });

  useEffect(() => {
    if (match.params.id === 'new') return;
    mounted.current = true;
    onSnapshot(doc(firestore, 'Crew', match.params.id), (snap) => {
      if (snap.exists) {
        const r = snap.data();
        if (mounted.current) setRecord({ ...r });
      }
    });
    // eslint-disable-next-line consistent-return
    return () => (mounted.current = false);
  }, [match.params.id]);

  const onChange = (event, name, displayFromTypeahead) => {
    try {
      const id = name || event.target.name;
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changedRecord = { ...record };
      changedRecord.data[id] = value;
      if (typeof displayFromTypeahead !== 'undefined') {
        changedRecord.data[`${id}Display`] = displayFromTypeahead;
      }
      if (changedRecord.CreatedDate === '') {
        changedRecord.CreatedBy = user.email;
        changedRecord.CreatedDate = new Date().toJSON();
      }
      changedRecord.ModifiedBy = user.email;
      changedRecord.ModifiedDate = new Date().toJSON();
      setRecord(changedRecord);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const getRecords = () => {
    const colRefJob = (() => {
      let col = collection(firestore, `Crew`);
      if (CurrentForeman.value.length > 0)
        col = query(col, where('data.Foreman', '==', CurrentForeman.value[0]));
      else col = query(col, where('data.Foreman', '==', ''));

      if (CurrentJobNumber.value.length > 0)
        col = query(
          col,
          where('data.JobNumber', '==', CurrentJobNumber.value[0])
        );
      col = query(col, orderBy('data.EmployeeDisplay'));
      return col;
    })();
    const allCrewMembers = [];
    if (typeof colRefJob !== 'undefined') {
      onSnapshot(colRefJob, (res) => {
        res.forEach((d) => allCrewMembers.push(d.data()));
      });
    } else if (mounted.current) {
      setCrews([]);
      return;
    }
    if (mounted.current) setCrews(allCrewMembers);
  };

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) getRecords();
    return () => (mounted.current = false);
  }, [CurrentForeman, CurrentJobNumber]);

  useEffect(() => {
    mounted.current = true;
    const perDiemArr = [];
    getDocs(
      query(
        collection(firestore, 'PerDiems'),
        where('status', '==', 'A'),
        orderBy('order')
      )
    ).then((perDiems) => {
      perDiems.forEach((pd) => {
        const perDiemEntry = pd.data();
        perDiemArr.push({
          value: perDiemEntry.value,
          label: perDiemEntry.label
        });
      });
      setPerDiemOptions(perDiemArr);
    });
  }, []);

  const addCrewMember = (event, newRecord) => {
    const savedRecord = JSON.parse(JSON.stringify(record));
    if (record.data.Employee.length === 0 || record.data.Union.length === 0) {
      toast.error(
        'You need to select both an Employee and a Union to add them to your crew.'
      );
      return;
    }
    for (let i = 0; i < crews.length; i++) {
      if (
        savedRecord.data.Employee[0] === crews[i].data.Employee[0] &&
        savedRecord.data.Union[0] === crews[i].data.Union[0] &&
        (CurrentForeman.value[0] || '') === crews[i].data.Foreman &&
        CurrentJobNumber.value[0] === crews[i].data.JobNumber &&
        crews[i].id !== savedRecord.id
      ) {
        toast.error(
          `${savedRecord.data.EmployeeDisplay} [${savedRecord.data.UnionDisplay}] is already in this crew.`,
          { toastId: 'DuplicateCrewMember' }
        );
        return;
      }
    }
    if (match.params.id === 'new') {
      const docRef = doc(collection(firestore, 'Crew'));
      savedRecord.id = docRef.id;
      setDoc(docRef, savedRecord);
      // toast.success('Crew Member Added.');

      toast.success(
        `"${savedRecord.data.EmployeeDisplay}" has been added to your Crew.`
      );
    } else {
      toast.success(
        `"${savedRecord.data.EmployeeDisplay}" has been updated on your Crew.`
      );
      setDoc(doc(firestore, 'Crew', savedRecord.id), savedRecord, {
        merge: true
      });
    }
    if (newRecord) {
      setRecord({
        id: '',
        CreatedBy: '',
        CreatedDate: '',
        ModifiedBy: '',
        ModifiedDate: '',
        data: {
          Job: CurrentJob || '',
          JobNumber: CurrentJobNumber.value[0] || '',
          Foreman: CurrentForeman.value[0] || '',
          ForemanDisplay: CurrentForeman.shortDisplay || '',
          Employee: [],
          Union: []
        }
      });
      history.push('/Crew/new');
    } else {
      history.push('/Crew');
    }
    // closeRecord();
  };
  const backToCrew = () => {
    history.push('/Crew');
  };

  const onEmployeeChange = (event, name, displayFromTypeahead) => {
    onChange(event, name, displayFromTypeahead.split(' - [')[0]);
  };
  const iconClasses = icons();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div style={{ paddingTop: '16px' }}>
      <div
        style={{
          position: 'sticky',
          top: '-1px',
          left: 0,
          right: 0,
          width: '100%',
          padding: '8px',
          paddingRight: '0px',
          display: 'flex',
          justifyContent: 'flex-end',
          zIndex: 100
        }}
      >
        <div
          className={`${iconClasses.container} ${iconClasses.greenContainer}`}
          style={{ margin: matches ? '' : '0px 12px' }}
          title='Save Record'
        >
          <Button
            onClick={addCrewMember}
            disableRipple
            className={iconClasses.buttonWrapper}
          >
            <FontAwesomeIcon icon={faSave} className={iconClasses.icon} />
          </Button>
        </div>

        <div
          className={`fa-layers fa-fw ${iconClasses.container} ${iconClasses.blueContainer}`}
          style={{ margin: matches ? '0px -8px' : '0px 12px' }}
          title='Save and New Record'
        >
          <Button
            onClick={(event) => addCrewMember(event, true)}
            disableRipple
            className={iconClasses.buttonWrapper}
          >
            <FontAwesomeIcon
              style={{ fontSize: '24px', color: 'white' }}
              icon={faSave}
            />
            <FontAwesomeIcon
              style={{ color: 'black', top: '-14px', left: '16px' }}
              icon={faSparkles}
            />
          </Button>
        </div>
        <div
          className={`${iconClasses.container} ${iconClasses.redContainer}`}
          style={{ margin: '0px 0px 0px 12px' }}
          title='Close Record'
        >
          <Button
            onClick={backToCrew}
            disableRipple
            className={iconClasses.buttonWrapper}
          >
            <FontAwesomeIcon icon={faTimes} className={iconClasses.icon} />
          </Button>
        </div>
      </div>
      <Paper className={classes.simplePaper}>
        Don&apos;t add Office Personnel to Crew.
        <Grid container spacing={0}>
          <Grid
            item
            className={classes.field}
            xs={12}
            style={{ marginBottom: '8px' }}
          >
            <InputAutocomplete
              firebaseConfig={firebaseConfig}
              label='Employee'
              firestoreOptions={{
                method: 'get',
                collection: 'ENT-Employees',
                where: [
                  ['Status', '==', 'A'],
                  ['EmployeeType', 'in', ['Field', 'Fleet']]
                ],
                orderBy: ['LegalLastName', 'PreferredFirstName'],
                valueKey: 'prtmstid'
              }}
              name='Employee'
              onChange={onEmployeeChange}
              optionDisplay={(opts) => {
                if (Object.keys(opts).length === 0) return '';
                return `${opts.LegalLastName} ${opts.Suffix || ''}, ${
                  opts.PreferredFirstName
                } ${opts.MiddleName} - [${opts.Title} - ${
                  opts.UniversalID
                }]`.replace(/ {2}/g, ' ');
              }}
              value={record.data.Employee || []}
            />
          </Grid>
          <Grid item className={classes.field} xs={12}>
            <InputAutocomplete
              firebaseConfig={firebaseConfig}
              label='Working Union'
              firestoreOptions={{
                method: 'get',
                collection: 'ENT-Unions',
                valueKey: 'prtunmid',
                orderBy: 'UnionNumber'
              }}
              name='Union'
              onChange={onChange}
              optionDisplay={[
                'option_UnionNumber',
                ' - ',
                'option_UnionDescription'
              ]}
              value={record.data.Union || []}
            />
          </Grid>
          <Grid item className={classes.field} xs={12}>
            <InputSelect
              label='Automatic Per Diem'
              name='DefaultPerDiem'
              onChange={onChange}
              options={perDiemOptions}
              value={record.data.DefaultPerDiem || ''}
            />
          </Grid>
          <Grid item className={classes.field} xs={12}>
            <Alert
              severity='info'
              style={{ width: '100%', whiteSpace: 'pre-wrap' }}
            >
              The per diem value to be automatically populated in any time
              record. Leave blank if this crew member does not use per diem or
              needs to manually change the value often.
            </Alert>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

CrewListSelection.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired
};

QuickSearchToolbar.propTypes = {};

export default CrewListSelection;
