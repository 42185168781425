/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { basicStyles } from '../../theme';

const useStyles = makeStyles(
  {
    root: {
      borderRadius: '8px',
      margin: '0',
      outline: 'none',
      position: 'absolute',
      textAlign: 'center',
      textDecoration: 'none',
      width: '84px',

      '&:hover': {
        backgroundColor: 'lightgray',
        border: 'none',
        borderRadius: '8px',
        margin: '0',
        zIndex: '1',
        '& .ninedot-text': {
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          textDecoration: 'none'
        }
      }
    },
    nineDot: {
      color: '#202124',
      display: 'inline-block',
      fontSize: '14px',
      letterSpacing: '0.09px',
      lineHeight: '18px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '76px'
    }
  },
  { index: 1 }
);

const ApplicationsNavItem = ({ href, icon: Icon, title }) => {
  const customClasses = useStyles();
  const classes = basicStyles();

  const navigateAway = (h) => {
    toast.info('Will navigate to the specified app...');
    return;
    // eslint-disable-next-line no-unreachable
    window.open(h);
  };

  return (
    <div
      style={{
        display: 'inline-block',
        height: '84px',
        boxSizing: 'content-box',
        listStyleType: 'none',
        padding: '6px',
        position: 'relative',
        transition: 'transform .2s cubic-bezier(.333,0,0,1)',
        verticalAlign: 'top',
        width: '84px'
      }}
    >
      <div
        className={customClasses.root}
        onClick={() => {
          navigateAway(href);
        }}
      >
        <div
          style={{
            visibility: 'hidden',
            borderLeft: '5px solid transparent',
            borderRight: '5px solid #4273db',
            borderTop: '5px solid transparent',
            left: '36px',
            top: '0',
            position: 'absolute',
            height: '0',
            width: '0'
          }}
        />
        <div
          style={{
            height: '62px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {Icon && (
            <FontAwesomeIcon className={`${classes.icon} fa-fw`} icon={Icon} />
          )}
        </div>
        <span className={`${customClasses.nineDot} ninedot-text`}>{title}</span>
      </div>
    </div>
  );
};

ApplicationsNavItem.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string.isRequired
};

export default ApplicationsNavItem;
