import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Grid, Card, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useRecoilValue } from 'recoil';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import {
  _CurrentDate,
  _CurrentJobNumber,
  _CurrentForeman
} from '../../_Recoil/atoms';
import { firestore } from '../../firebase';
import { UserContext } from '../../providers/UserProvider';

const DailyPODDashboard = () => {
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);
  const CurrentDate = useRecoilValue(_CurrentDate);
  const CurrentForeman = useRecoilValue(_CurrentForeman);
  const [pod, setPOD] = useState([]);
  const { user } = useContext(UserContext);
  if (CurrentJobNumber.value.length === 0 || CurrentDate.value === '') {
    return 'Need data to get data';
  }
  useEffect(() => {
    let mounted = true;

    if (user.admin || user.manager) {
      if (CurrentJobNumber.value.length > 0 && CurrentDate.value !== '') {
        const q = query(
          collection(firestore, 'Tasks'),
          where('data.JobNumber', '==', CurrentJobNumber.value[0]),
          where('data.Date', '==', CurrentDate.value),
          orderBy('data.Foreman')
        );
        getDocs(q).then((querySnapshot) => {
          const records = [];
          querySnapshot.forEach((d) => {
            records.push(d.data());
          });
          if (mounted) setPOD(records);
        });
      } else {
        toast.info('Please select a Job and Date');
      }
    } else if (user.user) {
      if (
        CurrentJobNumber.value.length > 0 &&
        CurrentForeman.value.length > 0 &&
        CurrentDate.value !== ''
      ) {
        const q = query(
          collection(firestore, 'Tasks'),
          where('data.JobNumber', '==', CurrentJobNumber.value[0]),
          where('data.Foreman', '==', CurrentForeman.value[0]),
          where('data.Date', '==', CurrentDate.value)
        );

        getDocs(q).then((querySnapshot) => {
          const records = [];
          querySnapshot.forEach((d) => {
            records.push(d.data());
          });
          if (mounted) setPOD(records);
        });
      } else {
        toast.info(
          'Please Select a Job, Date, and Foreman to view Daily P.O.D.s.'
        );
      }
    }
    return () => (mounted = false);
  }, [CurrentJobNumber.value, CurrentDate.value]);
  const customClasses = makeStyles(
    {
      link: {
        color: '#3f51b5',
        '&:hover': {
          fontWeight: 'bold'
        }
      }
    },
    { index: 1 }
  )();
  const wbsTaskDescription = (r) => {
    if (
      typeof r.data.Level1Display === 'undefined' &&
      typeof r.data.Level2Display === 'undefined' &&
      typeof r.data.Level3Display === 'undefined' &&
      typeof r.data.Level4Display === 'undefined'
    )
      return 'No Task/WBS name.';
    const stack = [];
    if (typeof r.data.Level1Display !== 'undefined')
      stack.push(r.data.Level1Display);
    if (typeof r.data.Level2Display !== 'undefined')
      stack.push(r.data.Level2Display);
    if (typeof r.data.Level3Display !== 'undefined')
      stack.push(r.data.Level3Display);
    if (typeof r.data.Level4Display !== 'undefined')
      stack.push(r.data.Level4Display);
    return stack.join(' / ');
  };
  return (
    <Card raised>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            fontWeight: 'bold',
            fontSize: '1.25rem',
            padding: '10px 0'
          }}
        >
          Daily P.O.D.s for {CurrentDate.display}
        </Grid>
        {pod.length > 0 ? (
          <Grid item xs={12}>
            <Grid container>
              <Grid
                item
                xs={6}
                style={{
                  fontWeight: 'bold',
                  paddingBottom: '5px'
                }}
              >
                Foreman
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  fontWeight: 'bold',
                  paddingBottom: '5px'
                }}
              >
                POD/WBS
              </Grid>
              <Grid item xs={12}>
                <div>
                  {pod.map((r, idx) => (
                    <Grid
                      item
                      xs={12}
                      key={r.id}
                      style={{
                        backgroundColor: idx % 2 === 0 ? '#efefef' : '#fff',
                        padding: '5px 0'
                      }}
                    >
                      <Link to={`pod/${r.id}`} className={customClasses.link}>
                        <Grid container>
                          <Grid
                            item
                            xs={6}
                            style={{
                              textAlign: 'left',
                              paddingLeft: '5px'
                            }}
                          >
                            {r.data.ForemanDisplay ? (
                              r.data.ForemanDisplay
                            ) : (
                              <i>No Assigned Foreman</i>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            style={{
                              textAlign: 'center',
                              paddingLeft: '5px'
                            }}
                          >
                            {r.data.Task ? r.data.Task : wbsTaskDescription(r)}
                          </Grid>
                        </Grid>
                      </Link>
                    </Grid>
                  ))}
                </div>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item style={{ width: '100%', textAlign: '-webkit-center' }}>
            <Card
              style={{
                padding: '16px',
                margin: '0px 16px 13.25px 16px',
                width: 'fit-content'
              }}
            >
              <i>No tasks have been set up for this day.</i>
              <div style={{ margin: '8px 8px 0px 8px' }}>
                <Link to='/pod'>
                  <Button variant='contained' color='primary'>
                    Add tasks for {CurrentDate.display}
                  </Button>
                </Link>
              </div>
            </Card>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default DailyPODDashboard;
