import React, { useEffect, useContext } from 'react';
import { Grid } from '@mui/material';
import { useSetRecoilState } from 'recoil';
import TimeEntryForm from '../components/time/TimeEntryForm';
import { _HeaderTitle } from '../_Recoil/atoms';
import { basicStyles } from '../theme';
import ErrorBoundary from '../utils/ErrorBoundary';

import { UserContext } from '../providers/UserProvider';

const TimeEntry = () => {
  document.title = 'Time Entry Form';
  const { user } = useContext(UserContext);
  const setHeaderTitle = useSetRecoilState(_HeaderTitle);
  useEffect(() => setHeaderTitle('Time Entry'), []);
  const classes = basicStyles();
  return (
    <ErrorBoundary componentName='Time Entry' user={user}>
      <Grid item xs={12} className={classes.gridItem}>
        <Grid container className={classes.gridItem} justifyContent='center'>
          <div style={{ paddingBottom: '8px' }}>
            <h1>{document.title}</h1>
          </div>
        </Grid>
        <Grid container>
          <TimeEntryForm />
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
};

export default TimeEntry;
