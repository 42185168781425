import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { faTrashAlt, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { getAnalytics, logEvent } from 'firebase/analytics';
import {
  deleteDoc,
  doc,
  collection,
  query,
  where,
  onSnapshot,
  orderBy
} from 'firebase/firestore';
import { UserContext } from '../../providers/UserProvider';
import ConfirmDelete from '../dailyUsage/ConfirmDelete';
import { firestore } from '../../firebase';
import {
  _CurrentJobNumber,
  _CurrentDate,
  _CurrentForeman
} from '../../_Recoil/atoms';
import { icons, basicStyles } from '../../theme';
import StartDayCopyForm from './StartDayCopyForm';
import GenerateOTDTasksModal from './GenerateOTDTasksModal';
import existsWithLength from '../../utils/existsWithLength';

const NewJobList = (props) => {
  const { history } = props;
  const [newJobs, setNewJobs] = useState([]);
  const [displayBoxOpen, setDisplayBoxOpen] = useState(false);
  const [otdBoxOpen, setOtdBoxOpen] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useContext(UserContext);

  const displayChangeBox = (bool) => {
    setDisplayBoxOpen(bool);
  };
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);
  const CurrentForeman = useRecoilValue(_CurrentForeman);
  const CurrentDate = useRecoilValue(_CurrentDate);

  const handleSelect = (idx) => {
    history.push(`/pod/${idx}`);
  };

  const [deleteState, setDeleteState] = useState({
    proceedLabel: 'Delete',
    cancelLabel: 'Cancel',
    title: 'Delete Record',
    modalBody: 'Are you sure you want to remove this Record?',
    show: false,
    proceed: (approved, r) => {
      // eslint-disable-next-line no-use-before-define
      removeRecord(approved, r);
    },
    enableEscape: true
  });

  const addNew = () => {
    history.push(`/pod/new`);
  };

  const PreviousTask = () => {
    if (
      CurrentJobNumber.value.length !== 0 &&
      CurrentForeman.value.length !== 0
    ) {
      displayChangeBox(true);
    } else {
      toast.error(
        'You must have a Job and Foreman Selected to copy P.O.D.s...'
      );
    }
  };

  const GenerateOTDTasks = () => {
    setOtdBoxOpen(true);
  };

  const customClasses = makeStyles(
    () => ({
      button: {
        padding: '8px',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
          justifyContent: 'center'
        },
        marginLeft: '8px',
        marginBottom: '16px'
      },
      line: {
        padding: '8px'
      }
    }),
    { index: 1 }
  )();
  const classes = basicStyles();

  useEffect(() => {
    let mounted = true;
    if (CurrentDate.value === '') {
      toast.error('Date is Required');
      setNewJobs([]);
      return;
    }
    if (
      CurrentForeman.value.length === 0 &&
      CurrentJobNumber.value.length === 0
    ) {
      toast.error('Foreman or Job Number is Required');
      setNewJobs([]);
      return;
    }
    const colRef = (() => {
      let col = collection(firestore, 'Tasks');
      if (CurrentForeman.value.length > 0)
        col = query(col, where('data.Foreman', '==', CurrentForeman.value[0]));
      if (CurrentDate.value !== '')
        col = query(col, where('data.Date', '==', CurrentDate.value));
      if (CurrentJobNumber.value.length > 0)
        col = query(
          col,
          where('data.JobNumber', '==', CurrentJobNumber.value[0])
        );
      col = query(col, orderBy('CreatedDate'));
      return col;
    })();
    onSnapshot(colRef, (res) => {
      const allNewJobs = [];
      res.forEach((d) => allNewJobs.push(d.data()));
      if (mounted) setNewJobs(allNewJobs);
    });
    // eslint-disable-next-line consistent-return
    return () => (mounted = false);
  }, [CurrentForeman, CurrentDate, CurrentJobNumber]);

  const removeRecord = async (a, r) => {
    if (a) {
      if (r.idx !== '') {
        deleteDoc(doc(firestore, 'Tasks', r.idx));
        toast.success('P.O.D. Removed.');
      }
    }
    setDeleteState({ ...deleteState, show: false });
    const analytics = getAnalytics();
    logEvent(analytics, 'pod_deleted', { user: user.email, recordID: r.idx });
  };
  const handleDelete = (idx) => {
    setDeleteState({ ...deleteState, show: true, record: { idx } });
  };

  const getWBSDescription = (r) => {
    const display = [];
    if (r.data.TaskType === 'Task') {
      display.push(r.data.PICCDisplay);
      display.push(r.data.Task);
      return display.join(' / ');
    }
    if (r.data.TaskType === 'PreFab') {
      display.push(r.data.OrderNumberDisplay);
      if (r.data.Task !== '') display.push(r.data.Task);
    }
    if (r.data.TaskType === 'WBS') {
      console.log(r.data);
      if ((r.data.Level1Display || '').toString().trim() !== '') {
        display.push(r.data.Level1Display);
      }
      if ((r.data.Level2Display || '').toString().trim() !== '') {
        display.push(r.data.Level2Display);
      }
      if ((r.data.Level3Display || '').toString().trim() !== '') {
        display.push(r.data.Level3Display);
      }
      if ((r.data.Level4Display || '').toString().trim() !== '') {
        display.push(r.data.Level4Display);
      }
      if (r.data.Task !== '') {
        display.push(r.data.Task);
      }
    }
    return display.join(' / ');
  };
  const iconClasses = icons();
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: matches ? '8px' : '0px',
          marginTop: '8px'
        }}
      >
        <Button
          className={customClasses.button}
          variant='contained'
          color='primary'
          onClick={PreviousTask}
        >
          Copy P.O.D.s
        </Button>
        {(user.prefab || user.SuperAdmin) &&
          <Button
            className={customClasses.button}
            variant='contained'
            color='primary'
            onClick={GenerateOTDTasks}
          >
            Create OTD Tasks
          </Button>}
        {matches ? (
          <div
            className={`${iconClasses.container} ${iconClasses.addContainer}`}
            style={{ marginRight: '0' }}
          >
            <Button
              onClick={addNew}
              disableRipple
              className={iconClasses.buttonWrapper}
            >
              <FontAwesomeIcon
                icon={faPlus}
                className={iconClasses.icon}
                title='Add P.O.D.'
              />
            </Button>
          </div>
        ) : null}
      </div>
      <div style={{ padding: '5px' }} />
      <ConfirmDelete
        proceedLabel={deleteState.proceedLabel}
        cancelLabel={deleteState.cancelLabel}
        title={deleteState.title}
        modalBody={deleteState.modalBody}
        show={deleteState.show}
        proceed={deleteState.proceed}
        enableEscape={deleteState.enableEscape}
        record={deleteState.record}
      />
      <StartDayCopyForm
        displayBoxOpen={displayBoxOpen}
        closePopup={() => displayChangeBox(false)}
      />
      <GenerateOTDTasksModal open={otdBoxOpen} closePopup={() => { setOtdBoxOpen(false); }} job={CurrentJobNumber} foreman={CurrentForeman} user={user} date={CurrentDate.value} />
      <Grid container>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: 'white' }}>
                <TableCell className={classes.tableHeader}>Date</TableCell>
                <TableCell className={classes.tableHeader}>Foreman</TableCell>
                {CurrentJobNumber.value.length === 0 ? (
                  <TableCell className={classes.tableHeader}>
                    Job Number
                  </TableCell>
                ) : null}
                {matches ? (
                  <TableCell className={classes.tableHeader} colSpan={3}>
                    Task/WBS
                  </TableCell>
                ) : (
                  <TableCell
                    className={classes.tableHeader}
                    style={{ paddingRight: '0px' }}
                    colSpan={3}
                  >
                    <Grid container style={{ alignItems: 'center' }}>
                      <Grid item xs={2} align='left'>
                        Task/WBS
                      </Grid>
                      <Grid item xs={10} align='right'>
                        <div
                          className={`${iconClasses.container} ${iconClasses.addContainer}`}
                          style={{ margin: '8px 0px' }}
                        >
                          <Button
                            onClick={addNew}
                            disableRipple
                            className={iconClasses.buttonWrapper}
                          >
                            <FontAwesomeIcon
                              icon={faPlus}
                              className={iconClasses.icon}
                              title='Add P.O.D.'
                            />
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {newJobs.map((row) => (
                <TableRow key={row.id} className={classes.row1}>
                  <TableCell
                    className={classes.tableCell}
                    onClick={() => handleSelect(row.id)}
                  >
                    {row.data.Date}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    onClick={() => handleSelect(row.id)}
                  >
                    {row.data.ForemanDisplay}
                  </TableCell>
                  {CurrentJobNumber.value.length === 0 ? (
                    <TableCell
                      className={classes.tableCell}
                      onClick={() => handleSelect(row.id)}
                    >
                      {existsWithLength(row.data.GLCode) ? row.data.GLCode : row.data.JobNumberDisplay}
                    </TableCell>
                  ) : null}
                  <TableCell
                    className={classes.tableCell}
                    onClick={() => handleSelect(row.id)}
                  >
                    {getWBSDescription(row)}
                  </TableCell>
                  <TableCell className={classes.tableCell} align='right'>
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(row.id);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

export default NewJobList;

NewJobList.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired
};
NewJobList.defaultProps = {};
