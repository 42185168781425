import React, { useContext, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue } from 'recoil';

import { auth } from '../firebase';
import { useInitializeOffline } from '../_GlobalComponents/InitializeOffline';
import {
  _CurrentDate,
  _CurrentJobNumber,
  _InitedOffline
} from '../_Recoil/atoms';
import { UserContext } from './UserProvider';

export const OfflineDataContext = createContext({ user: null });
const OfflineDataProvider = (props) => {
  const { user } = useContext(UserContext);
  const { syncs, addSync, syncData, setInitialCount } = useInitializeOffline();
  const { children } = props;
  const [InitedOffline, setInitedOffline] = useRecoilState(_InitedOffline);
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);
  const CurrentDate = useRecoilValue(_CurrentDate);

  // useEffect(() => {
  //   let mounted = true;
  // if (mounted) setInitedOffline(false);
  //   return () => (mounted = false);
  // }, [CurrentJobNumber, CurrentDate]);

  useEffect(() => {
    let mounted = true;
    if (auth.currentUser) {
      if (!InitedOffline.value) {
        if (
          user &&
          CurrentJobNumber.value.length > 0 &&
          CurrentDate.value !== ''
        ) {
          const [year, month, date] = CurrentDate.value.split('-');
          let startDate = new Date(`${month}/${date}/${year}`);
          // eslint-disable-next-line prefer-destructuring
          startDate = new Date(startDate.setDate(startDate.getDate() - 7))
            .toJSON()
            .split('T')[0];
          let endDate = new Date(`${month}/${date}/${year}`);
          // eslint-disable-next-line prefer-destructuring
          endDate = new Date(endDate.setDate(endDate.getDate() + 7))
            .toJSON()
            .split('T')[0];
          setInitialCount(14);
          addSync(
            'Crew',
            {
              collection: 'Crew',
              where: [['data.JobNumber', '==', CurrentJobNumber.value[0]]]
            },
            'Crews'
          );
          addSync(
            'DailyReport',
            {
              collection: 'DailyReport',
              where: [
                ['data.JobNumber', '==', CurrentJobNumber.value[0]],
                ['data.Date', '>=', startDate],
                ['data.Date', '<=', endDate]
              ]
            },
            `Daily Reports Between ${startDate} and ${endDate}`
          );
          addSync(
            'ENT-Employees',
            {
              collection: 'ENT-Employees',
              where: [['Status', '==', 'A']]
            },
            `ECMS Employees`
          );
          addSync(
            'ENT-Equipment',
            {
              collection: 'ENT-Equipment'
            },
            `ECMS Equipment`
          );
          addSync(
            'ENT-Jobs',
            {
              collection: 'ENT-Jobs',
              where: [['JobStatus', '==', 'Open']]
            },
            `ECMS Jobs`
          );
          addSync(
            'ENT-Piccs',
            {
              collection: 'ENT-Piccs',
              where: [['jctdscid', '==', CurrentJobNumber.value[0]]]
            },
            `ECMS Piccs for ${CurrentJobNumber.display}`
          );
          addSync(
            'ENT-Subcontractors',
            {
              collection: 'ENT-Subcontractors',
              where: [['Status', '==', 'A']]
            },
            `ECMS Subcontractors`
          );
          addSync(
            'ENT-Unions',
            {
              collection: 'ENT-Unions'
            },
            `ECMS Unions`
          );
          addSync(
            'Obstacles',
            {
              collection: 'Obstacles'
            },
            `Obstacles`
          );
          addSync(
            'PICC',
            {
              collection: 'PICC',
              where: [['data.JobNumber', '==', CurrentJobNumber.value[0]]]
            },
            `PICCs`
          );
          addSync(
            'Subcontractor',
            {
              collection: 'Subcontractor',
              where: [['data.JobNumber', '==', CurrentJobNumber.value[0]]]
            },
            `Subcontractors`
          );
          addSync(
            'Equipment',
            {
              collection: 'Equipment',
              where: [['data.JobNumber', '==', CurrentJobNumber.value[0]]]
            },
            `Equipment`
          );
          addSync(
            'Tasks',
            {
              collection: 'Tasks',
              where: [
                ['data.JobNumber', '==', CurrentJobNumber.value[0]],
                ['data.Date', '>=', startDate],
                ['data.Date', '<=', endDate]
              ]
            },
            `P.O.D.s Between ${startDate} and ${endDate}`
          );
          addSync(
            'TimeEntries',
            {
              collection: 'TimeEntries',
              where: [
                ['data.JobNumber', '==', CurrentJobNumber.value[0]],
                ['data.Date', '>=', startDate],
                ['data.Date', '<=', endDate]
              ]
            },
            `Time Entries Between ${startDate} and ${endDate}`
          );
          if (mounted)
            setInitedOffline({ value: true, lastInited: new Date().toJSON() });
        } else {
          toast.info('Enter a Job and a Date to intialize offline data.', {
            toastId: 'FailedToDownload'
          });
        }
      }
    }
    return () => (mounted = false);
  }, [user, InitedOffline, CurrentJobNumber.value, CurrentDate.value]);

  return (
    <OfflineDataContext.Provider
      value={{ syncs, addSync, syncData, setInitialCount }}
    >
      {children}
    </OfflineDataContext.Provider>
  );
};

OfflineDataProvider.propTypes = {
  children: PropTypes.objectOf(PropTypes.any)
};
OfflineDataProvider.defaultProps = {
  children: []
};
export default OfflineDataProvider;
