import React from "react";
import PropTypes from 'prop-types';
import DailyOverview from "./DailyOverview";

const WeatherForecast = (props) => {
  const { forecast } = props
  const dotw = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
  const today = new Date();

  const returnDays = (date) => {
    if (date < 10)
      return `0${date}`;
    return date;
  }

  const getDays = () => {
    const days = []
    if (forecast.length > 0) {
      for (let i = 1; i < forecast.length - 1; i++) {
        today.setDate(today.getDate() + 1)
        days.push(
          <DailyOverview key={dotw[today.getDay()]} day={`${months[today.getMonth()]}/${returnDays(today.getDate())}`} dotw={dotw[today.getDay()]} temp={Math.round(forecast[i].temp.day)} description={forecast[i].weather[0].main} icon={forecast[i].weather[0].icon} />)
      }
    }
    return days;
  }
  return (
    getDays()
  )
}

WeatherForecast.propTypes = {
  forecast: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

WeatherForecast.defaultProps = {
  forecast: []
};

export default WeatherForecast;