import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import DailyToolbar from '../components/dailyUsage/DailyToolbar';
import EndDayJobList from '../components/pod/EndDayJobList';
import PODForm from '../components/pod/PODForm';
import { basicStyles } from '../theme';
import ErrorBoundary from '../utils/ErrorBoundary';

import { UserContext } from '../providers/UserProvider';

const EndDay = (props) => {
  const { match, history, pod, setPOD } = props;
  const { user } = useContext(UserContext);
  const classes = basicStyles();
  return (
    <ErrorBoundary componentName='End Day' user={user}>
      <Grid className={classes.gridItem}>
        {typeof match.params.id === 'undefined' ? (
          <>
            <Grid container>
              <DailyToolbar />
            </Grid>
            <EndDayJobList history={history} match={match} />
          </>
        ) : (
          <Grid container spacing={0} className={classes.gridItem}>
            <PODForm
              timeOfDay='EndDay'
              history={history}
              match={match}
              pod={pod}
              setPOD={setPOD}
            />
          </Grid>
        )}
      </Grid>
    </ErrorBoundary>
  );
};

EndDay.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any),
  pod: PropTypes.objectOf(PropTypes.any),
  setPOD: PropTypes.func
};
EndDay.defaultProps = {
  history: {
    push: () => { }
  },
  match: {},
  pod: {},
  setPOD: () => { }
};
export default EndDay;
