/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import {
  Grid,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Button,
  useTheme,
  Typography
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import makeStyles from '@mui/styles/makeStyles';

import { useSetRecoilState } from 'recoil';
import { collection, onSnapshot } from 'firebase/firestore';
import { firestore } from '../firebase';
import { _HeaderTitle } from '../_Recoil/atoms';
import {
  basicStyles,
  accordionClass,
  accordionDetailsClass,
  accordionSummaryClass,
} from '../theme';
import ErrorBoundary from '../utils/ErrorBoundary';
import { UserContext } from '../providers/UserProvider';

const Module = (m) => {
  const ac = accordionClass();
  const acdc = accordionDetailsClass();
  const acsc = accordionSummaryClass();
  const { title, link, transcript, showTranscript } = m;

  return (
    <Accordion classes={ac} key={title}>
      <AccordionSummary
        classes={{ root: acsc.root, expanded: acsc.expanded }}
        expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
      >
        <div>{title}</div>
      </AccordionSummary>
      <AccordionDetails classes={acdc}>
        <Grid container>
          <Grid item xs={12}>
            <iframe
              style={{ width: '100%' }}
              title={title}
              src={link}
              height='480'
              allow='autoplay'
            />
          </Grid>
          {typeof transcript !== 'undefined' && showTranscript ? (
            <Grid item xs={12} style={{ textAlign: 'left', paddingTop: '8px' }}>
              <h3>Video Transcription:</h3>
              <p style={{ whiteSpace: 'pre-wrap' }}>{transcript}</p>
            </Grid>
          ) : null}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const Training = () => {
  document.title = 'Training';
  const { user } = useContext(UserContext);
  const theme = useTheme();
  const customClasses = makeStyles(
    () => ({
      container: {
        display: 'grid',
        gridGap: theme.spacing(3)
      },
      label: { padding: '0px 4px' },
      editText: {
        textTransform: 'none',
        marginLeft: '5px'
      }
    }),
    { index: 1 }
  )();
  const setHeaderTitle = useSetRecoilState(_HeaderTitle);
  useEffect(() => setHeaderTitle('Training'), []);
  const classes = basicStyles();
  const [modules, setModules] = useState([]);

  useEffect(() => {
    onSnapshot(collection(firestore, 'TrainingModules'), (snapshot) => {
      const a = [];
      snapshot.forEach((doc) => {
        a.push(doc.data());
      });
      setModules(a);
    });
  }, []);

  return (
    <ErrorBoundary componentName='Training' user={user}>
      <Grid item xs={12} className={classes.gridItem}>
        <Grid
          container
          key='ugh'
          className={classes.gridItem}
          justifyContent='center'
        >
          <div style={{ paddingBottom: '8px' }}>
            <h1>{document.title}</h1>
          </div>
        </Grid>

        <Button
          disableRipple
          variant='contained'
          color='primary'
          style={{ height: '40px', padding: '6px 12px', marginBottom: '15px' }}
        >
          <div className={customClasses.editText}>
            <a
              href='https://drive.google.com/file/d/161ZOpr2PwpIjKixmjHoCBqOOpL7xHsRJ/view'
              target='_blank'
              style={{ color: 'white' }}
              rel='noreferrer'
            >
              Download PDF Guide
            </a>
          </div>
        </Button>

        <Grid container>
          <Grid item xs={12} style={{ paddingBottom: '16px' }}>
            <Typography variant="h5">
              Register for the monthly events to learn about DJR <a style={{ fontWeight: 'bold', textDecoration: 'underline' }} href="https://aldridge.csod.com/ui/lms-learning-details/app/event/2e81c52b-cb2e-4926-abac-20aceaedf981">here</a>.
            </Typography>
          </Grid>
          {modules.map((m) => (
            <Grid item xs={12} key={m.title} style={{ paddingBottom: '16px' }}>
              <Module title={m.title} link={m.src} transcript={m.transcript} showTranscript={m.showTranscript} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
};

export default Training;
