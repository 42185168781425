import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useSetRecoilState } from 'recoil';
import DailyToolbar from '../components/dailyUsage/DailyToolbar';
import { _HeaderTitle } from '../_Recoil/atoms';
import SubcontractorList from '../components/subcontractors/SubcontractorList';
import Subcontractor from '../components/subcontractors/Subcontractor';
import { basicStyles } from '../theme';
import ErrorBoundary from '../utils/ErrorBoundary';

import { UserContext } from '../providers/UserProvider';

const Crew = (props) => {
  document.title = 'Subcontractor';
  const { match, history } = props;
  const { user } = useContext(UserContext);
  const setHeaderTitle = useSetRecoilState(_HeaderTitle);
  useEffect(() => setHeaderTitle('Job Subcontractor'), []);
  const classes = basicStyles();
  return (
    <ErrorBoundary componentName='Subcontractor' user={user}>
      <Grid className={classes.gridItem}>
        <Grid item xs={12} className={classes.gridItem}>
          <div style={{ paddingBottom: '8px' }}>
            <h1>{document.title}</h1>
          </div>
        </Grid>
        <Grid item xs={12}>
          <DailyToolbar />
        </Grid>
        <Grid item xs={12}>
          {typeof match.params.id === 'undefined' ? (
            <SubcontractorList history={history} match={match} />
          ) : (
            <Subcontractor history={history} match={match} />
          )}
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
};

Crew.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any)
};
Crew.defaultProps = {
  match: {
    params: {}
  },
  history: {
    push: () => { }
  }
};
export default Crew;
