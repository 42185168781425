import React, { useContext, useState, useEffect } from 'react';
import {
  Button,
  Grid,
  Modal,
  useTheme,
  useMediaQuery,
  Alert
} from '@mui/material';
import { faPlus, faSave } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import Loader from 'react-loader-spinner';
import dayjs from 'dayjs';
import {
  deleteDoc,
  getDoc,
  getDocs,
  setDoc,
  onSnapshot,
  doc,
  collection,
  query,
  where
} from 'firebase/firestore';
import { NaNtoNum } from '@aldridge/aldg-helpers';
import { icons, basicStyles } from '../../theme';
import DailyToolbar from '../dailyUsage/DailyToolbar';
import {
  _CurrentDate,
  _CurrentForeman,
  _CurrentJobNumber,
  _Job,
  _Division
} from '../../_Recoil/atoms';
import { config, firestore } from '../../firebase';
import { weekEndDate, dayDifference } from '../../utils/dateFunctions';
import { UserContext } from '../../providers/UserProvider';
import TimeEntryRecord from '../TimeSummary/TimeEntryRecord';
import TimeEntryTable from './TimeEntryTable';
import existsWithLength from '../../utils/existsWithLength';

const TimeEntryForm = () => {
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);
  const CurrentJob = useRecoilValue(_Job);
  const CurrentDate = useRecoilValue(_CurrentDate);
  const CurrentForeman = useRecoilValue(_CurrentForeman);
  const CurrentDivision = useRecoilValue(_Division);
  const [displayBoxOpen, setDisplayBoxOpen] = useState(false);
  const [tasks, setTasks] = useState([]);
  const { user } = useContext(UserContext);
  const [saving, setSaving] = useState(false);
  const [getTime, setGetTime] = useState(false);
  const [record, setRecord] = useState({
    id: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    ModifiedDate: '',
    data: {
      JobNumber: CurrentJobNumber.value[0],
      Foreman: CurrentForeman.value[0],
      Date: CurrentDate.value,
      TimeEntries: []
    }
  });
  const checkLockout = (() => {
    // if (user && user.admin) return false;
    const today = dayjs().format('YYYY-MM-DD HH:mm');
    const day = dayjs(today).day();
    const hour = dayjs(today).hour();
    const minTimeDate =
      // !(day >= 2 && hour >= 0)
      !(day >= 2 && hour >= 13)
        ? dayjs(today)
            .subtract(7 + (day - 1), 'days')
            .format('YYYY-MM-DD')
        : dayjs(today)
            .subtract(day === 0 ? 6 : day - 1, 'days')
            .format('YYYY-MM-DD');
    const dateDiff = dayjs(CurrentDate.value).diff(minTimeDate);
    const dayDiff = Math.ceil(dateDiff / (1000 * 3600 * 24));

    if (dayDiff < 0) {
      if (!toast.isActive('timeLockout')) {
        toast.error('You can no longer edit time for this date.', {
          toastId: 'timeLockout'
        });
      }
      return true;
    }
    return false;
  })();

  const displayChangeBox = (bool) => {
    setDisplayBoxOpen(bool);
  };

  const listTransaction = {
    id: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    ModifiedDate: '',
    data: {
      Crew: '',
      TaskID: '',
      Person: '',
      JCTDSCID: CurrentJobNumber.value[0] || '',
      JobNumber: CurrentJob || '',
      JobDisplay: CurrentJobNumber.display || '',
      Division: CurrentDivision,
      Date: CurrentDate.value,
      Foreman: CurrentForeman.value[0],
      WeekEndDate: weekEndDate(CurrentDate.value),
      Picc: '',
      PiccDisplay: '',
      PRTUNMID: '',
      UnionDisplay: '',
      Shift: '1',
      Regular: '',
      Overtime: '',
      Doubletime: '',
      Vacation: '',
      Holiday: '',
      PerDiem: '',
      PreFab: user.prefab,
      SickLeaveType: '',
      SickLeave: '',
      ExemptFromCertifiedPayroll: '',
      NoWorkRecorded: '',
      LessThan8HoursVerified: '',
      ArrowOpen: false
    }
  };
  const classes = basicStyles();
  const iconClasses = icons();
  const GatherTime = () => {
    if (checkLockout) {
      toast.error(
        'Payroll may have processed. Any time prepped now may not be processed in payroll.'
      );
    }
    if (
      // CurrentJobNumber.value.length > 0 &&
      CurrentDate.value !== '' &&
      CurrentForeman.value.length > 0
    ) {
      if (record.data.TimeEntries.length > 0) {
        toast.info(
          'Only new Tasks will be prepped for Time Entries. If you would like to prepare all time again, please delete all time records.',
          { toastId: 'NewTasksOnly' }
        );
      }
      setGetTime(true);
      let q = query(
        collection(firestore, 'Tasks'),
        where('data.Date', '==', CurrentDate.value),
        where('data.Foreman', '==', CurrentForeman.value[0])
        // where('data.JobNumber', '==', CurrentJobNumber.value[0])
      );
      if (CurrentJobNumber.value.length > 0) {
        q = query(q, where('data.JobNumber', '==', CurrentJobNumber.value[0]));
      }
      getDocs(q)
        .then((snap) => {
          const t = [];
          if (!snap.empty) {
            snap.forEach((d) => {
              const r = d.data();
              t.push(r);
            });
            setTasks(t);
          } else {
            setGetTime(false);
            toast.info(
              `There are no tasks to import time for on ${CurrentDate.display}.`,
              { toastId: 'NoTasks' }
            );
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } else {
      toast.error('Foreman and Date are required to import time.', {
        toastId: 'NotProperTaskInfo'
      });
    }
  };

  const basicTimeEntry = (r, employee, task) => {
    r.data.TimeEntryPoint = 'PrepTime';
    r.data.TaskID = task.id;
    r.data.Regular = NaNtoNum(employee.Regular);
    r.data.Overtime = NaNtoNum(employee.Overtime);
    r.data.Doubletime = NaNtoNum(employee.Doubletime);
    r.data.Crew = employee.CrewId;
    r.data.Employee = employee.Employee;
    r.data.EmployeeDisplay = employee.EmployeeDisplay;
    r.data.Shift = employee.Shift;
    r.data.Union = employee.Union;
    r.data.UnionDisplay = employee.UnionDisplay;
    return r;
  };

  useEffect(() => {
    let mounted = true;
    if (
      // CurrentJobNumber.value.length === 0 ||
      CurrentDate.value === '' ||
      CurrentForeman.value.length === 0
    ) {
      toast.error('Date, and Foreman must be selected to pull time.', {
        toastId: 'NotProperTaskInfoLoad'
      });
      return () => {
        mounted = false;
      };
    }
    let q = query(
      collection(firestore, 'TimeEntries'),
      where('data.Foreman', '==', CurrentForeman.value[0]),
      where('data.Date', '==', CurrentDate.value)
    );
    if (!user.admin && !user.manager) {
      q = query(q, where('CreatedBy', '==', user.email));
    }
    if (CurrentJobNumber.value.length > 0) {
      q = query(q, where('data.JCTDSCID', '==', CurrentJobNumber.value[0]));
    }
    onSnapshot(q, (snap) => {
      if (!snap.empty) {
        const timeEntries = [];
        snap.forEach((d) => {
          timeEntries.push(d.data());
        });
        const cRecord = { ...record };
        cRecord.data.TimeEntries = timeEntries.sort((a, b) => {
          const nameA = a.data.EmployeeDisplay.toUpperCase(); // ignore upper and lowercase
          const nameB = b.data.EmployeeDisplay.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
        if (mounted) setRecord(cRecord);
      } else {
        const cRecord = { ...record };
        cRecord.data.TimeEntries = [];
        if (mounted) setRecord(cRecord);
      }
    });

    return () => (mounted = false);
  }, [CurrentDate.value]);

  useEffect(() => {
    let mounted = true;
    const getData = async () => {
      const timeEntries = [];
      const currentTimeEntries = record.data.TimeEntries.map(
        (a) => a.data.TaskID
      );
      const LevelValues = (c, rec, level) => {
        if (
          rec.data.TaskType === 'WBS' &&
          typeof rec.data[`Level${level}`] !== 'undefined' &&
          rec.data[`Level${level}`] !== ''
        )
          c.push(rec.data[`Level${level}`]);
      };
      for (let t = 0; t < tasks.length; t++) {
        if (currentTimeEntries.indexOf(tasks[t].id) === -1) {
          const employees = tasks[t].data.TimeEntry || [];
          const validPicc =
            (typeof tasks[t].data.Level1 !== 'undefined' &&
              tasks[t].data.Level1 !== '') ||
            existsWithLength(tasks[t].data.PICC);
          const validEmployees = existsWithLength(employees);
          const validGL =
            tasks[t].data.TaskType === 'PreFab' &&
            existsWithLength(tasks[t].data.GLCode);
          if (validPicc && validEmployees) {
            const categories = [];
            LevelValues(categories, tasks[t], '1');
            LevelValues(categories, tasks[t], '2');
            LevelValues(categories, tasks[t], '3');
            LevelValues(categories, tasks[t], '4');

            // eslint-disable-next-line no-await-in-loop
            const picc = await (async () => {
              if (categories.length > 0) {
                let col = collection(firestore, 'PICC');
                col = query(
                  col,
                  where('data.JobNumber', '==', tasks[t].data.JobNumber)
                );
                categories.forEach((c, idx) => {
                  col = query(col, where(`data.Level${idx + 1}`, '==', c));
                });
                const data = await getDocs(col);
                if (!data.empty) {
                  return data.docs[0].data();
                }
              } else if (typeof tasks[t].data?.PICC[0] !== 'undefined') {
                const col = doc(firestore, 'ENT-Piccs', tasks[t].data?.PICC[0]);
                const data = await getDoc(col);
                if (data.exists()) {
                  const res = data.data();
                  return {
                    data: {
                      Picc: [res.jctmstid],
                      PiccDisplay: `${res.PICC} - ${res.Description}`
                    }
                  };
                }
              } else {
                toast.error(
                  'One or more of your tasks have "WBS" selected as the Task Type with none of the levels selected or you have selected "Task" as the Task Type with no PICC selected. Please correct the errors to continue prepping time.',
                  { autoClose: 15000 }
                );
              }
              return undefined;
            })();
            if (
              typeof picc === 'undefined' ||
              picc === '' ||
              picc.data.Picc.length === 0
            ) {
              toast.warn(
                `A task for ${tasks[t].data.EmployeesWorkingOnTaskDisplay} has an invalid PICC associated with it, and will not be prepped.`,
                { autoClose: 15000 }
              );
            } else {
              for (let e = 0; e < employees.length; e++) {
                const TimeEntryObj = typeof employees[e] === 'object';
                const r = basicTimeEntry(
                  JSON.parse(JSON.stringify(listTransaction)),
                  employees[e],
                  tasks[t]
                );
                if (TimeEntryObj) {
                  r.data.JCTDSCID =
                    CurrentJobNumber.value.length === 0
                      ? tasks[t].data.JobNumber
                      : CurrentJobNumber.value[0];
                  r.data.JobNumber =
                    CurrentJobNumber.value.length === 0
                      ? tasks[t].data.JobNumberDisplay.split('-')[0].substring(
                          0,
                          6
                        )
                      : CurrentJob;
                  r.data.JobNumberDisplay =
                    CurrentJobNumber.value.length === 0
                      ? tasks[t].data.JobNumberDisplay
                      : CurrentJobNumber.display;
                  r.data.Picc =
                    typeof picc === 'undefined'
                      ? tasks[t].data.PICC
                      : picc.data.Picc;
                  r.data.PiccDisplay =
                    typeof picc === 'undefined'
                      ? tasks[t].data.PICCDisplay
                      : picc.data.PiccDisplay;
                  r.data.PersonJobPiccDisplay = `${employees[e].EmployeeDisplay}\n${r.data.JobNumberDisplay}\n${r.data.PiccDisplay}`;
                  if (
                    typeof employees[e].DefaultPerDiem !== 'undefined' &&
                    employees[e].DefaultPerDiem !== ''
                  )
                    r.data.PerDiem = employees[e].DefaultPerDiem;
                  timeEntries.push(JSON.parse(JSON.stringify(r)));
                }
              }
            }
          } else if (validGL) {
            for (let e = 0; e < employees.length; e++) {
              const TimeEntryObj = typeof employees[e] === 'object';
              const r = basicTimeEntry(
                JSON.parse(JSON.stringify(listTransaction)),
                employees[e],
                tasks[t]
              );
              if (TimeEntryObj) {
                r.data.GLCode = tasks[t].data.GLCode;
                r.data.TimeCardType = 'GL';
                r.data.PersonJobPiccDisplay = `${employees[e].EmployeeDisplay}\n${r.data.GLCode}`;
                timeEntries.push(JSON.parse(JSON.stringify(r)));
              }
            }
          } else {
            const getWBSDescription = (r) => {
              const display = [];
              if ((r.data.Level1Display?.trim() || '') !== '') {
                display.push(r.data.Level1Display);
              }
              if ((r.data.Level2Display?.trim() || '') !== '') {
                display.push(r.data.Level2Display);
              }
              if ((r.data.Level3Display?.trim() || '') !== '') {
                display.push(r.data.Level3Display);
              }
              if ((r.data.Level4Display?.trim() || '') !== '') {
                display.push(r.data.Level4Display);
              }
              return display.join(' / ');
            };
            toast.warn(
              `Task "${
                getWBSDescription(tasks[t]) || tasks[t].data.Task
              }" does not have any completed hours, a WBS task, or a GL code associated with it. Cannot create a time record.`,
              { autoClose: 8000 }
            );
          }
        }
      }
      const cRecord = { ...record };
      cRecord.data.TimeEntries = cRecord.data.TimeEntries.concat(
        timeEntries
      ).sort((a, b) => {
        const nameA = a.data.EmployeeDisplay?.toUpperCase();
        const nameB = b.data.EmployeeDisplay?.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      if (mounted) {
        setGetTime(false);
        setRecord(cRecord);
      }
    };
    getData();

    return () => (mounted = false);
  }, [tasks]);
  const AddTime = () => {
    if (
      CurrentJobNumber.value.length !== 0 &&
      CurrentDate.value !== '' &&
      CurrentForeman.value.length !== 0
    ) {
      displayChangeBox(true);
    } else {
      toast.error(
        'You must have a Job, Date, and Foreman Selected to enter time...'
      );
    }
  };
  const onChange = (idx, event, name, displayFromTypeahead) => {
    try {
      const nameCanSplit = (n) => {
        if (n.split('.').length > 1) return [true, n.split('.')];
        return [false, n];
      };
      const [canSplit, id] =
        typeof name !== 'undefined'
          ? nameCanSplit(name)
          : nameCanSplit(event.target.name);
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const updatedRecord = { ...record };
      const changedRecord = updatedRecord.data.TimeEntries[idx];
      if (canSplit) {
        changedRecord.data[id[0]][id[1]] = value;
      } else {
        changedRecord.data[id] = value;
      }

      if (typeof displayFromTypeahead !== 'undefined') {
        if (canSplit) {
          changedRecord.data[id[0]][`${id[1]}Display`] = displayFromTypeahead;
        } else {
          changedRecord.data[`${id}Display`] = displayFromTypeahead;
        }
      }
      if (changedRecord.CreatedDate === '') {
        changedRecord.CreatedBy = user.email;
        changedRecord.CreatedDate = new Date().toJSON();
      }
      changedRecord.ModifiedBy = user.email;
      changedRecord.ModifiedDate = new Date().toJSON();
      delete updatedRecord.data.TimeEntries[idx].data.ArrowOpenDisplay;
      setRecord(updatedRecord);
    } catch (err) {
      toast.error(err.message);
    }
  };
  const saveAllRecords = async () => {
    const timeRecords = record.data.TimeEntries;
    setSaving(true);
    let docRef;
    for (let t = 0; t < timeRecords.length; t++) {
      const r = timeRecords[t];
      if (r.id === '') {
        r.CreatedDate = new Date().toJSON();
        r.CreatedBy = user.email;
        r.ModifiedDate = new Date().toJSON();
        r.ModifiedBy = user.email;
        docRef = doc(collection(firestore, 'TimeEntries'));
        r.id = docRef.id;
      } else {
        r.ModifiedDate = new Date().toJSON();
        r.ModifiedBy = user.email;
        docRef = doc(firestore, 'TimeEntries', r.id);
      }
      // eslint-disable-next-line no-await-in-loop
      await setDoc(docRef, r, { merge: true });
    }
    setSaving(false);
    toast.success('Time saved successfully.', { autoClose: 5000 });
    const numOfDaysAfterWeekEnd = dayDifference(weekEndDate(CurrentDate.value));
    if (numOfDaysAfterWeekEnd > 1) {
      toast.warn(
        'Payroll may have already run by now. If this is a normal Payroll week, please touch base with your payroll coordinator to make sure time has been submitted.',
        { autoClose: 10000, toastId: 'LatePayrollNotice' }
      );
    }
  };

  const removeTimeRecord = (rows, idx) => {
    rows.splice(idx, 1);
    const cRecord = { ...record };
    cRecord.data.TimeEntries = rows;
    setRecord(cRecord);
    toast.success('Time Record deleted successfully.');
  };

  const overrideDelete = (rows, idx) => {
    if (rows[idx].id !== '') {
      deleteDoc(doc(firestore, 'TimeEntries', rows[idx].id)).then(() => {
        removeTimeRecord(rows, idx);
      });
    } else {
      removeTimeRecord(rows, idx);
    }
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <DailyToolbar />
      <Grid container>
        <Modal open={displayBoxOpen} className={classes.modal}>
          <div className={classes.timePaper}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '1rem'
              }}
            >
              <h5 style={{ textDecoration: 'underline' }}>Add Time Record</h5>
            </div>

            <div
              style={{
                position: 'relative',
                flex: '1 1 auto',
                padding: '1rem'
              }}
            >
              <TimeEntryRecord
                closePopup={() => displayChangeBox(false)}
                checkLockout={checkLockout}
              />
            </div>
          </div>
        </Modal>
        <Grid container>
          <div
            style={{
              position: 'sticky',
              top: '-1px',
              left: 0,
              right: 0,
              width: '100%',
              height: '56px',
              backgroundColor: 'transparent',
              padding: '8px',
              margin: '8px 0px',
              display: 'flex',
              justifyContent: 'flex-end',
              zIndex: 100
            }}
          >
            {saving ? (
              <Loader type='TailSpin' height={56} />
            ) : (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: '-webkit-fill-available',
                    marginLeft: '-8px'
                  }}
                >
                  {!checkLockout && (
                    <Grid
                      hidden={
                        // CurrentJobNumber.value.length === 0 ||
                        CurrentDate.value === ''
                      }
                      style={{ margin: '0px', whiteSpace: 'nowrap' }}
                    >
                      {getTime ? (
                        <Loader type='TailSpin' height={56} />
                      ) : (
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={GatherTime}
                          style={{ height: '40px', padding: '6px 12px' }}
                        >
                          {matches
                            ? `Prep Time for ${dayjs(CurrentDate.value).format(
                                'MM/DD/YY'
                              )}`
                            : `Prep Time for ${dayjs(CurrentDate.value).format(
                                'ddd MM/DD/YY'
                              )}`}
                        </Button>
                      )}
                    </Grid>
                  )}
                </div>
                {!checkLockout && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      width: '-webkit-fill-available',
                      marginRight: '-19px'
                    }}
                  >
                    <div
                      className={`${iconClasses.container} ${iconClasses.saveContainer}`}
                      style={{ margin: matches ? '0px -4px' : '0px 12px' }}
                      title='Save All Records'
                    >
                      <Button
                        onClick={saveAllRecords}
                        disableRipple
                        className={iconClasses.buttonWrapper}
                      >
                        <FontAwesomeIcon
                          icon={faSave}
                          className={iconClasses.icon}
                        />
                      </Button>
                    </div>
                    <div
                      className={`${iconClasses.container} ${iconClasses.addContainer}`}
                      style={{
                        margin: matches
                          ? '0px 12px 0px 8px'
                          : '0px 12px 0px 12px'
                      }}
                      title='Add Time'
                    >
                      <Button
                        onClick={AddTime}
                        disableRipple
                        className={iconClasses.buttonWrapper}
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          className={iconClasses.icon}
                        />
                      </Button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div style={{ marginTop: matches ? '8px' : '0px', width: '100%' }}>
            {config.projectId.indexOf('-dev') > -1 ||
            config.projectId.indexOf('-training') > -1 ? (
              <div style={{ marginBottom: '12px' }}>
                <Alert severity='warning'>
                  You are currently in a development or training enviornment.
                  Any time entered here will NOT be submitted to payroll.
                </Alert>
              </div>
            ) : null}
            <TimeEntryTable
              data={record.data.TimeEntries || []}
              checkLockout={checkLockout}
              onChange={onChange}
              onDelete={overrideDelete}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default TimeEntryForm;
