import React, { useEffect, useState, createContext } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

export const SizeContext = createContext({ size: null });

const SizeProvider = (props) => {
  const { children } = props;
  const [size, setSize] = useState(null);
  const theme = useTheme();
  const xsMatches = useMediaQuery(theme.breakpoints.only('xs'));
  const smMatches = useMediaQuery(theme.breakpoints.only('sm'));
  const mdMatches = useMediaQuery(theme.breakpoints.only('md'));
  const lgMatches = useMediaQuery(theme.breakpoints.only('lg'));
  const xlMatches = useMediaQuery(theme.breakpoints.only('xl'));

  useEffect(() => {
    if (xsMatches)
      setSize('xs');
    if (smMatches)
      setSize('sm');
    if (mdMatches)
      setSize('md');
    if (lgMatches)
      setSize('lg');
    if (xlMatches)
      setSize('xl');
  }, [xsMatches, smMatches, mdMatches, lgMatches, xlMatches]);

  return (
    <SizeContext.Provider value={size}>
      {children}
    </SizeContext.Provider>
  );
};


SizeProvider.propTypes = {
  children: PropTypes.objectOf(PropTypes.any)
};
SizeProvider.defaultProps = {
  children: []
};
export default SizeProvider;
