import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  InputText,
  InputAutocomplete,
  InputDate,
  InputTypeahead,
  InputRadio,
  InputRadioGroup,
  InputCheckbox,
  InputCheckboxGroup,
  InputTextarea,
  InputSelect
} from '@aldridge/aldg-data-components';
import { Button, Grid } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { firebaseConfig } from '../../firebase';
import { icons } from '../../theme';
import AllWBSSearchBar from '../wbs/AllWBSSearchBar';
import existsWithLength from '../../utils/existsWithLength';

const TaskWBSInfo = (props) => {
  const {
    record,
    setRecord,
    onChange,
    customClasses,
    classes,
    CurrentForeman,
    piccs,
    timeOfDay,
    CurrentJobNumber,
    checkLockout,
    user
  } = props;
  const onForemanChange = (event, name, displayFromTypeahead) => {
    onChange(event[0], name, displayFromTypeahead.split(' [')[0]);
  };

  const onJobChange = (event, name, displayFromTypeahead) => {
    onChange(event[0] || '', name, displayFromTypeahead);
  };

  const distinctList = (obj, name) => {
    let options = [];
    switch (name) {
      case 'Level1':
        options = [
          ...new Set(
            obj.filter((o) => o.data.Level1 !== '').map((o) => o.data[name])
          )
        ];
        break;
      case 'Level2':
        options = [
          ...new Set(
            obj
              .filter(
                (o) =>
                  o.data.Level1 === record.data.Level1 && o.data.Level2 !== ''
              )
              .map((o) => o.data[name])
          )
        ];
        break;
      case 'Level3':
        options = [
          ...new Set(
            obj
              .filter(
                (o) =>
                  o.data.Level1 === record.data.Level1 &&
                  o.data.Level2 === record.data.Level2 &&
                  o.data.Level3 !== ''
              )
              .map((o) => o.data[name])
          )
        ];
        break;
      case 'Level4':
        options = [
          ...new Set(
            obj
              .filter(
                (o) =>
                  o.data.Level1 === record.data.Level1 &&
                  o.data.Level2 === record.data.Level2 &&
                  o.data.Level3 === record.data.Level3 &&
                  o.data.Level4 !== ''
              )
              .map((o) => o.data[name])
          )
        ];
        break;
      default:
        options = [];
    }
    return options.map((o) => ({ value: o, label: o }));
  };

  // code here
  useEffect(() => {
    if (existsWithLength(record.data.AllWBS)) {
      const filteredPicc = piccs.filter((currentValue) => {
        if (currentValue.id === record.data.AllWBS[0]) return true;
        return false;
      });
      if (existsWithLength(filteredPicc)) {
        const changedRecord = { ...record };
        changedRecord.data.Level1 = filteredPicc[0].data.Level1;
        changedRecord.data.Level1Display = filteredPicc[0].data.Level1;
        changedRecord.data.Level2 = filteredPicc[0].data.Level2;
        changedRecord.data.Level2Display = filteredPicc[0].data.Level2;
        changedRecord.data.Level3 = filteredPicc[0].data.Level3;
        changedRecord.data.Level3Display = filteredPicc[0].data.Level3;
        changedRecord.data.Level4 = filteredPicc[0].data.Level4;
        changedRecord.data.Level4Display = filteredPicc[0].data.Level4;

        setRecord(changedRecord);
      }
    }
  }, [record.data.AllWBS]);
  const iconClasses = icons();
  const linkToOTD = `https://aldg-otd.web.app/requests/${record.data.OrderNumber?.[0]}`;

  return (
    <Grid container style={{ margin: 0 }}>
      <Grid item xs={12} className={customClasses.header}>
        <h2>Task/WBS</h2>
      </Grid>
      <Grid item xs={12} md={4} className={classes.field}>
        <InputAutocomplete
          firestoreOptions={{
            collection: 'ENT-Employees',
            where: [
              ['Status', '==', 'A'],
              ['EmployeeType', 'in', ['Field', 'Fleet']]
            ],
            orderBy: ['LegalLastName', 'LegalFirstName'],
            valueKey: 'prtmstid',
            method: 'onSnapshot'
          }}
          label='Foreman'
          value={[record.data.Foreman] || []}
          onChange={onForemanChange}
          name='Foreman'
          optionDisplay={(opts) => {
            if (Object.keys(opts).length === 0) return '';
            return `${opts.LegalLastName} ${opts.Suffix || ''}, ${
              opts.PreferredFirstName
            } ${opts.MiddleName} - [${opts.Title} - ${
              opts.UniversalID
            }]`.replace(/ {2}/g, ' ');
          }}
          disabled={CurrentForeman.value.length > 0 || checkLockout}
        />
      </Grid>
      {record.data.IsGLTicket && record.data.GLCode !== '' ? (
        <>
          <Grid item xs={12} md={4} className={classes.field}>
            <InputText
              name='GLCode'
              label='GLCode'
              value={record.data.GLCode || ''}
              onChange={onChange}
              disablePortal
              disabled={checkLockout}
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} md={4} className={classes.field}>
            <InputAutocomplete
              firebaseConfig={firebaseConfig}
              name='JobNumber'
              label='Job Number'
              firestoreOptions={{
                collection: 'ENT-Jobs',
                where: [['JobStatus', '==', 'Open']],
                orderBy: 'JobNumber',
                valueKey: 'jctdscid',
                method: 'onSnapshot'
              }}
              value={[record.data.JobNumber] || []}
              onChange={onJobChange}
              optionDisplay={(opts) => {
                if (typeof opts.JobNumber === 'undefined') return '';
                return `${opts.JobNumber}.${opts.SubJobNumber} - ${opts.JobDescription}`;
              }}
              disablePortal
              disabled={
                (CurrentJobNumber.value.length > 0 &&
                  record.data.TaskType !== 'PreFab') ||
                checkLockout
              }
            />
          </Grid>
        </>
      )}

      <Grid item xs={12} md={4} className={classes.field}>
        <InputDate
          type='date'
          label='P.O.D. Date'
          onChange={onChange}
          name='Date'
          value={record.data.Date || ''}
          disabled={
            timeOfDay === 'EndDay' ||
            (typeof record.data.id !== 'undefined' &&
              record.data.id !== '' &&
              checkLockout)
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Grid
          item
          md={3}
          xs={12}
          className={classes.field}
          style={{ marginBottom: '0px' }}
        >
          <InputRadioGroup
            label='Task Type'
            name='TaskType'
            onChange={onChange}
            value={record.data.TaskType || ''}
            disabled={checkLockout}
          >
            {!(user.prefab && !user.admin) ? (
              <InputRadio
                label='WBS'
                value='WBS'
                className={classes.field}
                hidden={user.prefab}
              />
            ) : (
              <></>
            )}
            <InputRadio
              label='Task'
              value='Task'
              className={classes.field}
              hidden={user.prefab}
            />
            {user.prefab || user.SuperAdmin || user.admin ? (
              <InputRadio
                label='PreFab'
                value='PreFab'
                className={classes.field}
              />
            ) : (
              <></>
            )}
          </InputRadioGroup>
        </Grid>
      </Grid>
      {distinctList(piccs, 'Level1').length === 0 &&
        record.data.TaskType === 'WBS' && (
          <Grid item xs={12}>
            <h3>
              {' '}
              {typeof record.data.JobNumber === 'undefined' ||
              record.data.JobNumber?.length === 0
                ? 'Please select a Job Number to see WBS tasks.'
                : 'There are no WBS tasks set up. Please set up your WBS tasks in the WBS Tab.'}
            </h3>
          </Grid>
        )}
      <Grid
        item
        xs={12}
        className={
          distinctList(piccs, 'Level1').length === 0 ||
          ['Task', 'PreFab'].indexOf(record.data.TaskType) > -1
            ? customClasses.hidden
            : classes.field
        }
      >
        <AllWBSSearchBar
          JobNumber={record.data.JobNumber}
          value={record.data.AllWBS}
          onChange={onChange}
          completeStatus='Both'
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        className={
          distinctList(piccs, 'Level1').length === 0 ||
          record.data.TaskType === 'Task'
            ? customClasses.hidden
            : classes.field
        }
      >
        <InputTypeahead
          name='Level1'
          onChange={onChange}
          value={record.data.Level1 || ''}
          label='Level 1'
          options={distinctList(piccs, 'Level1')}
          requirement={[record.data.JobNumber] || []}
          requirementText='Please Select a Job Number...'
          hidden={
            distinctList(piccs, 'Level1').length === 0 ||
            record.data.TaskType === 'Task' ||
            record.data.TaskType === 'PreFab'
          }
          disabled={checkLockout}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        className={
          distinctList(piccs, 'Level2').length === 0 ||
          record.data.TaskType === 'Task'
            ? customClasses.hidden
            : classes.field
        }
      >
        <InputTypeahead
          name='Level2'
          onChange={onChange}
          value={record.data.Level2}
          label='Level 2'
          options={distinctList(piccs, 'Level2')}
          hidden={
            distinctList(piccs, 'Level2').length === 0 ||
            record.data.TaskType === 'Task'
          }
          disabled={checkLockout}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        className={
          distinctList(piccs, 'Level3').length === 0 ||
          record.data.TaskType === 'Task'
            ? customClasses.hidden
            : classes.field
        }
      >
        <InputTypeahead
          name='Level3'
          onChange={onChange}
          value={record.data.Level3 || ''}
          label='Level 3'
          options={distinctList(piccs, 'Level3')}
          hidden={
            distinctList(piccs, 'Level3').length === 0 ||
            record.data.TaskType === 'Task'
          }
          disabled={checkLockout}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        className={
          distinctList(piccs, 'Level4').length === 0 ||
          record.data.TaskType === 'Task'
            ? customClasses.hidden
            : classes.field
        }
      >
        <InputTypeahead
          name='Level4'
          onChange={onChange}
          value={record.data.Level4}
          label='Level 4'
          options={distinctList(piccs, 'Level4')}
          hidden={
            distinctList(piccs, 'Level4').length === 0 ||
            record.data.TaskType === 'Task'
          }
          disabled={checkLockout}
        />
      </Grid>
      <Grid item xs={12} />
      {record.data.TaskType === 'PreFab' && (
        <Grid item xs={6} className={classes.field}>
          <InputCheckboxGroup
            name='HideCompleted'
            onChange={onChange}
            required={false}
            value={record.data.HideCompleted || ''}
          >
            <InputCheckbox label='Hide Completed Tickets' value='true' />
          </InputCheckboxGroup>
        </Grid>
      )}
      <Grid
        item
        xs={8}
        hidden={record.data.TaskType !== 'PreFab'}
        className={
          record.data.TaskType === 'PreFab'
            ? classes.field
            : customClasses.hidden
        }
      >
        {' '}
        {/* order autocomplete */}
        <InputAutocomplete
          callType='function'
          cloudFunction={{
            name: 'otdOrders',
            config: firebaseConfig,
            variables: {
              job: record.data.JobNumber,
              hideCompleted: record.data.HideCompleted || ''
            },
            valueKey: 'id'
          }}
          label='Order'
          value={record.data.OrderNumber || []}
          onChange={onChange}
          name='OrderNumber'
          optionDisplay={(opts) => {
            if (Object.keys(opts).length === 0) return '';
            return `${opts.data.OrderNumber} - ${opts.data.BriefDescription} [${opts.data.Status}]`;
          }}
          hidden={record.data.TaskType !== 'PreFab'}
        />
        {record.data.OrderNumber?.length > 0 && (
          <div
            className={`${iconClasses.container} ${iconClasses.blueContainer}`}
            style={{ margin: '0px 0px 0px 12px' }}
            title='Link to OTD Record'
          >
            <a href={linkToOTD} target='_blank' rel='noreferrer'>
              <Button disableRipple className={iconClasses.buttonWrapper}>
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  className={iconClasses.icon}
                />
              </Button>
            </a>
          </div>
        )}
      </Grid>
      <Grid item xs={4} md={4} className={classes.field}>
        <InputDate
          type='date'
          label='Need by Date'
          onChange={onChange}
          hidden={record.data.TaskType !== 'PreFab'}
          name='NeedbyDate'
          value={record.data.NeedByDate || ''}
        />
      </Grid>
      <Grid item xs={12} className={classes.field}>
        <InputTextarea
          name='Task'
          label='Task Details'
          onChange={onChange}
          value={record.data.Task || ''}
          disabled={timeOfDay === 'EndDay' || checkLockout}
          hidden={record.data.TaskType === 'PreFab'}
        />
      </Grid>
      {record.data.TaskType === 'PreFab' && (
        <>
          <Grid item xs={3} className={classes.field}>
            <InputSelect
              name='TypeOfWork'
              label='Type Of Work'
              onChange={onChange}
              value={record.data.TypeOfWork || ''}
              options={[
                {
                  label: 'Welding/ Fabrication/ Jigs',
                  value: 'Welding/ Fabrication/ Jigs'
                },
                {
                  label: 'Underground/ Ductbank',
                  value: 'Underground/ Ductbank'
                },
                { label: 'Cable/ Wire Prep', value: 'Cable/ Wire Prep' },
                { label: 'Building Lighting', value: 'Building Lighting' },
                { label: 'Poles/ Luminaries', value: 'Poles/ Luminaries' },
                {
                  label: 'Conduit bending/ Nipples/ Racks',
                  value: 'Conduit bending/ Nipples/ Racks'
                },
                { label: 'JBox Prep', value: 'JBox Prep' },
                {
                  label: 'Device/ Equipment Prep',
                  value: 'Device/ Equipment Prep'
                },
                { label: 'Material Kitting', value: 'Material Kitting' },
                { label: 'Concrete Precast', value: 'Concrete Precast' },
                { label: 'CNC', value: 'CNC' },
                { label: 'Equipment Racks', value: 'Equipment Racks' },
                { label: 'Carpenter', value: 'Carpenter' },
                { label: 'R&D', value: 'R&D' },
                { label: 'Rework', value: 'Rework' },
                { label: 'Other', value: 'Other' }
              ]}
              disabled={timeOfDay === 'EndDay' || checkLockout}
            />
          </Grid>
          <Grid item xs={9} className={classes.field}>
            <InputTextarea
              name='ScopeOfWork'
              label='Scope of Work'
              onChange={onChange}
              value={record.data.ScopeOfWork || ''}
              disabled
            />
          </Grid>
        </>
      )}
      {record.data.IsGLTicket === 'true' && (
        <Grid item xs={12} className={classes.field}>
          <InputText
            name='GLCode'
            label={record.data.GLCode || ''}
            onChange={onChange}
            value={record.data.GLCode || ''}
            // disabled={timeOfDay === 'EndDay' || checkLockout}
          />
        </Grid>
      )}

      {(record.data.GLCode === '' ||
        typeof record.data.GLCode === 'undefined') && (
        <>
          <Grid
            item
            xs={12}
            className={
              record.data.TaskType === 'WBS'
                ? customClasses.hidden
                : classes.field
            }
          >
            <InputAutocomplete
              firebaseConfig={firebaseConfig}
              name='PICC'
              label='PICC'
              value={record.data.PICC || ''}
              firestoreOptions={{
                valueKey: 'jctmstid',
                collection: 'ENT-Piccs',
                orderBy: ['Status', 'PICC'],
                where: [
                  ['jctdscid', '==', record.data.JobNumber],
                  ['Status', '!=', 'Invalid']
                ],
                method: 'onSnapshot'
              }}
              optionDisplay={['option_PICC', 'option_Description']}
              onChange={onChange}
              requirement={{
                rules: record.data.JobNumber || '',
                text: 'Please Select a Job Number...'
              }}
              hidden={record.data.TaskType === 'WBS'}
              disabled={checkLockout}
            />
          </Grid>
        </>
      )}
      <Grid
        item
        xs={12}
        md={12}
        className={
          record.data.TaskType === 'WBS' ? customClasses.hidden : classes.field
        }
      >
        <InputText
          name='ForceNumber'
          onChange={onChange}
          value={record.data.ForceNumber || ''}
          label='FA/FO #'
          hidden={
            record.data.TaskType === 'WBS' ||
            (record.data.PICCDisplay?.toLowerCase()?.indexOf(
              'Force Account Work'.toLowerCase()
            ) || -1) === -1
          }
          disabled={timeOfDay === 'EndDay' || checkLockout}
        />
      </Grid>
      <Grid item xs={12} className={classes.field}>
        <InputTextarea
          name='Task'
          label='Additional Work Details (Task)'
          onChange={onChange}
          value={record.data.Task || ''}
          disabled={timeOfDay === 'EndDay' || checkLockout}
          hidden={
            record.data.TaskType === 'WBS' || record.data.TaskType === 'Task'
          }
        />
      </Grid>
    </Grid>
  );
};

TaskWBSInfo.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  setRecord: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  customClasses: PropTypes.objectOf(PropTypes.any).isRequired,
  CurrentForeman: PropTypes.objectOf(PropTypes.any).isRequired,
  CurrentJobNumber: PropTypes.objectOf(PropTypes.any).isRequired,
  piccs: PropTypes.arrayOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  timeOfDay: PropTypes.string.isRequired,
  checkLockout: PropTypes.bool,
  user: PropTypes.objectOf(PropTypes.any).isRequired
};
TaskWBSInfo.defaultProps = {
  checkLockout: false
};

export default TaskWBSInfo;
