import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, useTheme, Grid, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import {
  deleteDoc,
  onSnapshot,
  query,
  doc,
  collection,
  where
} from 'firebase/firestore';
import { AldgDataGrid } from '@aldridge/aldg-data-components';
import { _CurrentJobNumber } from '../../_Recoil/atoms';
import { firestore } from '../../firebase';
import QuickSearchToolbar from '../../_GlobalComponents/QuickFilterToolbar';
import { icons } from '../../theme';

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const gridStyles = makeStyles(
  () => ({
    root: {
      '& .MuiDataGrid-footerContainer': {
        justifyContent: 'flex-start',
        marginLeft: '-12px'
      },
      '& .MuiDataGrid-columnsContainer': {
        backgroundColor: '#fff'
      },
      '& .MuiDataGrid-row:nth-child(odd)': {
        backgroundColor: '#efefef'
      },
      '& .MuiDataGrid-row:nth-child(even)': {
        backgroundColor: '#fff'
      }
    }
  }),
  { index: 1 }
);

const SubcontractorList = (props) => {
  const { history } = props;
  const [subcontractor, setSubcontractor] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState(subcontractor);
  const [selection, setSelection] = useState([]);
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);
  const GridApiRef = useGridApiRef();
  const gridClasses = gridStyles();

  useEffect(() => {
    let mounted = true;
    if (CurrentJobNumber.value.length === 0) {
      setSubcontractor([]);
      return;
    }
    const q = query(
      collection(firestore, 'Subcontractor'),
      where('data.JobNumber', '==', CurrentJobNumber.value[0])
    );
    onSnapshot(q, (res) => {
      const allSubcontractors = [];
      res.forEach((d) => allSubcontractors.push(d.data()));
      if (mounted) setSubcontractor(allSubcontractors);
    });
    // eslint-disable-next-line consistent-return
    return () => (mounted = false);
  }, [CurrentJobNumber]);

  useEffect(() => {
    let mounted = true;
    if (mounted) setRows(subcontractor);
    return () => (mounted = false);
  }, [subcontractor]);

  const getFlex = (f) => (matches ? null : f);
  const getWidth = (w) => (matches ? w : null);

  const handleSelect = (idx) => {
    history.push(`/Subcontractor/${idx}`);
  };
  const addNew = () => {
    if (CurrentJobNumber.value.length > 0) history.push(`/Subcontractor/new`);
    else toast.error('You must select a Job before adding a Subcontractor...');
  };
  const deleteRecords = () => {
    try {
      selection.forEach((s) => {
        deleteDoc(doc(firestore, 'Subcontractor', s));
      });
      setSelection([]);
      toast.success('Record(s) deleted successfully.');
    } catch (error) {
      toast.error(
        'An error occured and the selected records were not deleted.'
      );
    }
  };
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = subcontractor.filter((row) =>
      Object.keys(row.data).some((field) =>
        searchRegex.test(row.data[field].toString())
      )
    );
    setRows(filteredRows);
  };

  const iconClasses = icons();
  const columns = [
    {
      field: 'EquipmentNumber',
      headerName: 'Subcontractor',
      valueGetter: (params) => params.row.data.Name,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'Make',
      headerName: 'Contract Number',
      valueGetter: (params) => params.row.data.ContractNumber,
      flex: getFlex(15),
      width: getWidth(300)
    }
  ];
  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              padding: matches ? '20px 0' : '10px 0',
              justifyContent: 'flex-end',
              flexDirection: 'row'
            }}
          >
            <div
              className={`${iconClasses.container} ${iconClasses.redContainer}`}
            >
              <Button
                onClick={deleteRecords}
                disableRipple
                className={iconClasses.buttonWrapper}
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  className={iconClasses.icon}
                  title='Delete Selected Records'
                />
              </Button>
            </div>
            <div
              className={`${iconClasses.container} ${iconClasses.addContainer}`}
              style={{ marginRight: '0' }}
            >
              <Button
                onClick={addNew}
                disableRipple
                className={iconClasses.buttonWrapper}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  className={iconClasses.icon}
                  title='Add New Subcontractor'
                />
              </Button>
            </div>
          </div>
          <AldgDataGrid
            autoHeight
            exposeCheckbox
            selectionModel={selection}
            accessSelections={setSelection}
            components={{ Toolbar: QuickSearchToolbar }}
            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (event) => requestSearch(event.target.value),
                clearSearch: () => requestSearch('')
              }
            }}
            onRowClick={(row) => handleSelect(row.id)}
            className={gridClasses.root}
            rows={rows}
            columns={columns}
            apiRefOverride={GridApiRef}
          />
        </Grid>
      </Grid>
    </div>
  );
};

SubcontractorList.propTypes = {
  history: PropTypes.objectOf(PropTypes.any)
};

SubcontractorList.defaultProps = {
  history: {
    push: () => { }
  }
};

export default SubcontractorList;
