import React from "react";
import PropTypes from 'prop-types';
import { Grid } from "@mui/material";
import Logo from '../Logo/LogoColor';
import { pdfStyles } from '../../theme';

const Header = (props) => {
  const { recoilJob, jobDetails, task } = props;

  const pdfClasses = pdfStyles();

  return (
    <>
      <Grid item xs={2}>
        <Logo />
      </Grid>
      <Grid item xs={10}>
        <div className={pdfClasses.main}>
          Daily Job Report {recoilJob.display}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={pdfClasses.titleBox}>Project Details</div>
      </Grid>

      <Grid item xs={3}>
        <div className={pdfClasses.subTitleBox}>Project</div>
      </Grid>
      <Grid item xs={3}>
        <div className={pdfClasses.fillable}>{recoilJob.display}</div>
      </Grid>
      <Grid item xs={3}>
        <div className={pdfClasses.subTitleBox}>Project Manager</div>
      </Grid>
      <Grid item xs={3}>
        <div className={pdfClasses.fillable}>{jobDetails.ProjectManager}</div>
      </Grid>
      <Grid item xs={3}>
        <div className={pdfClasses.subTitleBox}>Foreman</div>
      </Grid>
      <Grid item xs={3}>
        <div className={pdfClasses.fillable}>{task.Foreman}</div>
      </Grid>
      <Grid item xs={3}>
        <div className={pdfClasses.subTitleBox}>Date</div>
      </Grid>
      <Grid item xs={3}>
        <div className={pdfClasses.fillable}>{task.Date}</div>
      </Grid>
      <Grid item xs={3}>
        <div className={pdfClasses.subTitleBox}>Weather</div>
      </Grid>
      <Grid item xs={3}>
        <div className={pdfClasses.fillable}>
          {task.DailyReport?.map((res) => res.data.Weather)?.join(',')}
        </div>
      </Grid>
      <Grid item xs={3}>
        <div className={pdfClasses.subTitleBox}>Location</div>
      </Grid>
      <Grid item xs={3}>
        <div className={pdfClasses.fillable}>{jobDetails.Location}</div>
      </Grid>
    </>
  );
};

Header.propTypes = {
  recoilJob: PropTypes.object,
  jobDetails: PropTypes.object,
  task: PropTypes.object,
};
Header.defaultProps = {
  recoilJob: { display: '' },
  jobDetails: { ProjectManager: '', Location: '' },
  task: { Foreman: '', Date: '', DailyReport: [] }
};

export default Header;