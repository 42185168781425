import React from 'react';
import PropTypes from 'prop-types';
import { env } from '../../../.env';

const iconURL = env.REACT_APP_ICON_URL;

const DailyOverview = (props) => {
  const { temp, description, icon, dotw, day } =
    props;
  const weatherImage = `${iconURL}${icon}`;

  return (
    <div style={{ padding: '8px' }}>
      {day ? <div style={{ fontSize: '20px' }}>{day}</div> : null}
      {dotw ? <div>{dotw}</div> : null}
      <div>{icon === '' ? null : <img style={{ width: '75px', marginBottom: '-20px', marginTop: '-10px' }} src={`${weatherImage}.png`} alt='WeatherIcon' />}</div>
      <div><h2 style={{ paddingLeft: '4px' }}>
        {Math.round(temp)}
        &deg;
      </h2></div>
      <div>{description}</div>
    </div>
  );
};

DailyOverview.propTypes = {
  temp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  description: PropTypes.string,
  icon: PropTypes.string,
  dotw: PropTypes.string,
  day: PropTypes.string,
};

DailyOverview.defaultProps = {
  temp: '',
  description: '',
  icon: '',
  dotw: '',
  day: ''
};

export default DailyOverview;