import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useSetRecoilState } from 'recoil';
import DailyToolbar from '../components/dailyUsage/DailyToolbar';
import WBSEntryForm from '../components/wbs/WBSEntryForm';
import WBSEntryList from '../components/wbs/WBSEntryList';
import { _HeaderTitle } from '../_Recoil/atoms';
import { basicStyles } from '../theme';
import ErrorBoundary from '../utils/ErrorBoundary';

import { UserContext } from '../providers/UserProvider';

const WBS = (props) => {
  document.title = 'WBS/PICC';
  const { match, history } = props;
  const { user } = useContext(UserContext);
  const setHeaderTitle = useSetRecoilState(_HeaderTitle);
  useEffect(() => setHeaderTitle('WBS/PICC'), []);
  const classes = basicStyles();
  return (
    <ErrorBoundary componentName='WBS' user={user}>
      <Grid className={classes.gridItem}>
        <Grid item xs={12} className={classes.gridItem}>
          <div style={{ paddingBottom: '8px' }}>
            <h1>{document.title}</h1>
          </div>
        </Grid>
        <Grid item xs={12}>
          <DailyToolbar />
        </Grid>
        <Grid item xs={12}>
          {typeof match.params.id === 'undefined' ? (
            <WBSEntryList history={history} match={match} />
          ) : (
            <WBSEntryForm history={history} match={match} />
          )}
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
};

WBS.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any)
};
WBS.defaultProps = {
  match: {
    params: {}
  },
  history: {
    push: () => { }
  }
};
export default WBS;
