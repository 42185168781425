import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useSetRecoilState } from 'recoil';
import AccountingSummary from '../components/accounting/AccountingSummary';
import AccountingDetail from '../components/accounting/AccountingDetail';
import { _HeaderTitle } from '../_Recoil/atoms';
import { basicStyles } from '../theme';
import ErrorBoundary from '../utils/ErrorBoundary';

import { UserContext } from '../providers/UserProvider';

const Accounting = (props) => {
  const { match, history } = props;
  const { user } = useContext(UserContext);
  const classes = basicStyles();
  const setHeaderTitle = useSetRecoilState(_HeaderTitle);
  useEffect(() => setHeaderTitle('Accounting'), []);
  document.title = 'Accounting';
  return (
    <ErrorBoundary componentName='Accounting' user={user}>
      <Grid className={classes.gridItem}>
        <Grid item xs={12} className={classes.gridItem}>
          <div style={{ paddingBottom: '8px' }}>
            <h1>{document.title}</h1>
          </div>
        </Grid>
        {typeof match.params.id1 === 'undefined' ? (
          <AccountingSummary history={history} match={match} />
        ) : (
          <AccountingDetail
            history={history}
            employeeId={match.params.id2}
            weekEndDate={match.params.id1}
          />
        )}
      </Grid>
    </ErrorBoundary>
  );
};

Accounting.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any)
};
Accounting.defaultProps = {
  match: {
    params: {}
  },
  history: {
    push: () => { }
  }
};
export default Accounting;
