/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  InputAutocomplete,
  InputCheckbox,
  InputCheckboxGroup
} from '@aldridge/aldg-data-components';
import {
  Grid,
  FormHelperText,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import Chip from '@mui/material/Chip';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { firestore, firebaseConfig } from '../../firebase';
import {
  accordionClass,
  accordionSummaryClass,
  accordionDetailsClass
} from '../../theme';

const TimeSummaryFilters = (props) => {
  const {
    allForDiv,
    setAllForDiv,
    AllSubJobTime,
    setAllSubJobTime,
    classes,
    jobsSelected,
    editJobsSelected,
    CurrentDivision,
    CurrentJobNumber
  } = props;

  const ac = accordionClass();
  const acsc = accordionSummaryClass();
  const acdc = accordionDetailsClass();
  return (
    <>
      <Accordion classes={ac}>
        <AccordionSummary
          classes={{ root: acsc.root, expanded: acsc.expanded }}
          expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
        >
          Additional Time Summary Filters
        </AccordionSummary>
        <AccordionDetails classes={acdc}>
          <Grid container>
            <Grid item xs={12}>
              <FormHelperText style={{ width: '100%', textAlign: 'center' }}>
                The <b>Division</b> and <b>All Sub Job</b> View is driven off of
                your Job Details <b>Job Selection</b>. If you want to view{' '}
                <u>
                  all the Sub Jobs of a different Job or a different Division
                </u>
                , please change your Job Details Information.
              </FormHelperText>
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.gridItem}
              style={{ flexBasis: '100%' }}
            >
              {allForDiv ? null : (
                <>
                  {AllSubJobTime ? null : (
                    <InputAutocomplete
                      firebaseConfig={firebaseConfig}
                      name='MultipleJobs'
                      label='Multiple Job Selection'
                      firestoreOptions={{
                        method: 'get',
                        collection: 'ENT-Jobs',
                        valueKey: 'jctdscid',
                        where: [['JobStatus', '==', 'Open']],
                        orderBy: 'JobNumber'
                      }}
                      value={
                        jobsSelected.ids.length > 0 ? jobsSelected.ids : []
                      }
                      onChange={editJobsSelected}
                      optionDisplay={(opts) => {
                        if (typeof opts.JobNumber === 'undefined') return '';
                        return `${opts.JobNumber}.${opts.SubJobNumber} - ${opts.JobDescription}`;
                      }}
                      renderTags={(options, getTagProps) =>
                        options.map((opts, index) =>
                          opts.jctdscid === CurrentJobNumber.value[0] ? (
                            <Chip
                              label={`${opts.JobNumber}.${opts.SubJobNumber} - ${opts.JobDescription}`}
                              size='small'
                              {...getTagProps({ index })}
                              disabled
                            />
                          ) : (
                            <Chip
                              label={`${opts.JobNumber}.${opts.SubJobNumber} - ${opts.JobDescription}`}
                              size='small'
                              {...getTagProps({ index })}
                            />
                          )
                        )
                      }
                      multiple
                    />
                  )}
                </>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.gridItem}
              style={{ flexBasis: '100%' }}
            >
              {jobsSelected.length > 1 || allForDiv ? null : (
                <InputCheckboxGroup
                  label=''
                  name='showAllDivision'
                  value={AllSubJobTime ? 'true' : 'false'}
                  onChange={() => {
                    setAllSubJobTime(!AllSubJobTime);
                  }}
                >
                  <InputCheckbox
                    label={`View Time for all Sub Jobs for ${CurrentJobNumber.display}`}
                    value='true'
                  />
                </InputCheckboxGroup>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.gridItem}
              style={{ flexBasis: '100%' }}
            >
              <InputCheckboxGroup
                label=''
                name='showAllDivision'
                value={allForDiv ? 'true' : 'false'}
                onChange={() => {
                  setAllForDiv(!allForDiv);
                }}
              >
                <InputCheckbox
                  label={`View Time for all of Division ${CurrentDivision}`}
                  value='true'
                />
              </InputCheckboxGroup>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

TimeSummaryFilters.propTypes = {
  allForDiv: PropTypes.bool.isRequired,
  setAllForDiv: PropTypes.func.isRequired,
  AllSubJobTime: PropTypes.bool.isRequired,
  setAllSubJobTime: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  jobsSelected: PropTypes.objectOf(PropTypes.any).isRequired,
  editJobsSelected: PropTypes.func.isRequired,
  CurrentDivision: PropTypes.string.isRequired,
  CurrentJobNumber: PropTypes.objectOf(PropTypes.any).isRequired
};
TimeSummaryFilters.defaultProps = {};
export default TimeSummaryFilters;
