// import React from 'react';
import { faTachometerAltAverage, faClipboardListCheck, faCalendarEdit, faUserClock, faTruckContainer, faRoad, faBolt, faPlaneDeparture } from '@fortawesome/pro-regular-svg-icons';

// eslint-disable-next-line import/prefer-default-export
export const ApplicationsMenuData = [
  {
    href: 'https://care.aldridge.app',
    logo: faClipboardListCheck,
    title: 'C.A.R.E',
  },
  {
    href: 'https://aldridge.app',
    logo: faTachometerAltAverage,
    title: 'P.I.D',
  },
  {
    href: 'https://aldg-sis-dev.web.app',
    logo: faCalendarEdit,
    title: 'D.J.R',
  },
  {
    href: 'https://aldridge.app',
    logo: faPlaneDeparture,
    title: 'LAX',
  },
  {
    href: 'https://aldridge.app',
    logo: faTruckContainer,
    title: 'F.M.S',
  },
  {
    href: 'https://aldridge.app',
    logo: faRoad,
    title: 'Manhole',
  },
  {
    href: 'https://aldridge.app',
    logo: faBolt,
    title: 'DC Transmissions',
  },
  {
    href: 'https://aldridge.app',
    logo: faUserClock,
    title: 'Time Entry',
  },
]