import React from "react";
import PropTypes from 'prop-types';
import DailyOverview from "./DailyOverview";

const WeatherForecast = (props) => {
  const { forecast } = props
  const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
  const returnDays = (date) => {
    if (date < 10)
      return `0${date}`;
    return date;
  }

  const getDays = () => {
    const hours = []
    if (forecast.length > 0) {
      for (let i = 0; i < forecast.length; i++) {
        const time = new Date(forecast[i].dt * 1000)
        hours.push(
          <DailyOverview key={`${months[time.getMonth()]}/${returnDays(time.getDate())}_${i}`} day={`${months[time.getMonth()]}/${returnDays(time.getDate())}`} dotw={i === 0 ? 'Now' : new Date(forecast[i].dt * 1000).toLocaleTimeString('en-us', { hour: "numeric" })} temp={Math.round(forecast[i].temp)} description={forecast[i].weather[0].main} icon={forecast[i].weather[0].icon} />)
      }
    }
    return hours;
  }
  return (
    getDays()
  )
}

WeatherForecast.propTypes = {
  forecast: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

WeatherForecast.defaultProps = {
  forecast: []
};

export default WeatherForecast;