import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useSetRecoilState } from 'recoil';
import DailyToolbar from '../components/dailyUsage/DailyToolbar';
import { _HeaderTitle } from '../_Recoil/atoms';
import DailyReportList from '../components/dailyReport/DailyReportList';
import DailyReport from '../components/dailyReport/DailyReport';
import { basicStyles } from '../theme';
import ErrorBoundary from '../utils/ErrorBoundary';

import { UserContext } from '../providers/UserProvider';

const DailyJobReport = (props) => {
  document.title = 'Daily Job Reporting';
  const { match, history } = props;
  const { user } = useContext(UserContext);
  const classes = basicStyles();
  const setHeaderTitle = useSetRecoilState(_HeaderTitle);
  useEffect(() => {
    let mounted = true;
    if (mounted) setHeaderTitle('DJR');
    return () => (mounted = false);
  }, []);
  return (
    <ErrorBoundary componentName='DJR' user={user}>
      <Grid className={classes.gridItem}>
        <Grid item xs={12} className={classes.gridItem}>
          <div style={{ paddingBottom: '8px' }}>
            <h1>{document.title}</h1>
          </div>
        </Grid>
        <Grid container>
          <DailyToolbar />
        </Grid>
        {typeof match.params.id !== 'undefined' ? (
          <DailyReport match={match} history={history} />
        ) : (
          <DailyReportList history={history} />
        )}
      </Grid>
    </ErrorBoundary>
  );
};

DailyJobReport.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any)
};
DailyJobReport.defaultProps = {
  match: {
    params: {}
  },
  history: {
    push: () => { }
  }
};
export default DailyJobReport;
