import { NaNtoNum } from "@aldridge/aldg-helpers";

const ObstacleDisplay = (obstacle) => {
  let result = '';
  if (typeof obstacle.data.EndDay?.ObstacleCodeDisplay !== 'undefined')
    result += obstacle.data.EndDay?.ObstacleCodeDisplay || '';
  if (typeof obstacle.data.EndDay?.SubObstacleCodeDisplay !== 'undefined')
    result += ` / ${obstacle.data.EndDay?.SubObstacleCodeDisplay || ''}`;
  if (typeof obstacle.data.EndDay?.ObstacleCode2Display !== 'undefined')
    result += obstacle.data.EndDay?.ObstacleCode2Display || '';
  if (typeof obstacle.data.EndDay?.SubObstacleCode2Display !== 'undefined')
    result += ` / ${obstacle.data.EndDay?.SubObstacleCode2Display || ''}`;
  return result;
};

const taskDescription = (task) => {
  const wbsDescriptions = (task1) => {
    const display = [];
    if ((task1.data.Level1Display?.trim() || '') !== '') {
      display.push(task1.data.Level1Display);
    }
    if ((task1.data.Level2Display?.trim() || '') !== '') {
      display.push(task1.data.Level2Display);
    }
    if ((task1.data.Level3Display?.trim() || '') !== '') {
      display.push(task1.data.Level3Display);
    }
    if ((task1.data.Level4Display?.trim() || '') !== '') {
      display.push(task1.data.Level4Display);
    }
    return display.join(' / ');
  };
  if (task.data.TaskType === 'WBS' && task.data.Task === '') {
    return wbsDescriptions(task);
  }
  return task.data.Task;
};

const parsePercentComplete = (task) => {
  task.data.EndDay.PercentComplete = task.data.EndDay.PercentComplete.replace(
    '%',
    ''
  );
  return NaNtoNum(task.data.EndDay.PercentComplete);
};

const taskCheck = ((t) => {
  for (let i = 0; i < t?.Tasks.length || 0; i++) {
    if (t.Tasks[i].data.ForceNumber !== '') return false;
  }
  return true;
})();

export { ObstacleDisplay, taskDescription, parsePercentComplete, taskCheck };