import {
  getStorage,
  listAll,
  ref,
  getDownloadURL,
  getMetadata
} from 'firebase/storage';
import { existsWithLength } from '@aldridge/aldg-helpers';
import getURL from './getURL';

const getAttachments = (mounted, setLoading, setAllFiles, folder) =>
  new Promise((resolve) => {
    const storage = getStorage();
    setLoading(true);
    setAllFiles([]);
    const allFilesRef = ref(storage, folder);
    listAll(allFilesRef).then((res) => {
      if (existsWithLength(res.items)) {
        const files = [];
        res.items.forEach((itemRef) => {
          getMetadata(itemRef).then((meta) => {
            getDownloadURL(itemRef).then((url) => {
              const fileObj = {
                downloadURL: url,
                url: getURL(url, meta.contentType),
                type: meta.contentType,
                name: itemRef.name,
                uploadedBy: meta.customMetadata?.uploadedBy,
                uploadedDate: meta.customMetadata?.uploadedDate,
                LinkTo: meta.customMetadata?.LinkTo,
                NeedsDriveViewer: !(
                  ['application/pdf', 'text/plain'].includes(
                    meta.contentType
                  ) || meta.contentType.indexOf('image/') > -1
                )
              };
              files.push(fileObj);
              // if (mounted.current) {

              //   // setAllFiles((prev) => {
              //   //   const newArr = [].concat(
              //   //     JSON.parse(JSON.stringify(prev)),
              //   //     fileObj
              //   //   );
              //   //   return newArr
              //   //     .filter(
              //   //       (obj, index) =>
              //   //         newArr.findIndex((item) => item.name === obj.name) ===
              //   //         index
              //   //     )
              //   //     .sort((a, b) => a.uploadedDate > b.uploadedDate);
              //   // });
              //   // setLoading(false);
              // }
            });
          });
        });
        resolve(files);
      } else resolve([]);
      // else setLoading(false);
    });
  });

export default getAttachments;
