/* eslint-disable */

import React, { useEffect, useState } from 'react';
import MultiDatePicker from '../utils/MultiDatePicker';
import { useRecoilState } from "recoil";
import { _CurrentDate } from "../_Recoil/atoms";

// import dayjs from 'dayjs';

const Test = () => {

  // const [tasks, setTasks] = useState([]);
  // useEffect(() => {
  //   getDocs(
  //     query(
  //       collection(firestore, 'ENT-Jobs'),
  //     )
  //   ).then((r) => {
  //     console.log(r);
  //     const all = [];
  //     r.forEach((d) => all.push(d.data()));
  //     console.log(all);
  //     setJobData(all);
  //   });
  //   getDocs(
  //     query(
  //       collection(firestore, 'ENT-Employees'),
  //     )
  //   ).then((r) => {
  //     console.log(r);
  //     const all = [];
  //     r.forEach((d) => all.push(d.data()));
  //     console.log(all);
  //     setEmployeeData(all);
  //   });
  //   // console.log('getting records');
  //   // getDocs(query(collection(firestore, 'TimeEntries'))).then(async (r) => {
  //   //   console.log(r);
  //   //   let knt = 0;
  //   //   let crewFound = 0;
  //   //   console.log('looping records');
  //   //   for (let d of r.docs) {
  //   //     const data = d.data();
  //   //     if (
  //   //       data.data.Foreman === '' ||
  //   //       typeof data.data.Foreman === 'undefined'
  //   //     ) {
  //   //       const crewD = await getDoc(doc(firestore, 'Crew', data.data.Crew[0]));
  //   //       const crewR = crewD.exists() ? crewD.data() : undefined;
  //   //       if (typeof crewR !== 'undefined') {
  //   //         crewFound += 1;
  //   //         data.data.Foreman = crewR.data.Foreman;
  //   //         setDoc(d.ref, data, { merge: true });
  //   //       }
  //   //       knt += 1;
  //   //       // data.data.PerDiem = '';
  //   //       // d.ref.set(data, { merge: true });
  //   //     }
  //   //   }
  //   //   const end = Date.now();
  //   //   console.log('records found: ', knt);
  //   //   console.log('crew updated: ', crewFound);
  //   //   console.log('time taken: ', end - start);
  //   // });
  //   // const start = Date.now();
  //   // console.log('getting records');
  //   // getDocs(query(collection(firestore, 'Crew'))).then(async (r) => {
  //   //   console.log(r);
  //   //   let knt = 0;
  //   //   let crewFound = 0;
  //   //   console.log('looping records');
  //   //   for (let d of r.docs) {
  //   //     const data = d.data();
  //   //     if (data.data.Job === '' || typeof data.data.Job === 'undefined') {
  //   //       if (data.data.JobNumber !== '') {
  //   //         const crewD = await getDoc(
  //   //           doc(firestore, 'ENT-Jobs', data.data.JobNumber)
  //   //         );
  //   //         const crewR = crewD.exists() ? crewD.data() : undefined;
  //   //         if (typeof crewR !== 'undefined') {
  //   //           crewFound += 1;
  //   //           data.data.Job = crewR.JobNumber;
  //   //           // setDoc(d.ref, data, { merge: true });
  //   //         }
  //   //         knt += 1;
  //   //       }
  //   //       // data.data.PerDiem = '';
  //   //       // d.ref.set(data, { merge: true });
  //   //     }
  //   //   }
  //   //   const end = Date.now();
  //   //   console.log('records found: ', knt);
  //   //   console.log('crew updated: ', crewFound);
  //   //   console.log('time taken: ', (end - start) / 1000);
  //   // });
  // }, []);

  const [values, setValues] = useState([new Date().setHours(0, 0, 0, 0)]);
  const [days, setDays] = useState([]);
  const CurrentDate = useRecoilState(_CurrentDate);

  useEffect(() => {
    // values.sort().forEach((val, idx) => values[idx] = (dayjs(val).format('YYYY-MM-DD')));
    console.log(days);
  }, [days]);

  return (
    <>
      <h1>Testing</h1>
      <div style={{ padding: '20px' }} />
      <MultiDatePicker values={values} setValues={setValues} />
    </>
  );
};

export default Test;
