import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import TimeSummaryList from 'components/TimeSummary/TimeSummary';
import { _CurrentDate, _HeaderTitle } from '_Recoil/atoms';
import TimeSummaryDetail from 'components/TimeSummary/TimeSummaryDetail';
import { basicStyles } from 'theme';
import ErrorBoundary from 'utils/ErrorBoundary';

import { UserContext } from 'providers/UserProvider';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

const TimeSummary = (props) => {
  const { match, history } = props;
  const { user } = useContext(UserContext);
  const classes = basicStyles();
  const setHeaderTitle = useSetRecoilState(_HeaderTitle);
  const CurrentDate = useRecoilValue(_CurrentDate);
  document.title = 'Time Summary';
  useEffect(() => setHeaderTitle('Weekly Time Summary'), []);
  const checkLockout = (() => {
    // if (user && user.admin) return false;
    const today = dayjs().format('YYYY-MM-DD HH:mm');
    const day = dayjs(today).day();
    const hour = dayjs(today).hour();
    const minTimeDate =
      // !(day >= 2 && hour >= 0)
      !(day >= 2 && hour >= 13)
        ? dayjs(today)
          .subtract(7 + (day - 1), 'days')
          .format('YYYY-MM-DD')
        : dayjs(today)
          .subtract(day === 0 ? 6 : day - 1, 'days')
          .format('YYYY-MM-DD');
    const dateDiff = dayjs(CurrentDate.value).diff(minTimeDate);
    const dayDiff = Math.ceil(dateDiff / (1000 * 3600 * 24));

    if (dayDiff < 0) {
      if (!toast.isActive('timeLockout')) {
        toast.error('You can no longer edit time for this date.', {
          toastId: 'timeLockout'
        });
      }
      return true;
    }
    return false;
  })();
  return (
    <ErrorBoundary componentName='Time Summary' user={user}>
      <Grid className={classes.gridItem}>
        <Grid item xs={12} className={classes.gridItem}>
          <div style={{ paddingBottom: '8px' }}>
            <h1>{document.title}</h1>
          </div>
        </Grid>
        {typeof match.params.id === 'undefined' ? (
          <TimeSummaryList history={history} checkLockout={checkLockout} match={match} />
        ) : (
          <TimeSummaryDetail history={history} checkLockout={checkLockout} employeeId={match.params.id} />
        )}
      </Grid>
    </ErrorBoundary>
  );
};

TimeSummary.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any)
};
TimeSummary.defaultProps = {
  match: {
    params: {}
  },
  history: {
    push: () => { }
  }
};
export default TimeSummary;
