import * as React from 'react';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import PropTypes from 'prop-types';

const SwitchGroups = (props) => {
  const { userRole, setUserRole } = props;

  const handleChange = (event) => {
    setUserRole({
      ...userRole,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <FormControl component="fieldset" variant="standard">

      <FormLabel component="legend" style={{ fontWeight: 'bold' }}>Change Roles</FormLabel>

      <FormGroup >
        <FormControlLabel
          control={
            <Switch
              name='manager'
              id='MockedRoleManager'
              checked={userRole.manager}
              color='primary'
              onChange={handleChange}
            />
          }
          label='Manager'

        />
        <FormControlLabel
          control={
            <Switch
              name='prefab'
              id='MockedRolePrefab'
              checked={userRole.prefab}
              color='secondary'
              onChange={handleChange}
            />
          }
          label='Prefab'
        />
        <FormControlLabel
          control={
            <Switch
              name='user'
              id='MockedRoleUser'
              checked={userRole.user}
              color='warning'
              onChange={handleChange}
            />
          }
          label='User'
        />
      </FormGroup>
    </FormControl>
  );
};

SwitchGroups.propTypes = {
  userRole: PropTypes.objectOf(PropTypes.any),
  setUserRole: PropTypes.any
};
SwitchGroups.defaultProps = {
  userRole: PropTypes.objectOf(PropTypes.any),
  setUserRole: PropTypes.any
};

export default SwitchGroups;