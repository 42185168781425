import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSetRecoilState } from 'recoil';
import UserList from '../components/users/UserList';
import UserPage from '../components/users/UserPage';
import {
  _HeaderTitle,
} from '../_Recoil/atoms';

const Users = (props) => {
  const { history, match } = props;

  const setHeaderTitle = useSetRecoilState(_HeaderTitle);
  useEffect(() => { document.title = 'P.O.D.'; setHeaderTitle('P.O.D.'); }, []);

  return (
    <>
      {typeof match.params.id === 'undefined' ? <UserList history={history} match={match} /> : <UserPage history={history} match={match} />}
    </>
  );
};

Users.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any)
};
Users.defaultProps = {
  history: {
    push: () => { }
  },
  match: {}
};

export default Users;
