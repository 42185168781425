import { React, useState, useEffect } from 'react';
import { Grid, Button, Card } from '@mui/material';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { Link, useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { _CurrentJobNumber } from '../../_Recoil/atoms';
import { firestore } from '../../firebase';


const EquipmentDashboard = () => {
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);
  const history = useHistory();
  const [equip, setEquip] = useState([]);
  if (CurrentJobNumber.value.length === 0) {
    return 'Need data to get data';
  }
  useEffect(() => {
    let mounted = true;
    const q = query(
      collection(firestore, 'Equipment'),
      where('data.JobNumber', '==', CurrentJobNumber.value[0])
    );
    getDocs(q).then((querySnapshot) => {
      const records = [];
      querySnapshot.forEach((d) => {
        records.push(d.data());
      });
      if (mounted) setEquip(records);
    });
    return () => (mounted = false);
  }, [CurrentJobNumber.value]);
  return (
    <>
      <Card>
        <Grid container style={{ display: 'flex', alignItems: 'center' }}>
          <Grid
            item
            xs={12}
            style={{
              padding: '8px 16px 16px 16px',
              whiteSpace: 'nowrap',
              textAlign: 'left',
              fontSize: '1.5rem',
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            {' '}
            Equipment Available
            <ToolTipIconButton icon={faPlus} onClick={() => history.push('/Equipment')} tooltipText="Add a Piece of Equipment" shape="circle" />

          </Grid>
          {equip.length !== 0 ? (
            <Grid container>
              <Grid
                item
                xs={6}
                style={{ fontWeight: 'bold', paddingBottom: '5px' }}
              >
                Unit Number
              </Grid>
              <Grid
                item
                xs={6}
                style={{ fontWeight: 'bold', paddingBottom: '5px' }}
              >
                Description
              </Grid>
              <Grid item xs={12}>
                {equip.map((e, idx) => (
                  <Grid
                    item
                    xs={12}
                    key={e.id}
                    style={{
                      backgroundColor: idx % 2 === 0 ? '#efefef' : '#fff',
                      padding: '5px 0'
                    }}
                  >
                    <Grid container style={{ marginLeft: '5px' }}>
                      <Grid item xs={6} style={{ textAlign: 'left' }}>
                        {typeof e.data.Equipment[0] !== 'undefined' ? (
                          e.data.Equipment[0]
                        ) : (
                          <i>No Unit Number</i>
                        )}{' '}
                      </Grid>
                      <Grid item xs={6} style={{ paddingRight: '10px' }}>
                        {e.data.Description !== '' ? (
                          e.data.Description
                        ) : (
                          <i>No Description</i>
                        )}{' '}
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ) : (
            <Grid
              item
              style={{
                justifyContent: 'center',
                width: '100%',
                textAlign: '-webkit-center'
              }}
            >
              <Card
                style={{
                  padding: '16px',
                  margin: '0px 16px 13.25px 16px',
                  width: 'fit-content'
                }}
              >
                <i>
                  No equipment is currently listed as available for this job.
                </i>
                <div style={{ margin: '8px 8px 0px 8px' }}>
                  <Link to='/Equipment'>
                    <Button variant='contained' color='primary'>
                      Add equipment for {CurrentJobNumber.display}
                    </Button>
                  </Link>
                </div>
              </Card>
            </Grid>
          )}
        </Grid>
      </Card>
    </>
  );
};

export default EquipmentDashboard;
