import {
  faHome,
  faList,
  faWatch,
  faUserFriends,
  faHardHat,
  faTruckContainer,
  faVideo,
  faDollarSign,
  faClipboard,
  faFilePdf
} from '@fortawesome/pro-regular-svg-icons';
import { faClipboardList, faCalendarAlt } from '@fortawesome/pro-solid-svg-icons';

const SidebarData = [
  {
    href: '/',
    icon: faHome,
    title: 'Home',
    dock: false
  },
  {
    href: '/pod',
    icon: faList,
    title: 'P.O.D.',
    dock: true
  },
  {
    href: '/time-entry',
    icon: faWatch,
    title: 'Time',
    dock: true
  },
  {
    href: '/time-summary',
    icon: faCalendarAlt,
    title: 'Weekly Time Summary',
    dock: false
  },
  {
    href: '/Daily-Report',
    icon: faClipboard,
    title: 'DJR',
    dock: true
  },
  {
    href: '/ReportingTool',
    icon: faFilePdf,
    title: 'PDF',
    dock: false
  },
  {
    href: '/crew',
    icon: faUserFriends,
    title: 'Crew',
    dock: false
  },
  {
    href: '/DistributionList',
    icon: faClipboardList,
    title: 'DistributionList',
    dock: false
  },
  // {
  //   href: '/Distribution',
  //   icon: faAddressBook,
  //   title: 'Distribution',
  //   dock: false,
  // },
  {
    href: '/Equipment',
    icon: faTruckContainer,
    title: 'Equipment',
    dock: false
  },
  {
    href: '/Subcontractor',
    icon: faHardHat,
    title: 'Subcontractor',
    dock: false
  },
  {
    href: '/wbs',
    icon: faDollarSign,
    title: 'WBS/PICC',
    dock: false
  },
  {
    href: '/Training',
    icon: faVideo,
    title: 'Training',
    dock: false
  }
];
export default SidebarData;
